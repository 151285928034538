<section class="container-fluid float-start">
<div class="col-12 float-start">
    <app-flight-search-engine></app-flight-search-engine>
</div>

<div *ngIf="1==this.getResultRes.Results.length && 1==this.index.typ">
    <div class="col-12 col-md-3 pt-4 ps-1 pe-1 float-start">
        <div class="col-12 float-start pt-1 ps-2 pe-2 filter-box">
            <h3>Filters <button mat-flat-button color="accent" class="float-end mt-1 ps-1 pe-1 pt-0 pb-0" (click)="resetFilter()">Reset</button></h3>
            <div class="col-12 d-block float-start filter-bx mb-2">
                <h4 class="mb-2">Price Range From Delhi</h4>
                <div class="air-item">
                    <div class="col-12 float-start">
                        <span class="col-6 text-start">Min {{allonewayObMinFare}} </span> - <span class="col-6"> Max {{priceFrame}}</span>
                    </div> 
                    <input class="col-12" type="range" min="{{allonewayObMinFare}}" max="{{allonewayObMaxFare}}" value="{{allonewayObMaxFare}}" (change)="priceFilter($event)" >                      
                </div>
            </div>
            <div class="col-12 d-block float-start filter-bx mb-2">
                <h4 class="mb-2 mt-1">Stops From Delhi</h4>
                <div class="air-item"> 
                    <div class="col-3 float-start" *ngFor="let stops of stopArrayFilterOB">
                        <mat-checkbox value="{{stops.StopNo}}" name="Stop" class="d-block" (change)="applyAirFilterOB($event)" checked="{{stops.checked}}">{{stops.StopName}}</mat-checkbox>                     
                    </div> 
                </div>
            </div>
            <div class="col-12 d-block float-start filter-bx mb-2">
                <h4 class="mb-2 mt-1">Departure From Delhi</h4>
                <div class="air-item"> 
                    <div class="col-3 float-start" *ngFor="let deps of depArrayFilterOB">                       
                        <mat-checkbox value="{{deps.DepTime}}" name="DepTime" (change)="applyAirFilterOB($event)" checked="{{deps.checked}}"><small>{{deps.DepName}}</small></mat-checkbox>                    
                    </div> 
                </div>
            </div>
            <div class="col-12 d-block float-start filter-bx mb-2">
                <h4 class="mb-2 mt-1">Departure From Mumbai</h4>
                <div class="air-item"> 
                    <div class="col-3 float-start" *ngFor="let arrs of arrArrayFilterOB">                       
                        <mat-checkbox value="{{arrs.ArrTime}}" name="ArrTime" (change)="applyAirFilterOB($event)" checked="{{arrs.checked}}"><small>{{arrs.ArrName}}</small></mat-checkbox>                    
                    </div> 
                </div>
            </div>
            <div class="col-12 d-block float-start filter-bx mb-2">
                <h4 class="mb-2 mt-1">Airline From Delhi</h4>
                <div class="air-item">
                    <div class="col-12 float-start" *ngFor="let airList of getAirfilterOB">
                        <mat-checkbox value="{{airList.AirlineCode}}" name="Airline" class="d-block float-start" (change)="applyAirFilterOB($event)" checked="{{airList.checked}}"><div class="col-12 float-start"><img src="assets/images/air-logo/{{airList.AirlineCode}}.png" width="20" />{{airList.AirlineName[0]}} <small>{{airList.AirResultCount}}</small></div></mat-checkbox> <small class="float-end">{{airList.MinFare}}</small>                        
                    </div>
                </div>                
            </div>
        </div>
    </div>
    <div class="col-12 col-md-9 pt-1 pb-1 ps-1 pe-1 float-start">
        <div class="col-12 float-start sort-bx">
            <div class="col-3 float-start text-end">
                <span (click)="increaseDepart()" *ngIf="incdepartisShow">Depart <mat-icon>arrow_downward</mat-icon></span>
                <span (click)="decreaseDepart()" *ngIf="decdepartisShow">Depart <mat-icon>arrow_upward</mat-icon></span>
            </div>
            <div class="col-4 float-start text-center">
                <span *ngIf="incdepartisShow">Duration <mat-icon>arrow_downward</mat-icon></span>
                <span *ngIf="decdepartisShow">Duration <mat-icon>arrow_upward</mat-icon></span>
            </div>
            <div class="col-2 float-start">
                <span (click)="increaseArrival()" *ngIf="incarrivalisShow">Arrive <mat-icon>arrow_downward</mat-icon></span>
                <span (click)="decreaseArrival()" *ngIf="decarrivalisShow">Arrive <mat-icon>arrow_upward</mat-icon></span>
            </div>
            <div class="col-3 float-start">
                <span (click)="increasePrice()" *ngIf="incpriceisShow">Price <mat-icon>arrow_downward</mat-icon></span>
                <span (click)="decreasePrice()" *ngIf="decpriceisShow">Price <mat-icon>arrow_upward</mat-icon></span>
            </div>
        </div>
        <div class="col-12 float-start result-box">            
            <div class="col-12 mb-2 p-2 float-start result-bx" *ngFor="let res of onewayResultOB">
                <div class="col-12 air-name float-start">
                    <div class="d-inline" *ngFor="let aircode of res.Segments[0]">
                        <small>{{aircode.Airline.AirlineName}} {{aircode.Airline.AirlineCode}}-{{aircode.Airline.FlightNumber}} </small>
                    </div>
                    <div class="col-12 float-start">
                        
                        <div class="col-2 float-start air-logo" *ngFor="let air of res.Segments[0]">
                            <div>
                                <img src="assets/images/air-logo/{{air.Airline.AirlineCode}}.png" width="40" />
                            </div>
                        </div>
                        <div class="col-6 float-start">
                            <div class="col-4 float-start origin">
                                <div class="col-12 float-start" *ngFor="let air of res.Segments[0]">
                                    <span>{{air.Origin.DepTime.substring(11).slice(0,-3)}}</span><br>
                                {{air.Origin.Airport.CityName}}
                                </div>
                            </div>
                            <div class="col-4 float-start text-center duration">
                                <span class="d-block">
                                    <small>{{res.Duration/60 | number:'1.0-0'}}h:</small><small>{{res.Duration % 60}}m</small>
                                </span>
                                <small *ngIf="res.StopNo==0">Non Stop</small><small *ngIf="res.StopNo>=1">{{res.StopNo}} Stop</small>
                            </div>
                            <div class="col-4 float-start text-end destination">
                                <div class="col-12 float-start" *ngFor="let air of res.Segments[0]">
                                    <span>{{air.Destination.ArrTime.substring(11).slice(0,-3)}}</span><br>
                                {{air.Destination.Airport.CityName}}
                                </div>
                            </div>                   
                            
                        </div>
                        <div class="col-4 float-start">
                            <div class="col-12 col-md-6 text-center float-start">
                                <span class="text-danger fs-6">{{res.Fare.PublishedFare | number:'1.0-0'}}</span>
                            </div>
                            <div class="col-12 col-md-6 text-center float-start">
                                <button mat-stroked-button color="primary" type="submit"  (click)="onewaymoreBtn(res.DepFlightNo)" class="">More Fares</button>
                            </div>
                        </div>
                        <div class="col-12 float-start">
                            <div class="discountbx d-inline me-1" *ngFor="let dis of res.Discount">
                                <span class="alert alert-success p-1 mt-1 mb-0 d-inline-block" *ngIf="dis.offerType=='Discount'">
                                    Use Promo Code: {{dis.discountName}} to Get Flat <mat-icon>currency_rupee</mat-icon>{{dis.discountAmount/100*res.Fare.PublishedFare-1 | number:'1.0-0'}} OFF
                                </span>
                                <span class="alert alert-warning p-1 mt-1 mb-1 d-inline-block" *ngIf="dis.offerType=='Cashback'">
                                    Use Promo Code: {{dis.discountName}} to Get Flat <mat-icon>currency_rupee</mat-icon>{{dis.discountAmount/100*res.Fare.PublishedFare-1 | number:'1.0-0'}} OFF
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 float-start" *ngIf="2==res.Segments.length">
                    <div class="d-inline" *ngFor="let aircode of res.Segments[1]">
                        {{aircode.Airline.AirlineName}}-{{aircode.Airline.AirlineCode}}-{{aircode.Airline.FlightNumber}}
                    </div>
                    <div class="col-12 float-start">
                        
                        <div class="col-2 float-start air-logo" *ngFor="let air of res.Segments[1]">
                            <div>
                                <img src="assets/images/air-logo/{{air.Airline.AirlineCode}}.png" width="40" />
                            </div>
                        </div>
                        <div class="col-6 float-start">
                            <div class="col-4 float-start origin">
                                <div class="col-12 float-start" *ngFor="let air of res.Segments[1]">
                                    <span>{{air.Origin.DepTime.substring(11).slice(0,-3)}}</span><br>
                                {{air.Origin.Airport.CityName}}
                                </div>
                            </div>
                            <div class="col-4 float-start text-center duration">
                                20h:30m
                            </div>
                            <div class="col-4 float-start text-end destination">
                                <div class="col-12 float-start" *ngFor="let air of res.Segments[1]">
                                    <span>{{air.Destination.ArrTime.substring(11).slice(0,-3)}}</span><br>
                                {{air.Destination.Airport.CityName}}
                                </div>
                            </div>                   
                            
                        </div>
                        <div class="col-4 float-start">
                            &nbsp;
                        </div>
                        
                    </div>
                </div>
                <!-- <div class="col-12 float-start">
                    {{res.FareClassification.Type}}-{{res.AirlineRemark}}
                </div> -->
                <div *ngIf="currDiv == res.DepFlightNo" class="col-12 float-start p-2 more-box">
                    <div class="col-8 col-md-3 float-start text-center ps-1 pe-1" *ngFor="let res of onewaymoreFares">                    
                        <div class="col-12 more-bx p-2">
                            <b class="d-block">
                                <span *ngIf="res.AirlineRemark=='6E FARE test.'">Lower Fare</span>
                                <span *ngIf="res.AirlineRemark==' This is a Flexi Fare. Includes free seat(as per restriction). Lower cancellation. Please refer fare rules.. . 6E FARE test.'">Flexi Fare</span>
                                <span *ngIf="res.AirlineRemark=='G8 L2 G8S.'">Economy Fare l</span>
                                <span *ngIf="res.AirlineRemark=='G8 st G8C.'">Economy Fare s</span>
                                <span *ngIf="res.AirlineRemark=='This is a FLXI Fare. Journey(applicable on 1st segment only) includes following bundle items:Refundable Fare with Free Meal and Seat (17th Row onwards), . Go air API.'">Flexi Fare</span>
                            </b>
                            <span class="d-block mt-2 mb-2">{{res.PublishedFare}}</span>
                            <button mat-flat-button color="accent" (click)="onewayGetItinerary(res.ResultIndex)" class="d-block">Book</button>
                        </div>
                    </div>
                </div>
                <div class="col-12 flight-details-menu">
                    <!-- <mat-card-title>Flight Details</mat-card-title> -->
                </div>
                <div class="col-12 flight-details-bx">
    
                    <!-- <mat-tab-group animationDuration="0ms">
                        <mat-tab label="Flight Information">Content 1</mat-tab>
                        <mat-tab label="Fare Rules & Details">Content 2</mat-tab>
                        <mat-tab label="Baggage Information">Content 3</mat-tab>
                    </mat-tab-group> -->
                </div>
            </div>
        </div>
    </div>
</div>
<div *ngIf="1==this.getResultRes.Results.length && 2==this.index.typ">
    <div class="col-12 col-md-3 pt-4 ps-1 pe-1 float-start">
        <div class="col-12 float-start ps-2 pe-2 filter-box">
            <h3>Filters <button mat-flat-button color="accent" class="float-end mt-1 ps-1 pe-1 pt-0 pb-0" (click)="resetFilter()">Reset</button></h3>
            <div class="col-12 d-block float-start filter-bx mb-2">
                <h4 class="mb-2">Price Range From Delhi</h4>
                <div class="air-item">
                    <div class="col-12 float-start">
                        <span class="col-6 text-start">Min {{allonewayObMinFare}} </span> - <span class="col-6"> Max {{priceFrame}}</span>
                    </div> 
                    <input class="col-12" type="range" min="{{allonewayObMinFare}}" max="{{allonewayObMaxFare}}" value="{{allonewayObMaxFare}}" (change)="priceFilter($event)" >                      
                </div>
            </div>
            <div class="col-12 d-block float-start filter-bx mb-2">
                <h4 class="mb-2 mt-1">Stops From Delhi</h4>
                <div class="air-item"> 
                    <div class="col-3 float-start" *ngFor="let stops of stopArrayFilterOB">
                        <mat-checkbox value="{{stops.StopNo}}" name="Stop" class="d-block" (change)="applyAirFilterGOB($event)" checked="{{stops.checked}}">{{stops.StopName}}</mat-checkbox>                     
                    </div> 
                </div>
            </div>
            <div class="col-12 d-block float-start filter-bx mb-2">
                <h4 class="mb-2 mt-1">Departure From Delhi</h4>
                <div class="air-item"> 
                    <div class="col-3 float-start" *ngFor="let deps of depArrayFilterOB">                       
                        <mat-checkbox value="{{deps.DepTime}}" name="DepTime" (change)="applyAirFilterGOB($event)" checked="{{deps.checked}}"><small>{{deps.DepName}}</small></mat-checkbox>                    
                    </div> 
                </div>
            </div>            
            <div class="col-12 d-block float-start filter-bx mb-2">
                <h4 class="mb-2 mt-1">Departure From New York</h4>
                <div class="air-item"> 
                    <div class="col-3 float-start" *ngFor="let arrs of arrArrayFilterOB">                       
                        <mat-checkbox value="{{arrs.ArrTime}}" name="ArrTime" (change)="applyAirFilterGOB($event)" checked="{{arrs.checked}}"><small>{{arrs.ArrName}}</small></mat-checkbox>                    
                    </div> 
                </div>
            </div>
            <div class="col-12 d-block float-start filter-bx ps-1 pe-1 mb-2" style="background-color:#eee;">
                <h4 class="mb-2 mt-1">Departure From New York(Return)</h4>
                <div class="air-item"> 
                    <div class="col-3 float-start" *ngFor="let deps of depArrayFilterIB">                       
                        <mat-checkbox value="{{deps.DepTime}}" name="DepTime" (change)="applyAirFilterGIB($event)" checked="{{deps.checked}}"><small>{{deps.DepName}}</small></mat-checkbox>                    
                    </div> 
                </div>
                <h4 class="mb-2 mt-1">Departure From Delhi(Return)</h4>
                <div class="air-item"> 
                    <div class="col-3 float-start" *ngFor="let arrs of arrArrayFilterIB">                       
                        <mat-checkbox value="{{arrs.ArrTime}}" name="ArrTime" (change)="applyAirFilterGIB($event)" checked="{{arrs.checked}}"><small>{{arrs.ArrName}}</small></mat-checkbox>                    
                    </div> 
                </div>                
            </div>            
            <div class="col-12 d-block float-start filter-bx mb-2">
                <h4 class="mb-2 mt-1">Airline From Delhi</h4>
                <div class="air-item">
                    <div class="col-12 float-start" *ngFor="let airList of getAirfilterOB">
                        <mat-checkbox value="{{airList.AirlineCode}}" name="Airline" class="d-block float-start" (change)="applyAirFilterGOB($event)" checked="{{airList.checked}}"><div class="col-12 float-start"><img src="assets/images/air-logo/{{airList.AirlineCode}}.png" width="20" />{{airList.AirlineName[0]}} <small>{{airList.AirResultCount}}</small></div></mat-checkbox> <small class="float-end">{{airList.MinFare}}</small>                        
                    </div>
                </div>                
            </div>
        </div>
    </div>
    <div class="col-12 col-md-9 pt-1 pb-1 ps-1 pe-1 float-start">
        <div class="col-12 float-start sort-bx">
            <div class="col-3 float-start text-end">
                &nbsp;
            </div>
            <div class="col-4 float-start text-center">
                &nbsp;
            </div>
            <div class="col-2 float-start">
                &nbsp;
            </div>
            <div class="col-3 float-start">
                <span (click)="increasePrice()" *ngIf="incpriceisShow">Price <mat-icon>arrow_downward</mat-icon></span>
                <span (click)="decreasePrice()" *ngIf="decpriceisShow">Price <mat-icon>arrow_upward</mat-icon></span>
            </div>
        </div>
        <div class="col-12 float-start result-box">            
            <div class="col-12 mb-2 p-2 float-start result-bx" *ngFor="let res of onewayResultOB">
                <div class="col-12 float-start">
                    <div class="d-inline" *ngFor="let aircode of res.Segments[0]">
                        <small>{{aircode.Airline.AirlineName}}-{{aircode.Airline.AirlineCode}}-{{aircode.Airline.FlightNumber}}</small>
                    </div>
                    <div class="col-12 float-start">
                        
                        <div class="col-2 float-start air-logo" *ngFor="let air of res.Segments[0]">
                            <div>
                                <img src="assets/images/air-logo/{{air.Airline.AirlineCode}}.png" width="40" />
                            </div>
                        </div>
                        <div class="col-6 float-start">
                            <div class="col-4 float-start origin">
                                <div class="col-12 float-start" *ngFor="let air of res.Segments[0]">
                                    <span>{{air.Origin.DepTime.substring(11).slice(0,-3)}}</span><br>
                                {{air.Origin.Airport.CityName}}
                                </div>
                            </div>
                            <div class="col-4 float-start text-center duration">
                                20h:30m
                            </div>
                            <div class="col-4 float-start text-end destination">
                                <div class="col-12 float-start" *ngFor="let air of res.Segments[0]">
                                    <span>{{air.Destination.ArrTime.substring(11).slice(0,-3)}}</span><br>
                                {{air.Destination.Airport.CityName}}
                                </div>
                            </div>                   
                            
                        </div>
                        <div class="col-4 float-start">
                            <div class="col-12 col-md-6 text-center float-start">
                                <span class="text-danger fs-6">{{res.Fare.PublishedFare | number:'1.0-0'}}</span>
                            </div>
                            <div class="col-12 col-md-6 text-center float-start">
                                <button mat-stroked-button color="primary" type="submit"  (click)="returnGdsMoreBtn(res.DepFlightNo,res.RetFlightNo)" class="">More Fares</button>
                            </div>
                        </div>
                        
                    </div>
                </div>
                <div class="col-12 float-start" *ngIf="2==res.Segments.length">
                    <div class="d-inline" *ngFor="let aircode of res.Segments[1]">
                        {{aircode.Airline.AirlineName}}-{{aircode.Airline.AirlineCode}}-{{aircode.Airline.FlightNumber}}
                    </div>
                    <div class="col-12 float-start">
                        
                        <div class="col-2 float-start air-logo" *ngFor="let air of res.Segments[1]">
                            <div>
                                <img src="assets/images/air-logo/{{air.Airline.AirlineCode}}.png" width="40" />
                            </div>
                        </div>
                        <div class="col-6 float-start">
                            <div class="col-4 float-start origin">
                                <div class="col-12 float-start" *ngFor="let air of res.Segments[1]">
                                    <span>{{air.Origin.DepTime.substring(11).slice(0,-3)}}</span><br>
                                {{air.Origin.Airport.CityName}}
                                </div>
                            </div>
                            <div class="col-4 float-start text-center duration">
                                20h:30m
                            </div>
                            <div class="col-4 float-start text-end destination">
                                <div class="col-12 float-start" *ngFor="let air of res.Segments[1]">
                                    <span>{{air.Destination.ArrTime.substring(11).slice(0,-3)}}</span><br>
                                {{air.Destination.Airport.CityName}}
                                </div>
                            </div>                   
                            
                        </div>
                        <div class="col-4 float-start">
                            &nbsp;
                        </div>
                        <div class="col-12 float-start">
                            <div class="discountbx d-inline me-1" *ngFor="let dis of res.Discount">
                                <span class="alert alert-success p-1 mt-1 mb-0 d-inline-block" *ngIf="dis.offerType=='Discount'">
                                    Use Promo Code: {{dis.discountName}} to Get Flat <mat-icon>currency_rupee</mat-icon>{{dis.discountAmount/100*res.Fare.PublishedFare-1 | number:'1.0-0'}} OFF
                                </span>
                                <span class="alert alert-warning p-1 mt-1 mb-1 d-inline-block" *ngIf="dis.offerType=='Cashback'">
                                    Use Promo Code: {{dis.discountName}} to Get Flat <mat-icon>currency_rupee</mat-icon>{{dis.discountAmount/100*res.Fare.PublishedFare-1 | number:'1.0-0'}} OFF
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <div class="col-12 float-start">
                    {{res.FareClassification.Type}}-{{res.AirlineRemark}}
                </div> -->
                <div *ngIf="currDiv == res.DepFlightNo" class="col-12 float-start p-2 more-box">
                    <div class="col-8 col-md-3 float-start text-center ps-1 pe-1" *ngFor="let res of onewaymoreFares">                    
                        <div class="col-12 more-bx p-2">
                            <b class="d-block">
                                <span *ngIf="res.AirlineRemark=='6E FARE test.'">Lower Fare</span>
                                <span *ngIf="res.AirlineRemark==' This is a Flexi Fare. Includes free seat(as per restriction). Lower cancellation. Please refer fare rules.. . 6E FARE test.'">Flexi Fare</span>
                                <span *ngIf="res.AirlineRemark=='G8 L2 G8S.'">Economy Fare l</span>
                                <span *ngIf="res.AirlineRemark=='G8 st G8C.'">Economy Fare s</span>
                                <span *ngIf="res.AirlineRemark=='This is a FLXI Fare. Journey(applicable on 1st segment only) includes following bundle items:Refundable Fare with Free Meal and Seat (17th Row onwards), . Go air API.'">Flexi Fare</span>
                            </b>
                            <span class="d-block mt-2 mb-2">{{res.PublishedFare | number:'1.0-0'}}</span>
                            <button mat-flat-button color="accent" (click)="onewayGetItinerary(res.ResultIndex)" class="d-block">Book</button>
                        </div>
                    </div>
                </div>
                <div class="col-12 flight-details-menu">
                    <!-- <mat-card-title>Flight Details</mat-card-title> -->
                </div>
                <div class="col-12 flight-details-bx">
    
                    <!-- <mat-tab-group animationDuration="0ms">
                        <mat-tab label="Flight Information">Content 1</mat-tab>
                        <mat-tab label="Fare Rules & Details">Content 2</mat-tab>
                        <mat-tab label="Baggage Information">Content 3</mat-tab>
                    </mat-tab-group> -->
                </div>
            </div>
        </div>
    </div>
</div>
<div *ngIf="2==this.getResultRes.Results.length">
    <div class="col-12 col-md-3 pt-4 ps-1 pe-1 float-start">
        <!-- <div class="col-12 float-start ps-1 pe-1 filter-box">
            <div class="col-12 filter-bx mb-2">
                <div class="air-item">  
                    <mat-checkbox id="stp0" class="d-block">Non Stop</mat-checkbox>
                    <mat-checkbox id="stp1" class="d-block">1 Stop</mat-checkbox>
                    <mat-checkbox id="stp2" class="d-block">2 Stop</mat-checkbox>                       
                </div>
            </div>
            <div class="col-12 filter-bx mb-2">
                <div class="air-item">
                    <mat-checkbox id="sz6E" class="d-block">Indigo</mat-checkbox>
                    <mat-checkbox id="szSG" class="d-block">Spicejet</mat-checkbox>
                    <mat-checkbox id="szI5" class="d-block">AirAsia</mat-checkbox>
                    <mat-checkbox id="szG8" class="d-block">Go First</mat-checkbox>
                    <mat-checkbox id="szAI" class="d-block">Air India</mat-checkbox>
                    <mat-checkbox id="szUK" class="d-block">Vistara</mat-checkbox>                          
                </div>
            </div>
        </div> -->
    </div>
    <div class="col-12 col-md-9 pt-1 pb-1 ps-1 pe-1 float-start">
        <div class="col-12 col-md-6 p-1 float-start returnWayBx">
            <div class="col-12 float-start result-box">            
                <div class="col-12 mb-2 p-2 float-start result-bx" *ngFor="let res of returnResultOB; index as i">
                    <div class="d-inline air-name" *ngFor="let aircode of res.Segments[0]">
                        <small>{{aircode.Airline.AirlineName}}-{{aircode.Airline.AirlineCode}}-{{aircode.Airline.FlightNumber}} </small>
                    </div>
                    <div class="col-12 float-start">
                        
                        <div class="col-2 float-start air-logo" *ngFor="let air of res.Segments[0]">
                            <div>
                                <img src="assets/images/air-logo/{{air.Airline.AirlineCode}}.png" width="40" />
                            </div>
                        </div>
                        <div class="col-6 float-start">
                            <div class="col-4 float-start origin">
                                <div class="col-12 float-start" *ngFor="let air of res.Segments[0]">
                                    <span>{{air.Origin.DepTime.substring(11).slice(0,-3)}}</span><br>
                                {{air.Origin.Airport.CityName}}
                                </div>
                            </div>
                            <div class="col-4 float-start text-center duration">
                                20h:30m
                            </div>
                            <div class="col-4 float-start text-end destination">
                                <div class="col-12 float-start" *ngFor="let air of res.Segments[0]">
                                    <span>{{air.Destination.ArrTime.substring(11).slice(0,-3)}}</span><br>
                                {{air.Destination.Airport.CityName}}
                                </div>
                            </div>                   
                            
                        </div>
                        <div class="col-4 float-start">
                            <div class="col-12 col-md-6 text-center float-start">
                                <span class="text-danger fs-6">{{res.Fare.PublishedFare | number:'1.0-0'}}</span>
                            </div>
                            <div class="col-12 col-md-6 text-center float-start">
                                <mat-radio-button id="{{res.FlightNo}}" value="{{res.ResultIndex}}" name="OBfare" (change)="returnGetItineraryOb($event)" [checked]="i === 0"></mat-radio-button> 
                                <!-- <button mat-stroked-button color="primary" type="submit"  (click)="returnmoreBtnOb(res.FlightNo)" class="p-1 returnMoreBtn">More Fares</button> -->
                            </div>
                        </div>
                        <div class="col-12 float-start">
                            <div class="discountbx d-inline me-1" *ngFor="let dis of res.Discount">
                                <span class="alert alert-success p-1 mt-1 mb-0 d-inline-block" *ngIf="dis.offerType=='Discount'">
                                    Use Promo Code: {{dis.discountName}} to Get Flat <mat-icon>currency_rupee</mat-icon>{{dis.discountAmount/100*res.Fare.PublishedFare-1 | number:'1.0-0'}} OFF
                                </span>
                                <span class="alert alert-warning p-1 mt-1 mb-1 d-inline-block" *ngIf="dis.offerType=='Cashback'">
                                    Use Promo Code: {{dis.discountName}} to Get Flat <mat-icon>currency_rupee</mat-icon>{{dis.discountAmount/100*res.Fare.PublishedFare-1 | number:'1.0-0'}} OFF
                                </span>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="col-12 float-start">
                        {{res.FareClassification.Type}}-{{res.AirlineRemark}}
                    </div> -->
                    <div *ngIf="currDiv == res.FlightNo" class="col-12 float-start p-2 more-box">
                        <div class="col-8 col-md-3 float-start text-center ps-1 pe-1" *ngFor="let res of returnmoreFaresOb">                    
                            <div class="col-12 more-bx p-2">
                                <b class="d-block">
                                    <span *ngIf="res.AirlineRemark=='6E FARE test.'">Lower Fare</span>
                                    <span *ngIf="res.AirlineRemark==' This is a Flexi Fare. Includes free seat(as per restriction). Lower cancellation. Please refer fare rules.. . 6E FARE test.'">Flexi Fare</span>
                                    <span *ngIf="res.AirlineRemark=='G8 L2 G8S.'">Economy Fare l</span>
                                    <span *ngIf="res.AirlineRemark=='G8 st G8C.'">Economy Fare s</span>
                                    <span *ngIf="res.AirlineRemark=='This is a FLXI Fare. Journey(applicable on 1st segment only) includes following bundle items:Refundable Fare with Free Meal and Seat (17th Row onwards), . Go air API.'">Flexi Fare</span>
                                </b>
                                <span class="d-block mt-2 mb-2">{{res.PublishedFare}}</span>                                                                                  
                            </div>
                        </div>
                    </div>
                    <div class="col-12 flight-details-menu">
                        <!-- <mat-card-title>Flight Details</mat-card-title> -->
                    </div>
                    <div class="col-12 flight-details-bx">
        
                        <!-- <mat-tab-group animationDuration="0ms">
                            <mat-tab label="Flight Information">Content 1</mat-tab>
                            <mat-tab label="Fare Rules & Details">Content 2</mat-tab>
                            <mat-tab label="Baggage Information">Content 3</mat-tab>
                        </mat-tab-group> -->
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12 col-md-6 p-1 float-start returnWayBx">
            <div class="col-12 float-start result-box">            
                <div class="col-12 mb-2 p-2 float-start result-bx" *ngFor="let res of returnResultIB; index as i">
                    <div class="d-inline air-name" *ngFor="let aircode of res.Segments[0]">
                        <small>{{aircode.Airline.AirlineName}}-{{aircode.Airline.AirlineCode}}-{{aircode.Airline.FlightNumber}} </small>
                    </div>
                    <div class="col-12 float-start">
                        
                        <div class="col-2 float-start air-logo" *ngFor="let air of res.Segments[0]">
                            <div>
                                <img src="assets/images/air-logo/{{air.Airline.AirlineCode}}.png" width="40" />
                            </div>
                        </div>
                        <div class="col-6 float-start">
                            <div class="col-4 float-start origin">
                                <div class="col-12 float-start" *ngFor="let air of res.Segments[0]">
                                    <span>{{air.Origin.DepTime.substring(11).slice(0,-3)}}</span><br>
                                {{air.Origin.Airport.CityName}}
                                </div>
                            </div>
                            <div class="col-4 float-start text-center duration">
                                20h:30m
                            </div>
                            <div class="col-4 float-start text-end destination">
                                <div class="col-12 float-start" *ngFor="let air of res.Segments[0]">
                                    <span>{{air.Destination.ArrTime.substring(11).slice(0,-3)}}</span><br>
                                {{air.Destination.Airport.CityName}}
                                </div>
                            </div>                   
                            
                        </div>
                        <div class="col-4 float-start">
                            <div class="col-12 col-md-6 text-center float-start">
                                <span class="text-danger fs-6">{{res.Fare.PublishedFare | number:'1.0-0'}}</span>
                            </div>
                            <div class="col-12 col-md-6 text-center float-start">
                                <mat-radio-button id="{{res.FlightNo}}" value="{{res.ResultIndex}}" name="IBfare" (change)="returnGetItineraryIb($event)" [checked]="i === 0"></mat-radio-button>
                                <!-- <button mat-stroked-button color="primary" type="submit"  (click)="returnmoreBtnIb(res.FlightNo)" class="p-1 returnMoreBtn">More Fares</button> -->
                            </div>
                        </div>
                        <div class="col-12 float-start">
                            <div class="discountbx d-inline me-1" *ngFor="let dis of res.Discount">
                                <span class="alert alert-success p-1 mt-1 mb-0 d-inline-block" *ngIf="dis.offerType=='Discount'">
                                    Use Promo Code: {{dis.discountName}} to Get Flat <mat-icon>currency_rupee</mat-icon>{{dis.discountAmount/100*res.Fare.PublishedFare-1 | number:'1.0-0'}} OFF
                                </span>
                                <span class="alert alert-warning p-1 mt-1 mb-1 d-inline-block" *ngIf="dis.offerType=='Cashback'">
                                    Use Promo Code: {{dis.discountName}} to Get Flat <mat-icon>currency_rupee</mat-icon>{{dis.discountAmount/100*res.Fare.PublishedFare-1 | number:'1.0-0'}} OFF
                                </span>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="col-12 float-start">
                        {{res.FareClassification.Type}}-{{res.AirlineRemark}}
                    </div> -->
                    <div *ngIf="currDiv == res.FlightNo" class="col-12 float-start p-2 more-box">
                        <div class="col-8 col-md-3 float-start text-center ps-1 pe-1" *ngFor="let res of returnmoreFaresIb">                    
                            <div class="col-12 more-bx p-2">
                                <b class="d-block">
                                    <span *ngIf="res.AirlineRemark=='6E FARE test.'">Lower Fare</span>
                                    <span *ngIf="res.AirlineRemark==' This is a Flexi Fare. Includes free seat(as per restriction). Lower cancellation. Please refer fare rules.. . 6E FARE test.'">Flexi Fare</span>
                                    <span *ngIf="res.AirlineRemark=='G8 L2 G8S.'">Economy Fare l</span>
                                    <span *ngIf="res.AirlineRemark=='G8 st G8C.'">Economy Fare s</span>
                                    <span *ngIf="res.AirlineRemark=='This is a FLXI Fare. Journey(applicable on 1st segment only) includes following bundle items:Refundable Fare with Free Meal and Seat (17th Row onwards), . Go air API.'">Flexi Fare</span>
                                </b>
                                <span class="d-block mt-2 mb-2">{{res.PublishedFare}}</span>                                                                
                            </div>
                        </div>
                    </div>
                    <div class="col-12 flight-details-menu">
                        <!-- <mat-card-title>Flight Details</mat-card-title> -->
                    </div>
                    <div class="col-12 flight-details-bx">
        
                        <!-- <mat-tab-group animationDuration="0ms">
                            <mat-tab label="Flight Information">Content 1</mat-tab>
                            <mat-tab label="Fare Rules & Details">Content 2</mat-tab>
                            <mat-tab label="Baggage Information">Content 3</mat-tab>
                        </mat-tab-group> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="col-12 float-start fareFixBox" *ngIf="2==this.getResultRes.Results.length">
    
    <div class="col-4">
        <div class="col-12 mb-2 p-2 float-start result-bx">
            <div class="d-inline air-name" *ngFor="let aircode of returnFirstOb.Segments[0]">
                <small>{{aircode.Airline.AirlineName}}-{{aircode.Airline.AirlineCode}}-{{aircode.Airline.FlightNumber}} </small>
            </div>
            <div class="col-12 float-start">
                
                <div class="col-2 float-start air-logo" *ngFor="let air of returnFirstOb.Segments[0]">
                    <div>
                        <img src="assets/images/air-logo/{{air.Airline.AirlineCode}}.png" width="40" />
                    </div>
                </div>
                <div class="col-6 float-start">
                    <div class="col-4 float-start origin">
                        <div class="col-12 float-start" *ngFor="let air of returnFirstOb.Segments[0]">
                            <span>{{air.Origin.DepTime.substring(11).slice(0,-3)}}</span><br>
                        {{air.Origin.Airport.CityName}}
                        </div>
                    </div>
                    <div class="col-4 float-start text-center duration">
                        20h:30m
                    </div>
                    <div class="col-4 float-start text-end destination">
                        <div class="col-12 float-start" *ngFor="let air of returnFirstOb.Segments[0]">
                            <span>{{air.Destination.ArrTime.substring(11).slice(0,-3)}}</span><br>
                        {{air.Destination.Airport.CityName}}
                        </div>
                    </div>                   
                    
                </div>
                <div class="col-4 float-start">
                    <div class="col-12 col-md-6 text-center float-start">
                        <span class="text-danger fs-6">{{returnFirstOb.Fare.PublishedFare | number:'1.0-0'}}</span>
                    </div>
                    <!-- <div class="col-12 col-md-6 text-center float-start">
                        <button mat-stroked-button color="primary" type="submit"  (click)="returnmoreBtnOb(returnFirstOb.FlightNo)" class="p-1 returnMoreBtn">More Fares</button>
                    </div> -->
                </div>            
            </div>
            
        </div>
    </div>
    <div class="col-4 float-start">
        <div class="col-12 mb-2 p-2 float-start result-bx">
            <div class="d-inline air-name" *ngFor="let aircode of returnFirstIb.Segments[0]">
                <small>{{aircode.Airline.AirlineName}}-{{aircode.Airline.AirlineCode}}-{{aircode.Airline.FlightNumber}} </small>
            </div>
            <div class="col-12 float-start">
                
                <div class="col-2 float-start air-logo" *ngFor="let air of returnFirstIb.Segments[0]">
                    <div>
                        <img src="assets/images/air-logo/{{air.Airline.AirlineCode}}.png" width="40" />
                    </div>
                </div>
                <div class="col-6 float-start">
                    <div class="col-4 float-start origin">
                        <div class="col-12 float-start" *ngFor="let air of returnFirstIb.Segments[0]">
                            <span>{{air.Origin.DepTime.substring(11).slice(0,-3)}}</span><br>
                        {{air.Origin.Airport.CityName}}
                        </div>
                    </div>
                    <div class="col-4 float-start text-center duration">
                        20h:30m
                    </div>
                    <div class="col-4 float-start text-end destination">
                        <div class="col-12 float-start" *ngFor="let air of returnFirstIb.Segments[0]">
                            <span>{{air.Destination.ArrTime.substring(11).slice(0,-3)}}</span><br>
                        {{air.Destination.Airport.CityName}}
                        </div>
                    </div>                   
                    
                </div>
                <div class="col-4 float-start">
                    <div class="col-12 col-md-6 text-center float-start">
                        <span class="text-danger fs-6">{{returnFirstIb.Fare.PublishedFare | number:'1.0-0'}}</span>
                    </div>                    
                    <!-- <div class="col-12 col-md-6 text-center float-start">
                        <button mat-stroked-button color="primary" type="submit"  (click)="returnmoreBtnIb(returnFirstIb.FlightNo)" class="p-1 returnMoreBtn">More Fares</button>
                    </div> -->
                </div>            
            </div>
            
        </div>
    </div>
    <div class="col-4 float-start">
        <div class="col-12 mb-2 p-2 text-center float-start result-bx">
        Grand Total <b class="text-danger fs-6 me-2">{{returnFirstOb.Fare.PublishedFare + returnFirstIb.Fare.PublishedFare | number:'1.0-0'}}</b>
        <button mat-flat-button color="accent" (click)="returnGetItinerary()" class="pt-1 pb-1 col-3 d-none">Book</button>
        <button mat-flat-button color="accent" type="button" class="btn btn-primary pt-1 pb-1 ps-4 pe-4" data-bs-toggle="modal" data-bs-target="#staticBackdrop">
            Continue
          </button>
    </div>        
    </div>
</div>

<!-- Button trigger modal -->
  
  <!-- return box Modal -->
  <div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true" *ngIf="2==this.getResultRes.Results.length">
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-header">
          <h2 class="modal-title" id="staticBackdropLabel">More Fares</h2>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
            <div class="col-12 col-md-6 float-start pe-1">
                <b>Depart</b>
                <div class="col-12 float-start">
                    <div class="col-12 mb-2 p-2 float-start result-bx">
                        <div class="d-inline air-name" *ngFor="let aircode of returnFirstOb.Segments[0]">
                            <small>{{aircode.Airline.AirlineName}}-{{aircode.Airline.AirlineCode}}-{{aircode.Airline.FlightNumber}} </small>
                        </div>
                        <div class="col-12 float-start">
                            
                            <div class="col-2 float-start air-logo" *ngFor="let air of returnFirstOb.Segments[0]">
                                <div>
                                    <img src="assets/images/air-logo/{{air.Airline.AirlineCode}}.png" width="40" />
                                </div>
                            </div>
                            <div class="col-6 float-start">
                                <div class="col-4 float-start origin">
                                    <div class="col-12 float-start" *ngFor="let air of returnFirstOb.Segments[0]">
                                        <span>{{air.Origin.DepTime.substring(11).slice(0,-3)}}</span><br>
                                    {{air.Origin.Airport.CityName}}
                                    </div>
                                </div>
                                <div class="col-4 float-start text-center duration">
                                    20h:30m
                                </div>
                                <div class="col-4 float-start text-end destination">
                                    <div class="col-12 float-start" *ngFor="let air of returnFirstOb.Segments[0]">
                                        <span>{{air.Destination.ArrTime.substring(11).slice(0,-3)}}</span><br>
                                    {{air.Destination.Airport.CityName}}
                                    </div>
                                </div>                   
                                
                            </div>
                            <div class="col-4 float-start">
                                <div class="col-12 col-md-6 text-center float-start">
                                    <span class="text-danger fs-6">{{returnFirstOb.Fare.PublishedFare | number:'1.0-0'}}</span>
                                </div>
                                <!-- <div class="col-12 col-md-6 text-center float-start">
                                    <button mat-stroked-button color="primary" type="submit"  (click)="returnmoreBtnOb(returnFirstOb.FlightNo)" class="p-1 returnMoreBtn">More Fares</button>
                                </div> -->
                            </div>            
                        </div>
                        
                    </div>
                </div>
                <div class="col-12 float-start text-center ps-1 pe-1">                    
                    <div class="col-12 return-more-box float-start">
                        <div class="col-12 more-bx p-2 mb-2 float-start" *ngFor="let res of returnmoreFaresOb; index as i">
                            <b class="d-block">
                                <span *ngIf="res.AirlineRemark=='6E FARE test.'">Lower Fare</span>
                                <span *ngIf="res.AirlineRemark==' This is a Flexi Fare. Includes free seat(as per restriction). Lower cancellation. Please refer fare rules.. . 6E FARE test.'">Flexi Fare</span>
                                <span *ngIf="res.AirlineRemark=='G8 L2 G8S.'">Economy Fare l</span>
                                <span *ngIf="res.AirlineRemark=='G8 st G8C.'">Economy Fare s</span>
                                <span *ngIf="res.AirlineRemark=='This is a FLXI Fare. Journey(applicable on 1st segment only) includes following bundle items:Refundable Fare with Free Meal and Seat (17th Row onwards), . Go air API.'">Flexi Fare</span>
                            </b>
                            <span class="d-block mt-2 mb-2">{{res.PublishedFare}}</span> 
                            <mat-radio-button value="{{res.ResultIndex}}" name="moreOb" (change)="returnGetItineraryMoreOb($event)" [checked]="i === 0"></mat-radio-button>                                                                                                            
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-6 float-start ps-1">
                <b>Return</b>
                <div class="col-12 float-start">
                    <div class="col-12 mb-2 p-2 float-start result-bx">
                        <div class="d-inline air-name" *ngFor="let aircode of returnFirstIb.Segments[0]">
                            <small>{{aircode.Airline.AirlineName}}-{{aircode.Airline.AirlineCode}}-{{aircode.Airline.FlightNumber}} </small>
                        </div>
                        <div class="col-12 float-start">
                            
                            <div class="col-2 float-start air-logo" *ngFor="let air of returnFirstIb.Segments[0]">
                                <div>
                                    <img src="assets/images/air-logo/{{air.Airline.AirlineCode}}.png" width="40" />
                                </div>
                            </div>
                            <div class="col-6 float-start">
                                <div class="col-4 float-start origin">
                                    <div class="col-12 float-start" *ngFor="let air of returnFirstIb.Segments[0]">
                                        <span>{{air.Origin.DepTime.substring(11).slice(0,-3)}}</span><br>
                                    {{air.Origin.Airport.CityName}}
                                    </div>
                                </div>
                                <div class="col-4 float-start text-center duration">
                                    20h:30m
                                </div>
                                <div class="col-4 float-start text-end destination">
                                    <div class="col-12 float-start" *ngFor="let air of returnFirstIb.Segments[0]">
                                        <span>{{air.Destination.ArrTime.substring(11).slice(0,-3)}}</span><br>
                                    {{air.Destination.Airport.CityName}}
                                    </div>
                                </div>                   
                                
                            </div>
                            <div class="col-4 float-start">
                                <div class="col-12 col-md-6 text-center float-start">
                                    <span class="text-danger fs-6">{{returnFirstIb.Fare.PublishedFare | number:'1.0-0'}}</span>
                                </div>                    
                                <!-- <div class="col-12 col-md-6 text-center float-start">
                                    <button mat-stroked-button color="primary" type="submit"  (click)="returnmoreBtnIb(returnFirstIb.FlightNo)" class="p-1 returnMoreBtn">More Fares</button>
                                </div> -->
                            </div>            
                        </div>
                        
                    </div>
                </div>
                <div class="col-12 float-start text-center ps-1 pe-1">                              
                    <div class="col-12 return-more-box float-start">
                        <div class="col-12 more-bx p-2 mb-2 float-start" *ngFor="let res of returnmoreFaresIb; index as i">
                            <b class="d-block">
                                <span *ngIf="res.AirlineRemark=='6E FARE test.'">Lower Fare</span>
                                <span *ngIf="res.AirlineRemark==' This is a Flexi Fare. Includes free seat(as per restriction). Lower cancellation. Please refer fare rules.. . 6E FARE test.'">Flexi Fare</span>
                                <span *ngIf="res.AirlineRemark=='G8 L2 G8S.'">Economy Fare l</span>
                                <span *ngIf="res.AirlineRemark=='G8 st G8C.'">Economy Fare s</span>
                                <span *ngIf="res.AirlineRemark=='This is a FLXI Fare. Journey(applicable on 1st segment only) includes following bundle items:Refundable Fare with Free Meal and Seat (17th Row onwards), . Go air API.'">Flexi Fare</span>
                            </b>
                            <span class="d-block mt-2 mb-2">{{res.PublishedFare}}</span> 
                            <mat-radio-button value="{{res.ResultIndex}}" name="moreIb" (change)="returnGetItineraryMoreIb($event)" [checked]="i === 0"></mat-radio-button>                                                               
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <div>Grand Total <b class="text-danger fs-6 me-2">{{returnFirstOb.Fare.PublishedFare + returnFirstIb.Fare.PublishedFare | number:'1.0-0'}}</b></div>
          <button mat-flat-button color="accent" type="button" data-bs-dismiss="modal" (click)="returnGetItinerary()" class="btn btn-primary pt-1 pb-1 ps-4 pe-4">Book Now</button>
        </div>
      </div>
    </div>
  </div>
</section>