<section class="container-fluid pt-3">
    <!-- <button (click)="generatePdf()">Generate PDF</button>     -->
    <div class="htmltopdf" id="htmltopdf">                   
        <div class="htmltopdf-bx float-start p-1">
            <header>
                <div class="col-6 float-start">
                    <img  src="assets/images/logo.png" width="152" height="34">
                </div>
                <div class="col-6 float-start text-end">                    
                    <span class="d-block">Booking Id: <b>{{id}}</b></span>
                    <span class="d-block">Booking Date:{{results.bookingDate.substring(0).slice(0,-14)}}</span>
                    <span class="d-block">Booking Date:{{results.bookingDate.substring(11).slice(0,-8)}}</span>
                </div>
                <div class="col-12 float-start text-center p-1">
                    <span class="text-success h5">Booking Confirmed</span>
                </div>
            </header>
            <mat-card class="mb-2 d-block col-12 float-start" *ngIf="this.results.tripType==1">
            <div class="col-12 float-start">                
                <div *ngFor="let seg of bisegments" class="col-12 float-start">
                    <b class="d-block" *ngIf="seg.GroundTime==0">Itinerary Derparture</b>
                    <div *ngIf="seg.GroundTime!==0" class="col-12 bg-light float-start text-center p-1">{{math.trunc(seg.GroundTime/60)}}h:{{seg.GroundTime%60}}m Layover in {{seg.Origin.Airport.CityName}}</div>
                    <div class="col-3 float-start text-start"><span class="d-block">{{seg.Airline.AirlineName}}</span><span class="d-block">{{seg.Airline.AirlineCode}}-{{seg.Airline.FlightNumber}}</span></div>
                    <div class="col-3 float-start text-start"><span class="d-block">{{seg.Origin.DepTime.substring(11).slice(0,-3)}}</span> <span class="d-block">{{seg.Origin.Airport.CityName}}</span><span class="d-block">{{seg.Origin.DepTime.substring(0).slice(0,-9)}}</span></div>
                    <div class="col-3 float-start text-center"><mat-icon>arrow_forward</mat-icon><span class="d-block">{{seg.Duration/60| number : '1.0-0'}}h:{{seg.Duration%60}}m</span></div>
                    <div class="col-3 float-start text-end"><span class="d-block">{{seg.Destination.ArrTime.substring(11).slice(0,-3)}}</span> <span class="d-block">{{seg.Destination.Airport.CityName}}</span><span class="d-block">{{seg.Destination.ArrTime.substring(0).slice(0,-9)}}</span></div>
                    <div class="col-12 float-start d-block mb-2">
                        Baggage {{seg.Baggage}}, {{seg.CabinBaggage}}
                    </div>
                  </div>
            </div>                          
            </mat-card>
            <mat-card class="mb-2 d-block col-12 float-start" *ngIf="this.results.tripType==1">
                
                <mat-panel-title class="col-12 d-block"><b>Addons Departure</b></mat-panel-title>
                <div class="col-12 font-weight" *ngFor="let seats of seatSsrOb.ObSeatArray">
                  <div class="col-2 float-start">Seat(s)</div>
                  <div class="col-10 float-start" *ngFor="let seat of seats">{{seat.seat.split(" ")[0]}}-{{seat.seat.split(" ")[1]}}{{seat.seat.split(" ")[2]}}</div>
                </div>
                <div class="col-12 font-weight" *ngFor="let meals of mealSsrOb">
                  <div class="col-2 float-start">Meal(s)</div>
                  <div class="col-10 float-start" *ngFor="let meal of meals">{{meal.Origin}}-{{meal.Destination}} {{meal.Quantity}} {{meal.AirlineDescription}}</div>
                </div>
                <div class="col-12 font-weight" *ngFor="let baggages of baggageSsrOb">
                  <div class="col-2 float-start">Baggage(s)</div>
                  <div class="col-10 float-start" *ngFor="let baggage of baggages">{{baggage.Origin}}-{{baggage.Destination}} {{baggage.Weight}}KG</div>
                </div>
              </mat-card>
              
            <mat-card class="mb-2 d-block col-12 float-start" *ngIf="this.results.tripType==1">
                <b>Traveler Details</b>
                <div class="col-12 font-weight">
                    <div class="col-1 float-start">No.</div><div class="col-2 float-start">Type</div><div class="col-2 float-start">Title</div><div class="col-3 float-start">First Name</div><div class="col-3 float-start">Last Name</div><div class="col-1 float-start">PNR</div>
                </div>
                <mat-card-content>
                    <div class="col-12 float-start mb-3">
                        <div class="float-start bg-light col-12 m-1 p-1">
                          <div *ngFor="let adults of travelerDetails.adult">
                            <div class="col-1 float-start">1</div>
                            <div class="col-2 float-start">Adults</div>
                            <div class="col-2 float-start">{{adults.title}}</div>
                            <div class="col-3 float-start">{{adults.firstName}}</div>
                            <div class="col-3 float-start">{{adults.lastName}}</div>
                            <div class="col-1 float-start">{{pnr.obPnr}}</div>
                        </div>
                        </div>
                        <div class="float-start bg-light col-12 m-1 p-1">
                          <div class="float-start col-12" *ngFor="let childs of travelerDetails.child">
                            <div class="col-1 float-start">fd</div>
                            <div class="col-2 float-start">Childs</div>
                            <div class="col-2 float-start">{{childs.title}}</div>
                            <div class="col-3 float-start">{{childs.firstName}}</div>
                            <div class="col-3 float-start">{{childs.lastName}}</div>
                            <div class="col-1 float-start">{{pnr.obPnr}}</div>
                          </div>
                        </div>
                        <div class="float-start bg-light col-12 m-1 p-1">
                          <div class="float-start col-12" *ngFor="let infants of travelerDetails.infant">
                            <div class="col-1 float-start">df</div>
                            <div class="col-2 float-start">Infants</div>
                            <div class="col-2 float-start">{{infants.title}}</div>
                            <div class="col-3 float-start">{{infants.firstName}}</div>
                            <div class="col-3 float-start">{{infants.lastName}}</div>
                            <div class="col-1 float-start">{{pnr.obPnr}}</div>
                          </div>
                        </div>
                        </div>
                </mat-card-content>
            </mat-card>
            <mat-card class="col-12 float-start mb-1" *ngIf="this.results.tripType==2">
              <div>
                <b>Itinerary Departure</b>
                <div *ngFor="let seg of bisegmentsOb">
                  <div *ngIf="seg.GroundTime!==0" class="col-12 bg-light float-start p-1 text-center">{{math.trunc(seg.GroundTime/60)}}h:{{seg.GroundTime%60}}m Layover in {{seg.Origin.Airport.CityName}}</div>
                  <div class="col-3 float-start text-start"><span class="d-block">{{seg.Airline.AirlineName}}</span><span class="d-block">{{seg.Airline.AirlineCode}}-{{seg.Airline.FlightNumber}}</span></div>
                  <div class="col-3 float-start text-start"><span class="d-block">{{seg.Origin.DepTime.substring(11).slice(0,-3)}}</span> <span class="d-block">{{seg.Origin.Airport.CityName}}</span><span class="d-block">{{seg.Origin.DepTime.substring(0).slice(0,-9)}}</span></div>
                  <div class="col-3 float-start text-center"><mat-icon>arrow_forward</mat-icon><span class="d-block">{{seg.Duration/60| number : '1.0-0'}}h:{{seg.Duration%60}}m</span></div>
                  <div class="col-3 float-start text-end"><span class="d-block">{{seg.Destination.ArrTime.substring(11).slice(0,-3)}}</span> <span class="d-block">{{seg.Destination.Airport.CityName}}</span><span class="d-block">{{seg.Destination.ArrTime.substring(0).slice(0,-9)}}</span></div>
                  <div class="col-12 float-start d-block mb-2">
                      Baggage {{seg.Baggage}}, {{seg.CabinBaggage}}
                  </div>
                </div>
              </div>
            </mat-card>
            <mat-card class="mb-2 d-block col-12 float-start" *ngIf="this.results.tripType==2">
              <mat-panel-title class="col-12 d-block"><b>Addons-Departure</b></mat-panel-title>                
              <div class="col-12 font-weight" *ngFor="let seats of retseatSsrOb">
                <div class="col-2 float-start">Seat(s)</div>
                <div class="col-10 float-start">{{seats.seat.split(" ")[0]}}-{{seats.seat.split(" ")[1]}}{{seats.seat.split(" ")[2]}}</div>
              </div>
              <div class="col-12 font-weight" *ngFor="let meals of retmealSsrOb">
                <div class="col-2 float-start">Meal(s)</div>
                <div class="col-10 float-start">{{meals.Origin}}-{{meals.Destination}} {{meals.Quantity}} {{meals.AirlineDescription}}</div>
              </div>
              <div class="col-12 font-weight" *ngFor="let baggages of retbaggageSsrOb">
                <div class="col-2 float-start">Baggage(s)</div>
                <div class="col-10 float-start">{{baggages.Origin}}-{{baggages.Destination}} {{baggages.Weight}}KG</div>
              </div>
            </mat-card>
            <mat-card class="mb-2 d-block col-12 float-start" *ngIf="this.results.tripType==2">
              <b>Traveler Details</b>
              <div class="col-12 font-weight">
                  <div class="col-1 float-start">No.</div><div class="col-2 float-start">Type</div><div class="col-2 float-start">Title</div><div class="col-3 float-start">First Name</div><div class="col-3 float-start">Last Name</div><div class="col-1 float-start">PNR</div>
              </div>
              <mat-card-content>
                  <div class="col-12 float-start mb-3">
                      <div class="float-start bg-light col-12 m-1 p-1">
                        <div *ngFor="let adults of travelerDetails.adult">
                          <div class="col-1 float-start">1</div>
                          <div class="col-2 float-start">Adults</div>
                          <div class="col-2 float-start">{{adults.title}}</div>
                          <div class="col-3 float-start">{{adults.firstName}}</div>
                          <div class="col-3 float-start">{{adults.lastName}}</div>
                          <div class="col-1 float-start">{{pnr.obPnr}}</div>
                      </div>
                      </div>
                      <div class="float-start bg-light col-12 m-1 p-1">
                        <div class="float-start col-12" *ngFor="let childs of travelerDetails.child">
                          <div class="col-1 float-start">fd</div>
                          <div class="col-2 float-start">Childs</div>
                          <div class="col-2 float-start">{{childs.title}}</div>
                          <div class="col-3 float-start">{{childs.firstName}}</div>
                          <div class="col-3 float-start">{{childs.lastName}}</div>
                          <div class="col-1 float-start">{{pnr.obPnr}}</div>
                        </div>
                      </div>
                      <div class="float-start bg-light col-12 m-1 p-1">
                        <div class="float-start col-12" *ngFor="let infants of travelerDetails.infant">
                          <div class="col-1 float-start">df</div>
                          <div class="col-2 float-start">Infants</div>
                          <div class="col-2 float-start">{{infants.title}}</div>
                          <div class="col-3 float-start">{{infants.firstName}}</div>
                          <div class="col-3 float-start">{{infants.lastName}}</div>
                          <div class="col-1 float-start">{{pnr.obPnr}}</div>
                        </div>
                      </div>
                      </div>
              </mat-card-content>
          </mat-card>
            <mat-card class="col-12 float-start" *ngIf="this.results.tripType==2">
                <div>
                    <b>Itinerary Return</b>
                    <div *ngFor="let seg of bisegmentsIb">
                      <div *ngIf="seg.GroundTime!==0" class="col-12 bg-light float-start p-1 text-center">{{math.trunc(seg.GroundTime/60)}}h:{{seg.GroundTime%60}}m Layover in {{seg.Origin.Airport.CityName}}</div>
                      <div class="col-3 float-start text-start"><span class="d-block">{{seg.Airline.AirlineName}}</span><span class="d-block">{{seg.Airline.AirlineCode}}-{{seg.Airline.FlightNumber}}</span></div>
                      <div class="col-3 float-start text-start"><span class="d-block">{{seg.Origin.DepTime.substring(11).slice(0,-3)}}</span> <span class="d-block">{{seg.Origin.Airport.CityName}}</span><span class="d-block">{{seg.Origin.DepTime.substring(0).slice(0,-9)}}</span></div>
                      <div class="col-3 float-start text-center"><mat-icon>arrow_forward</mat-icon><span class="d-block">{{seg.Duration/60| number : '1.0-0'}}h:{{seg.Duration%60}}m</span></div>
                      <div class="col-3 float-start text-end"><span class="d-block">{{seg.Destination.ArrTime.substring(11).slice(0,-3)}}</span> <span class="d-block">{{seg.Destination.Airport.CityName}}</span><span class="d-block">{{seg.Destination.ArrTime.substring(0).slice(0,-9)}}</span></div>
                      <div class="col-12 float-start d-block mb-2">
                          Baggage {{seg.Baggage}}, {{seg.CabinBaggage}}
                      </div>
                    </div>
                  </div>
            </mat-card>
            <mat-card class="mb-2 d-block col-12 float-start mt-1" *ngIf="this.results.tripType==2">
              <mat-panel-title class="col-12 d-block"><b>Addons-Return</b></mat-panel-title>                
              <div class="col-12 font-weight" *ngFor="let seats of retseatSsrIb">
                <div class="col-2 float-start">Seat(s)</div>
                <div class="col-10 float-start">{{seats.seat.split(" ")[0]}}-{{seats.seat.split(" ")[1]}}{{seats.seat.split(" ")[2]}}</div>
              </div>
              <div class="col-12 font-weight" *ngFor="let meals of retmealSsrIb">
                <div class="col-2 float-start">Meal(s)</div>
                <div class="col-10 float-start">{{meals.Origin}}-{{meals.Destination}} {{meals.Quantity}} {{meals.AirlineDescription}}</div>
              </div>
              <div class="col-12 font-weight" *ngFor="let baggages of retbaggageSsrIb">
                <div class="col-2 float-start">Baggage(s)</div>
                <div class="col-10 float-start">{{baggages.Origin}}-{{baggages.Destination}} {{baggages.Weight}}KG</div>
              </div>
            </mat-card>
              <mat-card class="mb-2 d-block col-12 float-start" *ngIf="this.results.tripType==2">
                <b>Traveler Details</b>
                <div class="col-12 font-weight">
                    <div class="col-1 float-start">No.</div><div class="col-2 float-start">Type</div><div class="col-2 float-start">Title</div><div class="col-3 float-start">First Name</div><div class="col-3 float-start">Last Name</div><div class="col-1 float-start">PNR</div>
                </div>
                <mat-card-content>
                    <div class="col-12 float-start mb-3">
                        <div class="float-start bg-light col-12 m-1 p-1">
                          <div *ngFor="let adults of travelerDetails.adult">
                            <div class="col-1 float-start">1</div>
                            <div class="col-2 float-start">Adults</div>
                            <div class="col-2 float-start">{{adults.title}}</div>
                            <div class="col-3 float-start">{{adults.firstName}}</div>
                            <div class="col-3 float-start">{{adults.lastName}}</div>
                            <div class="col-1 float-start">{{pnr.ibPnr}}</div>
                        </div>
                        </div>
                        <div class="float-start bg-light col-12 m-1 p-1">
                          <div class="float-start col-12" *ngFor="let childs of travelerDetails.child">
                            <div class="col-1 float-start">fd</div>
                            <div class="col-2 float-start">Childs</div>
                            <div class="col-2 float-start">{{childs.title}}</div>
                            <div class="col-3 float-start">{{childs.firstName}}</div>
                            <div class="col-3 float-start">{{childs.lastName}}</div>
                            <div class="col-1 float-start">{{pnr.ibPnr}}</div>
                          </div>
                        </div>
                        <div class="float-start bg-light col-12 m-1 p-1">
                          <div class="float-start col-12" *ngFor="let infants of travelerDetails.infant">
                            <div class="col-1 float-start">df</div>
                            <div class="col-2 float-start">Infants</div>
                            <div class="col-2 float-start">{{infants.title}}</div>
                            <div class="col-3 float-start">{{infants.firstName}}</div>
                            <div class="col-3 float-start">{{infants.lastName}}</div>
                            <div class="col-1 float-start">{{pnr.ibPnr}}</div>
                          </div>
                        </div>
                        </div>
                </mat-card-content>
            </mat-card>
            <mat-card class="col-12 float-start">
                <div class="col-6 float-start"><b>Total Fare</b></div>
                <div class="col-6 float-start text-end"><b><mat-icon>currency_rupee</mat-icon>{{this.bookingAmount}}</b></div>
            </mat-card>
            </div>
        </div>
</section>
