import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders} from '@angular/common/http';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {Router,ActivatedRoute} from '@angular/router';
import {environment} from '../../../../environments/environment';
import { FlightService } from '../../../services/flight.service';
import { map } from 'rxjs/operators';
import * as $ from 'jquery';
@Component({
  selector: 'app-search-results',
  templateUrl: './search-results.component.html',
  styleUrls: ['./search-results.component.css']
})
export class SearchResultsComponent implements OnInit {
  
  url = environment.apiUrl;
  isShown: boolean = false ;
  decpriceisShow:boolean=false;
  incpriceisShow:boolean=true;
  incdepartisShow:boolean=true;
  decdepartisShow:boolean=false;
  incarrivalisShow:boolean=true;
  decarrivalisShow:boolean=false;
  typ:any;
  org:any;
  des:any;
  adt:any;
  chd:any;
  inf:any;
  cls:any;
  dep:any;
  arr:any;
  results:any;
  index: any;
  getResultRes: any;
  ags: any;
  resultsx: any;
  result: any;
  //allResults: any;
  currDiv: any;
  airlineSort: any;
  disResult: any;
  disrlt: any;
  airMark:any;
  disResults: any;
  returnResult: any;
  returnResults: any;
  returnResultob: any;
  returnResultib: any;
  returnResultOb: any;
  returnResultOB: any;
  returnResultIB: any;
  returnResultIb: any;
  onewayResultob: any;
  onewayResultOb: any;
  onewayResultOB: any=[];
  onewaymoreFares: any;
  returnmoreFaresOb: any;
  returnmoreFaresIb: any;  
  returnFirstOb: any;
  returnFirstIb: any;
  returnFirstObIndexOf: any;
  onewayAirlineFilter: any;
  getAirfilterOB: any;
  stopArrayFilterOB:any;
  depArrayFilterOB:any;
  arrArrayFilterOB:any;
  depArrayFilterIB:any;
  arrArrayFilterIB:any;
  applyAirFilterGOBVal:any=[];
  applyAirFilterGIBVal:any=[];
  allonewayObMinFare:any;
  allonewayObMaxFare:any;
  airline:any;
  priceFrame:any;
  constructor(private formBuilder:FormBuilder, private httpClient:HttpClient, private router:Router, private route:ActivatedRoute, private flightService:FlightService) { }

  ngOnInit(): void {
    
    console.log(this.priceFrame)
    this.route.queryParams.subscribe((params)=>{
      //console.log(params.json);
      //this.data= JSON.parse(atob(params.data));
      this.index= JSON.parse(params['index'])       
      //console.log(index.typ)
     // console.log(this.segments)
      
      
      });
      this.httpClient.get(this.url+"/flight/flight-get-discounts").subscribe(resp =>{
        //   this.isShow = false;
         this.disrlt = resp;
         this.disResult = this.disrlt[0];  
         //console.log(this.disrlt)
    
     });
      $(document).ready(function () {
      
        
        
    });
    //Flight Search
     this.httpClient.get(this.url+"/flight/flight-search/"+this.index.typ+"/"+this.index.org+"/"+this.index.des+"/"+this.index.adt+"/"+this.index.chd+"/"+this.index.inf+"/"+this.index.cls+"/"+this.index.dep+"/"+this.index.arr+"/").subscribe(resp =>{
       //   this.isShow = false;
        this.getResultRes = resp;        
        console.log(this.getResultRes)
        if(1==this.getResultRes.Results.length && 1==this.index.typ){
          //debugger
          this.onewayResultob = this.getResultRes.Results[0];
          //debugger
          this.onewayResultOb= this.onewayResultob.map((res:any)=>{
            return {
              ResultIndex:res.ResultIndex,
              ResultFareType:res.ResultFareType,
              AirlineCode:res.AirlineCode,
              Source:res.Source,
              AirlineRemark:res.AirlineRemark,
              IsLCC:res.IsLCC,
              Fare:res.Fare,
              FareBreakdown:res.FareBreakdown,
              FareRules:res.FareRules,
              StopNo:res.Segments[0].length-1,
              DepTime:res.Segments[0].map((a:any)=>{
                return parseInt(a.Origin.DepTime.substring(11).slice(0,-6),10)
              
              })[0].toString(),
              ArrTime:res.Segments[0].map((a:any)=>{
                return parseInt(a.Destination.ArrTime.substring(11).slice(0,-6),10)
              
              })[res.Segments[0].length-1].toString(),
              DpTime:res.Segments[0].map((a:any)=>{
                return parseInt(a.Origin.DepTime.substring(11).slice(0,-6),10)
              
              })[0],
              ArTime:res.Segments[0].map((a:any)=>{
                return parseInt(a.Destination.ArrTime.substring(11).slice(0,-6),10)
              
              })[res.Segments[0].length-1],
              PublishedFare:Math.round(res.Fare.PublishedFare),
    
              DepFlightNo:res.Segments[0].map((a:any)=>{
                return a.Airline.FlightNumber              
              }).toString().replace(",", ""),
              Segments:res.Segments,
              Duration:res.Segments[0].map((a:any,x:any)=>a.Duration).reduce(function(a:any,b:any){
                return a+b;
              })+res.Segments[0].map((a:any)=>a.GroundTime).reduce(function(a:any,b:any){
                return a+b;
              }),
              Discount:this.disrlt.filter(function(el: { airlineclass: string; }) {
        
                return el.airlineclass== res.AirlineRemark;
                })
            }    
            });
    
           console.log(this.results)
            const onewayhidefareob = this.onewayResultOb.filter(function(el: { AirlineRemark: string; }) {
               return el.AirlineRemark !== " This is a Flexi Fare. Includes free seat(as per restriction). Lower cancellation. Please refer fare rules.. . 6E FARE test.";
               });          
              this.onewayResultOB = onewayhidefareob;
              this.onewayResultOBFilterArray = onewayhidefareob;
              console.log(this.onewayResultOB)
          //debugger
          this.allonewayObMinFare=Math.min.apply(Math, this.onewayResultOB.map((i: { PublishedFare: any; })=>i.PublishedFare));
          this.allonewayObMaxFare=Math.max.apply(Math, this.onewayResultOB.map((i: { PublishedFare: any; })=>i.PublishedFare));
          this.priceFrame=this.allonewayObMaxFare
          debugger
          //let onewayDupOb = new Set(this.onewayResultOB.map((v: { AirlineCode: any; }) => v.AirlineCode));
          let getAirCode = this.onewayResultOB.reduce((a: any[], b: { AirlineCode: any; })=>{            
            let obj = a.find((item: { AirlineCode: any; })=>item.AirlineCode === b.AirlineCode);
            if(obj){
              return a;
            }
            return a.concat([b]);             
          },[]);
          //console.log(getAirCode)
          this.getAirfilterOB =getAirCode.map((res:any)=>{
            let aironlyObj= onewayhidefareob.filter(function(el: { AirlineCode: string; }) {              
              return el.AirlineCode == res.AirlineCode;
              });
            //let min = Math.min(aironlyObj.map((item: { PublishedFare: any; }) => item.PublishedFare));
            let min=Math.min.apply(Math, aironlyObj.map((i: { PublishedFare: any; })=>i.PublishedFare))
            //debugger
            return {
              AirlineCode:res.AirlineCode,
              AirlineName:res.Segments[0].map((a:any)=>{
                return a.Airline.AirlineName
              
              }),
              AirResultCount:aironlyObj.length,
              MinFare:min,
              checked:false
            }
          });
          //console.log(this.getAirfilter);
          this.stopArrayFilterOB =[
            {StopNo:0,StopName:'Non Stop',checked:false},
            {StopNo:1,StopName:'1 Stop',checked:false},
            {StopNo:2,StopName:'2 Stop',checked:false},
            {StopNo:3,StopName:'3+ Stop',checked:false}
          ]
          //console.log(this.stopArrayFilter)
          this.depArrayFilterOB =[
            {DepTime:'D0,D1,D2,D3,D4,D5',DepName:'Before 6AM',checked:false},
            {DepTime:'D6,D7,D8,D9,D10,D11',DepName:'6AM-12PM',checked:false},
            {DepTime:'D12,D13,D14,D15,D16,D17',DepName:'12PM-6PM',checked:false},
            {DepTime:'D18,D19,D20,D21,D22,D23',DepName:'After 6PM',checked:false}
          ]
          //console.log(this.depArrayFilter)
          this.arrArrayFilterOB =[
            {ArrTime:'A0,A1,A2,A3,A4,A5',ArrName:'Before 6AM',checked:false},
            {ArrTime:'A6,A7,A8,A9,A10,A11',ArrName:'6AM-12PM',checked:false},
            {ArrTime:'A12,A13,A14,A15,A16,A17',ArrName:'12PM-6PM',checked:false},
            {ArrTime:'A18,A19,A20,A21,A22,A23',ArrName:'After 6PM',checked:false}
          ]
          //console.log(this.depArrayFilter)
        
        }
        else if(1==this.getResultRes.Results.length && 2==this.index.typ){
          //debugger
          this.onewayResultob = this.getResultRes.Results[0];
          //debugger
          this.onewayResultOb= this.onewayResultob.map((res:any)=>{
            return {
              ResultIndex:res.ResultIndex,
              ResultFareType:res.ResultFareType,
              AirlineCode:res.AirlineCode,
              Source:res.Source,
              AirlineRemark:res.AirlineRemark,
              IsLCC:res.IsLCC,
              Fare:res.Fare,
              FareBreakdown:res.FareBreakdown,
              FareRules:res.FareRules,
              StopNo:res.Segments[0].length-1,
              StopNoG:res.Segments[1].length-1,
              DepTime:res.Segments[0].map((a:any)=>{
                return parseInt(a.Origin.DepTime.substring(11).slice(0,-6),10)
              
              })[0].toString(),
              ArrTime:res.Segments[0].map((a:any)=>{
                return parseInt(a.Destination.ArrTime.substring(11).slice(0,-6),10)              
              })[res.Segments[0].length-1].toString(),
              DepTimeG:res.Segments[1].map((a:any)=>{
                return parseInt(a.Origin.DepTime.substring(11).slice(0,-6),10)              
              })[0].toString(),
              ArrTimeG:res.Segments[1].map((a:any)=>{
                return parseInt(a.Destination.ArrTime.substring(11).slice(0,-6),10)              
              })[res.Segments[1].length-1].toString(),              
              PublishedFare:Math.round(res.Fare.PublishedFare),    
              DepFlightNo:res.Segments[0].map((a:any)=>{
                return a.Airline.FlightNumber              
              }).toString().replace(",", ""),
              RetFlightNo:res.Segments[1].map((a:any)=>{
                return a.Airline.FlightNumber              
              }).toString().replace(",", ""),
              Segments:res.Segments,
              Discount:this.disrlt.filter(function(el: { airlineclass: string; }) {
        
                return el.airlineclass== res.AirlineRemark;
                })
            }
    
            });
    
           console.log(this.results)
            const onewayhidefareob = this.onewayResultOb.filter(function(el: { AirlineRemark: string; }) {
               return el.AirlineRemark !== " This is a Flexi Fare. Includes free seat(as per restriction). Lower cancellation. Please refer fare rules.. . 6E FARE test.";
               });          
              this.onewayResultOB = onewayhidefareob;
              this.onewayResultOBFilterArray = onewayhidefareob;
              console.log(this.onewayResultOB)
          //debugger
          this.allonewayObMinFare=Math.min.apply(Math, this.onewayResultOB.map((i: { PublishedFare: any; })=>i.PublishedFare));
          this.allonewayObMaxFare=Math.max.apply(Math, this.onewayResultOB.map((i: { PublishedFare: any; })=>i.PublishedFare));
          this.priceFrame=this.allonewayObMaxFare
          debugger
          //let onewayDupOb = new Set(this.onewayResultOB.map((v: { AirlineCode: any; }) => v.AirlineCode));
          let getAirCode = this.onewayResultOB.reduce((a: any[], b: { AirlineCode: any; })=>{            
            let obj = a.find((item: { AirlineCode: any; })=>item.AirlineCode === b.AirlineCode);
            if(obj){
              return a;
            }
            return a.concat([b]);             
          },[]);
          //console.log(getAirCode)
          this.getAirfilterOB =getAirCode.map((res:any)=>{
            let aironlyObj= onewayhidefareob.filter(function(el: { AirlineCode: string; }) {              
              return el.AirlineCode == res.AirlineCode;
              });
            //let min = Math.min(aironlyObj.map((item: { PublishedFare: any; }) => item.PublishedFare));
            let min=Math.min.apply(Math, aironlyObj.map((i: { PublishedFare: any; })=>i.PublishedFare))
            //debugger
            return {
              AirlineCode:res.AirlineCode,
              AirlineName:res.Segments[0].map((a:any)=>{
                return a.Airline.AirlineName              
              }),
              AirResultCount:aironlyObj.length,
              MinFare:min,
              checked:false
            }
          });
          //console.log(this.getAirfilter);
          this.stopArrayFilterOB =[
            {StopNo:0,StopName:'Non Stop',checked:false},
            {StopNo:1,StopName:'1 Stop',checked:false},
            {StopNo:2,StopName:'2 Stop',checked:false},
            {StopNo:3,StopName:'3+ Stop',checked:false}
          ]
          //console.log(this.stopArrayFilter)
          this.depArrayFilterOB =[
            {DepTime:'D0,D1,D2,D3,D4,D5',DepName:'Before 6AM',checked:false},
            {DepTime:'D6,D7,D8,D9,D10,D11',DepName:'6AM-12PM',checked:false},
            {DepTime:'D12,D13,D14,D15,D16,D17',DepName:'12PM-6PM',checked:false},
            {DepTime:'D18,D19,D20,D21,D22,D23',DepName:'After 6PM',checked:false}
          ]
          //console.log(this.depArrayFilter)
          this.arrArrayFilterOB =[
            {ArrTime:'A0,A1,A2,A3,A4,A5',ArrName:'Before 6AM',checked:false},
            {ArrTime:'A6,A7,A8,A9,A10,A11',ArrName:'6AM-12PM',checked:false},
            {ArrTime:'A12,A13,A14,A15,A16,A17',ArrName:'12PM-6PM',checked:false},
            {ArrTime:'A18,A19,A20,A21,A22,A23',ArrName:'After 6PM',checked:false}
          ]
          //console.log(this.depArrayFilter)
          this.depArrayFilterIB =[
            {DepTime:'D0,D1,D2,D3,D4,D5',DepName:'Before 6AM',checked:false},
            {DepTime:'D6,D7,D8,D9,D10,D11',DepName:'6AM-12PM',checked:false},
            {DepTime:'D12,D13,D14,D15,D16,D17',DepName:'12PM-6PM',checked:false},
            {DepTime:'D18,D19,D20,D21,D22,D23',DepName:'After 6PM',checked:false}
          ]
          //console.log(this.depArrayFilterIB)
          this.arrArrayFilterIB =[
            {ArrTime:'A0,A1,A2,A3,A4,A5',ArrName:'Before 6AM',checked:false},
            {ArrTime:'A6,A7,A8,A9,A10,A11',ArrName:'6AM-12PM',checked:false},
            {ArrTime:'A12,A13,A14,A15,A16,A17',ArrName:'12PM-6PM',checked:false},
            {ArrTime:'A18,A19,A20,A21,A22,A23',ArrName:'After 6PM',checked:false}
          ]
          //console.log(this.arrArrayFilterIB)
        
        }
        else if(2==this.getResultRes.Results.length){
          this.returnResult = this.getResultRes
          this.returnResultob = this.getResultRes.Results[0]
          this.returnResultib = this.getResultRes.Results[1]
          this.returnFirstOb=this.returnResultob[0]
          //this.returnFirstObIndexOf=this.returnResultob.indexOf(this.returnFirstOb)
          this.returnFirstIb=this.returnResultib[0]
          //debugger
          //console.log(this.returnFirstIb)
          //console.log(this.returnResultob)
          //console.log(this.returnResultib)
          this.returnResultOb= this.returnResultob.map((res:any)=>{
            return {
              ResultIndex:res.ResultIndex,
              ResultFareType:res.ResultFareType,
              AirlineCode:res.AirlineCode,
              Source:res.Source,
              AirlineRemark:res.AirlineRemark,
              IsLCC:res.IsLCC,
              Fare:res.Fare,
              FareBreakdown:res.FareBreakdown,
              FareRules:res.FareRules,
              StopNo:res.Segments[0].length,
              DepTime:res.Segments[0].map((a:any)=>{
                return a.Origin.DepTime
              
              }).toString(),
              PublishedFare:Math.round(res.Fare.PublishedFare),
    
              FlightNo:res.Segments[0].map((a:any)=>{
                return a.Airline.FlightNumber
              
              }).toString().replace(",", ""),
              Segments:res.Segments,
              Discount:this.disrlt.filter(function(el: { airlineclass: string; }) {
        
                return el.airlineclass== res.AirlineRemark;
                })
            }
    
            });
            //remove fare ob
            const returnhidefareob = this.returnResultOb.filter(function(el: { AirlineRemark: string; }) {
              return el.AirlineRemark !== " This is a Flexi Fare. Includes free seat(as per restriction). Lower cancellation. Please refer fare rules.. . 6E FARE test.";
              
            });            
            this.returnResultOB = returnhidefareob;
           // console.log(this.returnResultOB)

              this.returnResultIb= this.returnResultib.map((res:any)=>{
                return {
                  ResultIndex:res.ResultIndex,
                  ResultFareType:res.ResultFareType,
                  AirlineCode:res.AirlineCode,
                  Source:res.Source,
                  AirlineRemark:res.AirlineRemark,
                  IsLCC:res.IsLCC,
                  Fare:res.Fare,
                  FareBreakdown:res.FareBreakdown,
                  FareRules:res.FareRules,
                  StopNo:res.Segments[0].length,
                  DepTime:res.Segments[0].map((a:any)=>{
                    return a.Origin.DepTime
                  
                  }).toString(),
                  PublishedFare:Math.round(res.Fare.PublishedFare),
        
                  FlightNo:res.Segments[0].map((a:any)=>{
                    return a.Airline.FlightNumber
                  
                  }).toString().replace(",", ""),
                  Segments:res.Segments,
                  Discount:this.disrlt.filter(function(el: { airlineclass: string; }) {
            
                    return el.airlineclass== res.AirlineRemark;
                    })
                }
        
                });
                //remove fare ib
                const returnhidefareib = this.returnResultIb.filter(function(el: { AirlineRemark: string; }) {
                  return el.AirlineRemark !== " This is a Flexi Fare. Includes free seat(as per restriction). Lower cancellation. Please refer fare rules.. . 6E FARE test.";
                  });
                  this.returnResultIB = returnhidefareib;
                 // console.log(this.returnResultIB)
          
        }
    });
    
    
  }
  priceFilter(event:any){
debugger
this.priceFrame=event.target.value
    const fdsfsds=this.onewayResultOBFilterArray.filter(function(el: { PublishedFare: number; }) {
      return el.PublishedFare <= event.target.value;
      });
      debugger
    this.onewayResultOB=fdsfsds
  }

  //onewayResultOB:any =[];  
  onewayResultOBFilterArray:any=[];
  temArray:any=[];
  newArray:any=[];
  
  resetFilter(){
    debugger
    this.ngOnInit() 
    debugger     
  }
   applyAirFilterOB(event:any){
    //get changed Stop    
    const getStopfilters= this.stopArrayFilterOB
    const changedStopfilter=getStopfilters.map((item:any)=>
    item.StopNo ===Number(event.source.value) ? {...item,checked:!item.checked}:item);
    this.stopArrayFilterOB=changedStopfilter
    
    //get checked Stop    
    //const stopchecked=["1","2"]
    const stopchecked =this.stopArrayFilterOB.filter((item:any)=>item.checked)
    .map((item:any)=>item.StopNo);

    //get changed Dep    
    const getDepfilters= this.depArrayFilterOB
    const changedDepfilter=getDepfilters.map((item:any)=>
    item.DepTime ===event.source.value ? {...item,checked:!item.checked}:item);
    this.depArrayFilterOB=changedDepfilter
    
    //get checked Dep    
    //const depchecked=["00:06"]
    const depchecked =this.depArrayFilterOB.filter((item:any)=>item.checked)
    .map((item:any)=>item.DepTime);
    const aa=depchecked.toString()
    const ab=aa.split(",");
    const depcheckeds=ab.map((s:any) => s.slice(1));
    
    //get changed Arr    
    const getArrfilters= this.arrArrayFilterOB
    const changedArrfilter=getArrfilters.map((item:any)=>
    item.ArrTime ===event.source.value ? {...item,checked:!item.checked}:item);
    this.arrArrayFilterOB=changedArrfilter

    //get checked Arr    
    //const arrchecked=["00:06"]
    const arrchecked =this.arrArrayFilterOB.filter((item:any)=>item.checked)
    .map((item:any)=>item.ArrTime);
    const bb=arrchecked.toString()
    const ba=bb.split(",");
    const arrcheckeds=ba.map((s:any) => s.slice(1));
    
    //get changed Airchecked    
    const getAirfilters= this.getAirfilterOB
    const changedAirfilter=getAirfilters.map((item:any)=>
    item.AirlineCode ===event.source.value ? {...item,checked:!item.checked}:item);
    this.getAirfilterOB=changedAirfilter
    
    //get checked air    
    //const airchecked=["UK","AI"]
    const airchecked =this.getAirfilterOB.filter((item:any)=>item.checked)
    .map((item:any)=>item.AirlineCode);
    debugger
    //checked air select    
    if(airchecked.length>0){            
      //if checked single air select
      if(stopchecked.length==0 && depchecked.length==0 && arrchecked.length==0){
        this.onewayResultOB=this.onewayResultOBFilterArray.filter((item:any)=>
        airchecked.includes(item.AirlineCode));        
      }
      else if(stopchecked.length>0 && depchecked.length>0 && arrchecked.length>0){        
        const ifcheckedStop=this.onewayResultOBFilterArray.filter((item:any)=>
        stopchecked.includes(item.StopNo));          
        const ifcheckedDep=ifcheckedStop.filter((item:any)=>
        depcheckeds.includes(item.DepTime));
        const ifcheckedArr=ifcheckedDep.filter((item:any)=>
        arrcheckeds.includes(item.ArrTime));        
        this.onewayResultOB=ifcheckedArr.filter((item:any)=>
        airchecked.includes(item.AirlineCode));        
      }
      else if(stopchecked.length>0 && depchecked.length>0){        
          const ifcheckedStop=this.onewayResultOBFilterArray.filter((item:any)=>
          stopchecked.includes(item.StopNo));          
          const ifcheckedDep=ifcheckedStop.filter((item:any)=>
          depcheckeds.includes(item.DepTime));          
          this.onewayResultOB=ifcheckedDep.filter((item:any)=>
          airchecked.includes(item.AirlineCode));          
      }
      else if(depchecked.length>0 && arrchecked.length>0){        
          const ifcheckedDep=this.onewayResultOBFilterArray.filter((item:any)=>
          depcheckeds.includes(item.DepTime));          
          const ifcheckedArr=ifcheckedDep.filter((item:any)=>
          arrcheckeds.includes(item.ArrTime));          
          this.onewayResultOB=ifcheckedArr.filter((item:any)=>
          airchecked.includes(item.AirlineCode));
      }
      else if(stopchecked.length>0 && arrchecked.length>0){
          const ifcheckedStop=this.onewayResultOBFilterArray.filter((item:any)=>
          stopchecked.includes(item.StopNo));          
          const ifcheckedArr=ifcheckedStop.filter((item:any)=>
          arrcheckeds.includes(item.ArrTime));          
          this.onewayResultOB=ifcheckedArr.filter((item:any)=>
          airchecked.includes(item.AirlineCode));
      }
      else if(stopchecked.length>0){        
        const ifcheckedStop=this.onewayResultOBFilterArray.filter((item:any)=>
        stopchecked.includes(item.StopNo));
        this.onewayResultOB=ifcheckedStop.filter((item:any)=>
        airchecked.includes(item.AirlineCode))
      }
      else if(depchecked.length>0){               
          const ifcheckedDep=this.onewayResultOBFilterArray.filter((item:any)=>
          depcheckeds.includes(item.DepTime));
          this.onewayResultOB=ifcheckedDep.filter((item:any)=>
          airchecked.includes(item.AirlineCode));        
      }
      else if(arrchecked.length>0){     
          const ifcheckedArr=this.onewayResultOBFilterArray.filter((item:any)=>
          arrcheckeds.includes(item.DepTime));
          this.onewayResultOB=ifcheckedArr.filter((item:any)=>
          airchecked.includes(item.AirlineCode));
      }    
  
    }
    else if(stopchecked.length>0){      
      //if checked single air select
      if(depchecked.length==0 && arrchecked.length==0 && airchecked.length==0){        
          this.onewayResultOB=this.onewayResultOBFilterArray.filter((item:any)=>
          stopchecked.includes(item.StopNo));        
      }
      else if(depchecked.length>0 && arrchecked.length>0 && airchecked.length>0){        
        const ifcheckedDep=this.onewayResultOBFilterArray.filter((item:any)=>
        depcheckeds.includes(item.DepTime));          
        const ifcheckedArr=ifcheckedDep.filter((item:any)=>
        arrcheckeds.includes(item.ArrTime));
        const ifcheckedAir=ifcheckedArr.filter((item:any)=>
        airchecked.includes(item.AirlineCode));        
        this.onewayResultOB=ifcheckedAir.filter((item:any)=>
        stopchecked.includes(item.StopNo));        
      }
      else if(depchecked.length>0 && arrchecked.length>0){      
          const ifcheckedDep=this.onewayResultOBFilterArray.filter((item:any)=>
          depcheckeds.includes(item.DepTime));          
          const ifcheckedArr=ifcheckedDep.filter((item:any)=>
          arrcheckeds.includes(item.ArrTime));          
          this.onewayResultOB=ifcheckedArr.filter((item:any)=>
          stopchecked.includes(item.StopNo));          
      }
      else if(arrchecked.length>0 && airchecked.length>0){        
          const ifcheckedArr=this.onewayResultOBFilterArray.filter((item:any)=>
          arrcheckeds.includes(item.ArrTime));          
          const ifcheckedAir=ifcheckedArr.filter((item:any)=>
          airchecked.includes(item.AirlineCode));          
          this.onewayResultOB=ifcheckedAir.filter((item:any)=>
          stopchecked.includes(item.StopNo));
      }
      else if(depchecked.length>0 && airchecked.length>0){
          const ifcheckedDep=this.onewayResultOBFilterArray.filter((item:any)=>
          depcheckeds.includes(item.DepTime));          
          const ifcheckedAir=ifcheckedDep.filter((item:any)=>
          airchecked.includes(item.AirlineCode));          
          this.onewayResultOB=ifcheckedAir.filter((item:any)=>
          stopchecked.includes(item.StopNo));
      }      
      else if(depchecked.length>0){       
          const ifcheckedDep=this.onewayResultOBFilterArray.filter((item:any)=>
          depcheckeds.includes(item.DepTime));          
          this.onewayResultOB=ifcheckedDep.filter((item:any)=>
          stopchecked.includes(item.StopNo));        
      }
      else if(arrchecked.length>0){ 
          const ifcheckedArr=this.onewayResultOBFilterArray.filter((item:any)=>
          arrcheckeds.includes(item.ArrTime));          
          this.onewayResultOB=ifcheckedArr.filter((item:any)=>
          stopchecked.includes(item.StopNo));
      }
      else if(airchecked.length>0){
          const ifcheckedAir=this.onewayResultOBFilterArray.filter((item:any)=>
          airchecked.includes(item.AirlineCode));          
          this.onewayResultOB=ifcheckedAir.filter((item:any)=>
          stopchecked.includes(item.StopNo));
      }   
  
    }
    else if(depchecked.length>0){      
      //if checked single air select
      if(stopchecked.length==0 && arrchecked.length==0 && airchecked.length==0){        
          this.onewayResultOB=this.onewayResultOBFilterArray.filter((item:any)=>
          depcheckeds.includes(item.DepTime));        
      }
      else if(stopchecked.length>0 && arrchecked.length>0 && airchecked.length>0){        
          const ifcheckedStop=this.onewayResultOBFilterArray.filter((item:any)=>
          stopchecked.includes(item.StopNo));          
          const ifcheckedArr=ifcheckedStop.filter((item:any)=>
          arrcheckeds.includes(item.ArrTime));
          const ifcheckedAir=ifcheckedArr.filter((item:any)=>
          airchecked.includes(item.AirlineCode));        
          this.onewayResultOB=ifcheckedAir.filter((item:any)=>
          depcheckeds.includes(item.DepTime));        
      }
      else if(stopchecked.length>0 && arrchecked.length>0){      
          const ifcheckedStop=this.onewayResultOBFilterArray.filter((item:any)=>
          stopchecked.includes(item.StopNo));          
          const ifcheckedArr=ifcheckedStop.filter((item:any)=>
          arrcheckeds.includes(item.ArrTime));          
          this.onewayResultOB=ifcheckedArr.filter((item:any)=>
          depcheckeds.includes(item.DepTime));          
      }
      else if(stopchecked.length>0 && airchecked.length>0){        
          const ifcheckedStop=this.onewayResultOBFilterArray.filter((item:any)=>
          stopchecked.includes(item.StopNo));          
          const ifcheckedAir=ifcheckedStop.filter((item:any)=>
          airchecked.includes(item.ArrTime));          
          this.onewayResultOB=ifcheckedAir.filter((item:any)=>
          depcheckeds.includes(item.DepTime));
      }
      else if(arrchecked.length>0 && airchecked.length>0){
          const ifcheckedArr=this.onewayResultOBFilterArray.filter((item:any)=>
          arrcheckeds.includes(item.ArrTime));          
          const ifcheckedAir=ifcheckedArr.filter((item:any)=>
          airchecked.includes(item.AirlineCode));          
          this.onewayResultOB=ifcheckedAir.filter((item:any)=>
          depcheckeds.includes(item.DepTime));
      }      
      else if(stopchecked.length>0){       
          const ifcheckedStop=this.onewayResultOBFilterArray.filter((item:any)=>
          stopchecked.includes(item.StopNo));          
          this.onewayResultOB=ifcheckedStop.filter((item:any)=>
          depcheckeds.includes(item.DepTime));        
      }
      else if(arrchecked.length>0){ 
          const ifcheckedArr=this.onewayResultOBFilterArray.filter((item:any)=>
          arrcheckeds.includes(item.ArrTime));          
          this.onewayResultOB=ifcheckedArr.filter((item:any)=>
          depcheckeds.includes(item.DepTime));
      }
      else if(airchecked.length>0){
          const ifcheckedAir=this.onewayResultOBFilterArray.filter((item:any)=>
          airchecked.includes(item.AirlineCode));          
          this.onewayResultOB=ifcheckedAir.filter((item:any)=>
          depcheckeds.includes(item.DepTime));
      }   
  
    }
    else if(arrchecked.length>0){      
      //if checked single air select
      if(stopchecked.length==0 && depchecked.length==0 && airchecked.length==0){        
          this.onewayResultOB=this.onewayResultOBFilterArray.filter((item:any)=>
          arrcheckeds.includes(item.ArrTime));        
      }
      else if(stopchecked.length>0 && depchecked.length>0 && airchecked.length>0){       
          const ifcheckedStop=this.onewayResultOBFilterArray.filter((item:any)=>
          stopchecked.includes(item.StopNo));          
          const ifcheckedDep=ifcheckedStop.filter((item:any)=>
          depcheckeds.includes(item.DepTime));
          const ifcheckedAir=ifcheckedDep.filter((item:any)=>
          airchecked.includes(item.AirlineCode));        
          this.onewayResultOB=ifcheckedAir.filter((item:any)=>
          arrcheckeds.includes(item.ArrTime));        
      }
      else if(stopchecked.length>0 && depchecked.length>0){      
          const ifcheckedStop=this.onewayResultOBFilterArray.filter((item:any)=>
          stopchecked.includes(item.StopNo));          
          const ifcheckedDep=ifcheckedStop.filter((item:any)=>
          depcheckeds.includes(item.DepTime));          
          this.onewayResultOB=ifcheckedDep.filter((item:any)=>
          arrcheckeds.includes(item.ArrTime));          
      }
      else if(stopchecked.length>0 && airchecked.length>0){                
          const ifcheckedStop=this.onewayResultOBFilterArray.filter((item:any)=>
          stopchecked.includes(item.StopNo));          
          const ifcheckedAir=ifcheckedStop.filter((item:any)=>
          airchecked.includes(item.AirlineCode));          
          this.onewayResultOB=ifcheckedAir.filter((item:any)=>
          arrcheckeds.includes(item.ArrTime));          
      }
      else if(depchecked.length>0 && airchecked.length>0){
          const ifcheckedDep=this.onewayResultOBFilterArray.filter((item:any)=>
          depcheckeds.includes(item.DepTime));          
          const ifcheckedAir=ifcheckedDep.filter((item:any)=>
          airchecked.includes(item.AirlineCode));          
          this.onewayResultOB=ifcheckedAir.filter((item:any)=>
          arrcheckeds.includes(item.ArrTime));
      }      
      else if(stopchecked.length>0){       
          const ifcheckedStop=this.onewayResultOBFilterArray.filter((item:any)=>
          stopchecked.includes(item.StopNo));          
          this.onewayResultOB=ifcheckedStop.filter((item:any)=>
          arrcheckeds.includes(item.ArrTime));        
      }
      else if(depchecked.length>0){ 
          const ifcheckedDep=this.onewayResultOBFilterArray.filter((item:any)=>
          depcheckeds.includes(item.DepTime));          
          this.onewayResultOB=ifcheckedDep.filter((item:any)=>
          arrcheckeds.includes(item.ArrTime));
      }
      else if(airchecked.length>0){
          const ifcheckedAir=this.onewayResultOBFilterArray.filter((item:any)=>
          airchecked.includes(item.AirlineCode));          
          this.onewayResultOB=ifcheckedAir.filter((item:any)=>
          arrcheckeds.includes(item.ArrTime));
      }   
  
    }      
   }
   applyAirFilterGOB(event:any){
    //get changed Stop    
    const getStopfilters= this.stopArrayFilterOB
    const changedStopfilter=getStopfilters.map((item:any)=>
    item.StopNo ===Number(event.source.value) ? {...item,checked:!item.checked}:item);
    this.stopArrayFilterOB=changedStopfilter
    
    //get checked Stop    
    //const stopchecked=["1","2"]
    const stopchecked =this.stopArrayFilterOB.filter((item:any)=>item.checked)
    .map((item:any)=>item.StopNo);

    //get changed Dep    
    const getDepfilters= this.depArrayFilterOB
    const changedDepfilter=getDepfilters.map((item:any)=>
    item.DepTime ===event.source.value ? {...item,checked:!item.checked}:item);
    this.depArrayFilterOB=changedDepfilter
    
    //get checked Dep    
    //const depchecked=["00:06"]
    const depchecked =this.depArrayFilterOB.filter((item:any)=>item.checked)
    .map((item:any)=>item.DepTime);
    const aa=depchecked.toString()
    const ab=aa.split(",");
    const depcheckeds=ab.map((s:any) => s.slice(1));
    
    //get changed Arr    
    const getArrfilters= this.arrArrayFilterOB
    const changedArrfilter=getArrfilters.map((item:any)=>
    item.ArrTime ===event.source.value ? {...item,checked:!item.checked}:item);
    this.arrArrayFilterOB=changedArrfilter

    //get checked Arr    
    //const arrchecked=["00:06"]
    const arrchecked =this.arrArrayFilterOB.filter((item:any)=>item.checked)
    .map((item:any)=>item.ArrTime);
    const bb=arrchecked.toString()
    const ba=bb.split(",");
    const arrcheckeds=ba.map((s:any) => s.slice(1));
    
    //get changed Airchecked    
    const getAirfilters= this.getAirfilterOB
    const changedAirfilter=getAirfilters.map((item:any)=>
    item.AirlineCode ===event.source.value ? {...item,checked:!item.checked}:item);
    this.getAirfilterOB=changedAirfilter
    
    //get checked air    
    //const airchecked=["UK","AI"]
    const airchecked =this.getAirfilterOB.filter((item:any)=>item.checked)
    .map((item:any)=>item.AirlineCode);
    debugger
    //checked air select    
    if(this.applyAirFilterGIBVal==0){
      debugger
      if(airchecked.length>0){            
        //if checked single air select
        if(stopchecked.length==0 && depchecked.length==0 && arrchecked.length==0){
          this.onewayResultOB=this.onewayResultOBFilterArray.filter((item:any)=>
          airchecked.includes(item.AirlineCode));
          this.applyAirFilterGOBVal=this.onewayResultOB     
        }
        else if(stopchecked.length>0 && depchecked.length>0 && arrchecked.length>0){        
          const ifcheckedStop=this.onewayResultOBFilterArray.filter((item:any)=>
          stopchecked.includes(item.StopNo));          
          const ifcheckedDep=ifcheckedStop.filter((item:any)=>
          depcheckeds.includes(item.DepTime));
          const ifcheckedArr=ifcheckedDep.filter((item:any)=>
          arrcheckeds.includes(item.ArrTime));        
          this.onewayResultOB=ifcheckedArr.filter((item:any)=>
          airchecked.includes(item.AirlineCode));
          this.applyAirFilterGOBVal=this.onewayResultOB        
        }
        else if(stopchecked.length>0 && depchecked.length>0){        
            const ifcheckedStop=this.onewayResultOBFilterArray.filter((item:any)=>
            stopchecked.includes(item.StopNo));          
            const ifcheckedDep=ifcheckedStop.filter((item:any)=>
            depcheckeds.includes(item.DepTime));          
            this.onewayResultOB=ifcheckedDep.filter((item:any)=>
            airchecked.includes(item.AirlineCode));
            this.applyAirFilterGOBVal=this.onewayResultOB          
        }
        else if(depchecked.length>0 && arrchecked.length>0){        
            const ifcheckedDep=this.onewayResultOBFilterArray.filter((item:any)=>
            depcheckeds.includes(item.DepTime));          
            const ifcheckedArr=ifcheckedDep.filter((item:any)=>
            arrcheckeds.includes(item.ArrTime));          
            this.onewayResultOB=ifcheckedArr.filter((item:any)=>
            airchecked.includes(item.AirlineCode));
            this.applyAirFilterGOBVal=this.onewayResultOB
        }
        else if(stopchecked.length>0 && arrchecked.length>0){
            const ifcheckedStop=this.onewayResultOBFilterArray.filter((item:any)=>
            stopchecked.includes(item.StopNo));          
            const ifcheckedArr=ifcheckedStop.filter((item:any)=>
            arrcheckeds.includes(item.ArrTime));          
            this.onewayResultOB=ifcheckedArr.filter((item:any)=>
            airchecked.includes(item.AirlineCode));
            this.applyAirFilterGOBVal=this.onewayResultOB
        }
        else if(stopchecked.length>0){        
          const ifcheckedStop=this.onewayResultOBFilterArray.filter((item:any)=>
          stopchecked.includes(item.StopNo));
          this.onewayResultOB=ifcheckedStop.filter((item:any)=>
          airchecked.includes(item.AirlineCode));
          this.applyAirFilterGOBVal=this.onewayResultOB
        }
        else if(depchecked.length>0){               
            const ifcheckedDep=this.onewayResultOBFilterArray.filter((item:any)=>
            depcheckeds.includes(item.DepTime));
            this.onewayResultOB=ifcheckedDep.filter((item:any)=>
            airchecked.includes(item.AirlineCode));
            this.applyAirFilterGOBVal=this.onewayResultOB        
        }
        else if(arrchecked.length>0){     
            const ifcheckedArr=this.onewayResultOBFilterArray.filter((item:any)=>
            arrcheckeds.includes(item.DepTime));
            this.onewayResultOB=ifcheckedArr.filter((item:any)=>
            airchecked.includes(item.AirlineCode));
            this.applyAirFilterGOBVal=this.onewayResultOB
        }    
    
      }
      else if(stopchecked.length>0){      
        //if checked single air select
        if(depchecked.length==0 && arrchecked.length==0 && airchecked.length==0){        
            this.onewayResultOB=this.onewayResultOBFilterArray.filter((item:any)=>
            stopchecked.includes(item.StopNo)); 
            this.applyAirFilterGOBVal=this.onewayResultOB       
        }
        else if(depchecked.length>0 && arrchecked.length>0 && airchecked.length>0){        
          const ifcheckedDep=this.onewayResultOBFilterArray.filter((item:any)=>
          depcheckeds.includes(item.DepTime));          
          const ifcheckedArr=ifcheckedDep.filter((item:any)=>
          arrcheckeds.includes(item.ArrTime));
          const ifcheckedAir=ifcheckedArr.filter((item:any)=>
          airchecked.includes(item.AirlineCode));        
          this.onewayResultOB=ifcheckedAir.filter((item:any)=>
          stopchecked.includes(item.StopNo));
          this.applyAirFilterGOBVal=this.onewayResultOB        
        }
        else if(depchecked.length>0 && arrchecked.length>0){      
            const ifcheckedDep=this.onewayResultOBFilterArray.filter((item:any)=>
            depcheckeds.includes(item.DepTime));          
            const ifcheckedArr=ifcheckedDep.filter((item:any)=>
            arrcheckeds.includes(item.ArrTime));          
            this.onewayResultOB=ifcheckedArr.filter((item:any)=>
            stopchecked.includes(item.StopNo)); 
            this.applyAirFilterGOBVal=this.onewayResultOB         
        }
        else if(arrchecked.length>0 && airchecked.length>0){        
            const ifcheckedArr=this.onewayResultOBFilterArray.filter((item:any)=>
            arrcheckeds.includes(item.ArrTime));          
            const ifcheckedAir=ifcheckedArr.filter((item:any)=>
            airchecked.includes(item.AirlineCode));          
            this.onewayResultOB=ifcheckedAir.filter((item:any)=>
            stopchecked.includes(item.StopNo));
            this.applyAirFilterGOBVal=this.onewayResultOB
        }
        else if(depchecked.length>0 && airchecked.length>0){
            const ifcheckedDep=this.onewayResultOBFilterArray.filter((item:any)=>
            depcheckeds.includes(item.DepTime));          
            const ifcheckedAir=ifcheckedDep.filter((item:any)=>
            airchecked.includes(item.AirlineCode));          
            this.onewayResultOB=ifcheckedAir.filter((item:any)=>
            stopchecked.includes(item.StopNo));
            this.applyAirFilterGOBVal=this.onewayResultOB
        }      
        else if(depchecked.length>0){       
            const ifcheckedDep=this.onewayResultOBFilterArray.filter((item:any)=>
            depcheckeds.includes(item.DepTime));          
            this.onewayResultOB=ifcheckedDep.filter((item:any)=>
            stopchecked.includes(item.StopNo)); 
            this.applyAirFilterGOBVal=this.onewayResultOB       
        }
        else if(arrchecked.length>0){ 
            const ifcheckedArr=this.onewayResultOBFilterArray.filter((item:any)=>
            arrcheckeds.includes(item.ArrTime));          
            this.onewayResultOB=ifcheckedArr.filter((item:any)=>
            stopchecked.includes(item.StopNo));
            this.applyAirFilterGOBVal=this.onewayResultOB
        }
        else if(airchecked.length>0){
            const ifcheckedAir=this.onewayResultOBFilterArray.filter((item:any)=>
            airchecked.includes(item.AirlineCode));          
            this.onewayResultOB=ifcheckedAir.filter((item:any)=>
            stopchecked.includes(item.StopNo));
            this.applyAirFilterGOBVal=this.onewayResultOB
        }   
    
      }
      else if(depchecked.length>0){      
        //if checked single air select
        if(stopchecked.length==0 && arrchecked.length==0 && airchecked.length==0){        
            this.onewayResultOB=this.onewayResultOBFilterArray.filter((item:any)=>
            depcheckeds.includes(item.DepTime));
            this.applyAirFilterGOBVal=this.onewayResultOB        
        }
        else if(stopchecked.length>0 && arrchecked.length>0 && airchecked.length>0){        
            const ifcheckedStop=this.onewayResultOBFilterArray.filter((item:any)=>
            stopchecked.includes(item.StopNo));          
            const ifcheckedArr=ifcheckedStop.filter((item:any)=>
            arrcheckeds.includes(item.ArrTime));
            const ifcheckedAir=ifcheckedArr.filter((item:any)=>
            airchecked.includes(item.AirlineCode));        
            this.onewayResultOB=ifcheckedAir.filter((item:any)=>
            depcheckeds.includes(item.DepTime));
            this.applyAirFilterGOBVal=this.onewayResultOB        
        }
        else if(stopchecked.length>0 && arrchecked.length>0){      
            const ifcheckedStop=this.onewayResultOBFilterArray.filter((item:any)=>
            stopchecked.includes(item.StopNo));          
            const ifcheckedArr=ifcheckedStop.filter((item:any)=>
            arrcheckeds.includes(item.ArrTime));          
            this.onewayResultOB=ifcheckedArr.filter((item:any)=>
            depcheckeds.includes(item.DepTime)); 
            this.applyAirFilterGOBVal=this.onewayResultOB         
        }
        else if(stopchecked.length>0 && airchecked.length>0){        
            const ifcheckedStop=this.onewayResultOBFilterArray.filter((item:any)=>
            stopchecked.includes(item.StopNo));          
            const ifcheckedAir=ifcheckedStop.filter((item:any)=>
            airchecked.includes(item.ArrTime));          
            this.onewayResultOB=ifcheckedAir.filter((item:any)=>
            depcheckeds.includes(item.DepTime));
            this.applyAirFilterGOBVal=this.onewayResultOB
        }
        else if(arrchecked.length>0 && airchecked.length>0){
            const ifcheckedArr=this.onewayResultOBFilterArray.filter((item:any)=>
            arrcheckeds.includes(item.ArrTime));          
            const ifcheckedAir=ifcheckedArr.filter((item:any)=>
            airchecked.includes(item.AirlineCode));          
            this.onewayResultOB=ifcheckedAir.filter((item:any)=>
            depcheckeds.includes(item.DepTime));
            this.applyAirFilterGOBVal=this.onewayResultOB
        }      
        else if(stopchecked.length>0){       
            const ifcheckedStop=this.onewayResultOBFilterArray.filter((item:any)=>
            stopchecked.includes(item.StopNo));          
            this.onewayResultOB=ifcheckedStop.filter((item:any)=>
            depcheckeds.includes(item.DepTime));
            this.applyAirFilterGOBVal=this.onewayResultOB        
        }
        else if(arrchecked.length>0){ 
            const ifcheckedArr=this.onewayResultOBFilterArray.filter((item:any)=>
            arrcheckeds.includes(item.ArrTime));          
            this.onewayResultOB=ifcheckedArr.filter((item:any)=>
            depcheckeds.includes(item.DepTime));
            this.applyAirFilterGOBVal=this.onewayResultOB
        }
        else if(airchecked.length>0){
            const ifcheckedAir=this.onewayResultOBFilterArray.filter((item:any)=>
            airchecked.includes(item.AirlineCode));          
            this.onewayResultOB=ifcheckedAir.filter((item:any)=>
            depcheckeds.includes(item.DepTime));
            this.applyAirFilterGOBVal=this.onewayResultOB
        }   
    
      }
      else if(arrchecked.length>0){      
        //if checked single air select
        if(stopchecked.length==0 && depchecked.length==0 && airchecked.length==0){        
            this.onewayResultOB=this.onewayResultOBFilterArray.filter((item:any)=>
            arrcheckeds.includes(item.ArrTime)); 
            this.applyAirFilterGOBVal=this.onewayResultOB       
        }
        else if(stopchecked.length>0 && depchecked.length>0 && airchecked.length>0){       
            const ifcheckedStop=this.onewayResultOBFilterArray.filter((item:any)=>
            stopchecked.includes(item.StopNo));          
            const ifcheckedDep=ifcheckedStop.filter((item:any)=>
            depcheckeds.includes(item.DepTime));
            const ifcheckedAir=ifcheckedDep.filter((item:any)=>
            airchecked.includes(item.AirlineCode));        
            this.onewayResultOB=ifcheckedAir.filter((item:any)=>
            arrcheckeds.includes(item.ArrTime));
            this.applyAirFilterGOBVal=this.onewayResultOB        
        }
        else if(stopchecked.length>0 && depchecked.length>0){      
            const ifcheckedStop=this.onewayResultOBFilterArray.filter((item:any)=>
            stopchecked.includes(item.StopNo));          
            const ifcheckedDep=ifcheckedStop.filter((item:any)=>
            depcheckeds.includes(item.DepTime));          
            this.onewayResultOB=ifcheckedDep.filter((item:any)=>
            arrcheckeds.includes(item.ArrTime)); 
            this.applyAirFilterGOBVal=this.onewayResultOB         
        }
        else if(stopchecked.length>0 && airchecked.length>0){                
            const ifcheckedStop=this.onewayResultOBFilterArray.filter((item:any)=>
            stopchecked.includes(item.StopNo));          
            const ifcheckedAir=ifcheckedStop.filter((item:any)=>
            airchecked.includes(item.AirlineCode));          
            this.onewayResultOB=ifcheckedAir.filter((item:any)=>
            arrcheckeds.includes(item.ArrTime));
            this.applyAirFilterGOBVal=this.onewayResultOB          
        }
        else if(depchecked.length>0 && airchecked.length>0){
            const ifcheckedDep=this.onewayResultOBFilterArray.filter((item:any)=>
            depcheckeds.includes(item.DepTime));          
            const ifcheckedAir=ifcheckedDep.filter((item:any)=>
            airchecked.includes(item.AirlineCode));          
            this.onewayResultOB=ifcheckedAir.filter((item:any)=>
            arrcheckeds.includes(item.ArrTime));
            this.applyAirFilterGOBVal=this.onewayResultOB
        }      
        else if(stopchecked.length>0){       
            const ifcheckedStop=this.onewayResultOBFilterArray.filter((item:any)=>
            stopchecked.includes(item.StopNo));          
            this.onewayResultOB=ifcheckedStop.filter((item:any)=>
            arrcheckeds.includes(item.ArrTime)); 
            this.applyAirFilterGOBVal=this.onewayResultOB       
        }
        else if(depchecked.length>0){ 
            const ifcheckedDep=this.onewayResultOBFilterArray.filter((item:any)=>
            depcheckeds.includes(item.DepTime));          
            this.onewayResultOB=ifcheckedDep.filter((item:any)=>
            arrcheckeds.includes(item.ArrTime));
            this.applyAirFilterGOBVal=this.onewayResultOB
        }
        else if(airchecked.length>0){
            const ifcheckedAir=this.onewayResultOBFilterArray.filter((item:any)=>
            airchecked.includes(item.AirlineCode));          
            this.onewayResultOB=ifcheckedAir.filter((item:any)=>
            arrcheckeds.includes(item.ArrTime));
            this.applyAirFilterGOBVal=this.onewayResultOB
        }   
    
      }
    } 
    else if(this.applyAirFilterGIBVal>0){
      debugger
      if(airchecked.length>0){            
        //if checked single air select
        if(stopchecked.length==0 && depchecked.length==0 && arrchecked.length==0){
          this.onewayResultOB=this.applyAirFilterGIBVal.filter((item:any)=>
          airchecked.includes(item.AirlineCode));
          this.applyAirFilterGOBVal=this.onewayResultOB     
        }
        else if(stopchecked.length>0 && depchecked.length>0 && arrchecked.length>0){        
          const ifcheckedStop=this.applyAirFilterGIBVal.filter((item:any)=>
          stopchecked.includes(item.StopNo));          
          const ifcheckedDep=ifcheckedStop.filter((item:any)=>
          depcheckeds.includes(item.DepTime));
          const ifcheckedArr=ifcheckedDep.filter((item:any)=>
          arrcheckeds.includes(item.ArrTime));        
          this.onewayResultOB=ifcheckedArr.filter((item:any)=>
          airchecked.includes(item.AirlineCode));
          this.applyAirFilterGOBVal=this.onewayResultOB        
        }
        else if(stopchecked.length>0 && depchecked.length>0){        
            const ifcheckedStop=this.applyAirFilterGIBVal.filter((item:any)=>
            stopchecked.includes(item.StopNo));          
            const ifcheckedDep=ifcheckedStop.filter((item:any)=>
            depcheckeds.includes(item.DepTime));          
            this.onewayResultOB=ifcheckedDep.filter((item:any)=>
            airchecked.includes(item.AirlineCode));
            this.applyAirFilterGOBVal=this.onewayResultOB          
        }
        else if(depchecked.length>0 && arrchecked.length>0){        
            const ifcheckedDep=this.applyAirFilterGIBVal.filter((item:any)=>
            depcheckeds.includes(item.DepTime));          
            const ifcheckedArr=ifcheckedDep.filter((item:any)=>
            arrcheckeds.includes(item.ArrTime));          
            this.onewayResultOB=ifcheckedArr.filter((item:any)=>
            airchecked.includes(item.AirlineCode));
            this.applyAirFilterGOBVal=this.onewayResultOB
        }
        else if(stopchecked.length>0 && arrchecked.length>0){
            const ifcheckedStop=this.applyAirFilterGIBVal.filter((item:any)=>
            stopchecked.includes(item.StopNo));          
            const ifcheckedArr=ifcheckedStop.filter((item:any)=>
            arrcheckeds.includes(item.ArrTime));          
            this.onewayResultOB=ifcheckedArr.filter((item:any)=>
            airchecked.includes(item.AirlineCode));
            this.applyAirFilterGOBVal=this.onewayResultOB
        }
        else if(stopchecked.length>0){        
          const ifcheckedStop=this.applyAirFilterGIBVal.filter((item:any)=>
          stopchecked.includes(item.StopNo));
          this.onewayResultOB=ifcheckedStop.filter((item:any)=>
          airchecked.includes(item.AirlineCode));
          this.applyAirFilterGOBVal=this.onewayResultOB
        }
        else if(depchecked.length>0){               
            const ifcheckedDep=this.applyAirFilterGIBVal.filter((item:any)=>
            depcheckeds.includes(item.DepTime));
            this.onewayResultOB=ifcheckedDep.filter((item:any)=>
            airchecked.includes(item.AirlineCode));
            this.applyAirFilterGOBVal=this.onewayResultOB        
        }
        else if(arrchecked.length>0){     
            const ifcheckedArr=this.applyAirFilterGIBVal.filter((item:any)=>
            arrcheckeds.includes(item.DepTime));
            this.onewayResultOB=ifcheckedArr.filter((item:any)=>
            airchecked.includes(item.AirlineCode));
            this.applyAirFilterGOBVal=this.onewayResultOB
        }    
    
      }
      else if(stopchecked.length>0){      
        //if checked single air select
        if(depchecked.length==0 && arrchecked.length==0 && airchecked.length==0){        
            this.onewayResultOB=this.applyAirFilterGIBVal.filter((item:any)=>
            stopchecked.includes(item.StopNo)); 
            this.applyAirFilterGOBVal=this.onewayResultOB       
        }
        else if(depchecked.length>0 && arrchecked.length>0 && airchecked.length>0){        
          const ifcheckedDep=this.applyAirFilterGIBVal.filter((item:any)=>
          depcheckeds.includes(item.DepTime));          
          const ifcheckedArr=ifcheckedDep.filter((item:any)=>
          arrcheckeds.includes(item.ArrTime));
          const ifcheckedAir=ifcheckedArr.filter((item:any)=>
          airchecked.includes(item.AirlineCode));        
          this.onewayResultOB=ifcheckedAir.filter((item:any)=>
          stopchecked.includes(item.StopNo));
          this.applyAirFilterGOBVal=this.onewayResultOB        
        }
        else if(depchecked.length>0 && arrchecked.length>0){      
            const ifcheckedDep=this.applyAirFilterGIBVal.filter((item:any)=>
            depcheckeds.includes(item.DepTime));          
            const ifcheckedArr=ifcheckedDep.filter((item:any)=>
            arrcheckeds.includes(item.ArrTime));          
            this.onewayResultOB=ifcheckedArr.filter((item:any)=>
            stopchecked.includes(item.StopNo)); 
            this.applyAirFilterGOBVal=this.onewayResultOB         
        }
        else if(arrchecked.length>0 && airchecked.length>0){        
            const ifcheckedArr=this.applyAirFilterGIBVal.filter((item:any)=>
            arrcheckeds.includes(item.ArrTime));          
            const ifcheckedAir=ifcheckedArr.filter((item:any)=>
            airchecked.includes(item.AirlineCode));          
            this.onewayResultOB=ifcheckedAir.filter((item:any)=>
            stopchecked.includes(item.StopNo));
            this.applyAirFilterGOBVal=this.onewayResultOB
        }
        else if(depchecked.length>0 && airchecked.length>0){
            const ifcheckedDep=this.applyAirFilterGIBVal.filter((item:any)=>
            depcheckeds.includes(item.DepTime));          
            const ifcheckedAir=ifcheckedDep.filter((item:any)=>
            airchecked.includes(item.AirlineCode));          
            this.onewayResultOB=ifcheckedAir.filter((item:any)=>
            stopchecked.includes(item.StopNo));
            this.applyAirFilterGOBVal=this.onewayResultOB
        }      
        else if(depchecked.length>0){       
            const ifcheckedDep=this.applyAirFilterGIBVal.filter((item:any)=>
            depcheckeds.includes(item.DepTime));          
            this.onewayResultOB=ifcheckedDep.filter((item:any)=>
            stopchecked.includes(item.StopNo)); 
            this.applyAirFilterGOBVal=this.onewayResultOB       
        }
        else if(arrchecked.length>0){ 
            const ifcheckedArr=this.applyAirFilterGIBVal.filter((item:any)=>
            arrcheckeds.includes(item.ArrTime));          
            this.onewayResultOB=ifcheckedArr.filter((item:any)=>
            stopchecked.includes(item.StopNo));
            this.applyAirFilterGOBVal=this.onewayResultOB
        }
        else if(airchecked.length>0){
            const ifcheckedAir=this.applyAirFilterGIBVal.filter((item:any)=>
            airchecked.includes(item.AirlineCode));          
            this.onewayResultOB=ifcheckedAir.filter((item:any)=>
            stopchecked.includes(item.StopNo));
            this.applyAirFilterGOBVal=this.onewayResultOB
        }   
    
      }
      else if(depchecked.length>0){      
        //if checked single air select
        if(stopchecked.length==0 && arrchecked.length==0 && airchecked.length==0){        
            this.onewayResultOB=this.applyAirFilterGIBVal.filter((item:any)=>
            depcheckeds.includes(item.DepTime));
            this.applyAirFilterGOBVal=this.onewayResultOB        
        }
        else if(stopchecked.length>0 && arrchecked.length>0 && airchecked.length>0){        
            const ifcheckedStop=this.applyAirFilterGIBVal.filter((item:any)=>
            stopchecked.includes(item.StopNo));          
            const ifcheckedArr=ifcheckedStop.filter((item:any)=>
            arrcheckeds.includes(item.ArrTime));
            const ifcheckedAir=ifcheckedArr.filter((item:any)=>
            airchecked.includes(item.AirlineCode));        
            this.onewayResultOB=ifcheckedAir.filter((item:any)=>
            depcheckeds.includes(item.DepTime));
            this.applyAirFilterGOBVal=this.onewayResultOB        
        }
        else if(stopchecked.length>0 && arrchecked.length>0){      
            const ifcheckedStop=this.applyAirFilterGIBVal.filter((item:any)=>
            stopchecked.includes(item.StopNo));          
            const ifcheckedArr=ifcheckedStop.filter((item:any)=>
            arrcheckeds.includes(item.ArrTime));          
            this.onewayResultOB=ifcheckedArr.filter((item:any)=>
            depcheckeds.includes(item.DepTime)); 
            this.applyAirFilterGOBVal=this.onewayResultOB         
        }
        else if(stopchecked.length>0 && airchecked.length>0){        
            const ifcheckedStop=this.applyAirFilterGIBVal.filter((item:any)=>
            stopchecked.includes(item.StopNo));          
            const ifcheckedAir=ifcheckedStop.filter((item:any)=>
            airchecked.includes(item.ArrTime));          
            this.onewayResultOB=ifcheckedAir.filter((item:any)=>
            depcheckeds.includes(item.DepTime));
            this.applyAirFilterGOBVal=this.onewayResultOB
        }
        else if(arrchecked.length>0 && airchecked.length>0){
            const ifcheckedArr=this.applyAirFilterGIBVal.filter((item:any)=>
            arrcheckeds.includes(item.ArrTime));          
            const ifcheckedAir=ifcheckedArr.filter((item:any)=>
            airchecked.includes(item.AirlineCode));          
            this.onewayResultOB=ifcheckedAir.filter((item:any)=>
            depcheckeds.includes(item.DepTime));
            this.applyAirFilterGOBVal=this.onewayResultOB
        }      
        else if(stopchecked.length>0){       
            const ifcheckedStop=this.applyAirFilterGIBVal.filter((item:any)=>
            stopchecked.includes(item.StopNo));          
            this.onewayResultOB=ifcheckedStop.filter((item:any)=>
            depcheckeds.includes(item.DepTime));
            this.applyAirFilterGOBVal=this.onewayResultOB        
        }
        else if(arrchecked.length>0){ 
            const ifcheckedArr=this.applyAirFilterGIBVal.filter((item:any)=>
            arrcheckeds.includes(item.ArrTime));          
            this.onewayResultOB=ifcheckedArr.filter((item:any)=>
            depcheckeds.includes(item.DepTime));
            this.applyAirFilterGOBVal=this.onewayResultOB
        }
        else if(airchecked.length>0){
            const ifcheckedAir=this.applyAirFilterGIBVal.filter((item:any)=>
            airchecked.includes(item.AirlineCode));          
            this.onewayResultOB=ifcheckedAir.filter((item:any)=>
            depcheckeds.includes(item.DepTime));
            this.applyAirFilterGOBVal=this.onewayResultOB
        }   
    
      }
      else if(arrchecked.length>0){      
        //if checked single air select
        if(stopchecked.length==0 && depchecked.length==0 && airchecked.length==0){        
            this.onewayResultOB=this.applyAirFilterGIBVal.filter((item:any)=>
            arrcheckeds.includes(item.ArrTime)); 
            this.applyAirFilterGOBVal=this.onewayResultOB       
        }
        else if(stopchecked.length>0 && depchecked.length>0 && airchecked.length>0){       
            const ifcheckedStop=this.applyAirFilterGIBVal.filter((item:any)=>
            stopchecked.includes(item.StopNo));          
            const ifcheckedDep=ifcheckedStop.filter((item:any)=>
            depcheckeds.includes(item.DepTime));
            const ifcheckedAir=ifcheckedDep.filter((item:any)=>
            airchecked.includes(item.AirlineCode));        
            this.onewayResultOB=ifcheckedAir.filter((item:any)=>
            arrcheckeds.includes(item.ArrTime));
            this.applyAirFilterGOBVal=this.onewayResultOB        
        }
        else if(stopchecked.length>0 && depchecked.length>0){      
            const ifcheckedStop=this.applyAirFilterGIBVal.filter((item:any)=>
            stopchecked.includes(item.StopNo));          
            const ifcheckedDep=ifcheckedStop.filter((item:any)=>
            depcheckeds.includes(item.DepTime));          
            this.onewayResultOB=ifcheckedDep.filter((item:any)=>
            arrcheckeds.includes(item.ArrTime)); 
            this.applyAirFilterGOBVal=this.onewayResultOB         
        }
        else if(stopchecked.length>0 && airchecked.length>0){                
            const ifcheckedStop=this.applyAirFilterGIBVal.filter((item:any)=>
            stopchecked.includes(item.StopNo));          
            const ifcheckedAir=ifcheckedStop.filter((item:any)=>
            airchecked.includes(item.AirlineCode));          
            this.onewayResultOB=ifcheckedAir.filter((item:any)=>
            arrcheckeds.includes(item.ArrTime));
            this.applyAirFilterGOBVal=this.onewayResultOB          
        }
        else if(depchecked.length>0 && airchecked.length>0){
            const ifcheckedDep=this.applyAirFilterGIBVal.filter((item:any)=>
            depcheckeds.includes(item.DepTime));          
            const ifcheckedAir=ifcheckedDep.filter((item:any)=>
            airchecked.includes(item.AirlineCode));          
            this.onewayResultOB=ifcheckedAir.filter((item:any)=>
            arrcheckeds.includes(item.ArrTime));
            this.applyAirFilterGOBVal=this.onewayResultOB
        }      
        else if(stopchecked.length>0){       
            const ifcheckedStop=this.applyAirFilterGIBVal.filter((item:any)=>
            stopchecked.includes(item.StopNo));          
            this.onewayResultOB=ifcheckedStop.filter((item:any)=>
            arrcheckeds.includes(item.ArrTime)); 
            this.applyAirFilterGOBVal=this.onewayResultOB       
        }
        else if(depchecked.length>0){ 
            const ifcheckedDep=this.applyAirFilterGIBVal.filter((item:any)=>
            depcheckeds.includes(item.DepTime));          
            this.onewayResultOB=ifcheckedDep.filter((item:any)=>
            arrcheckeds.includes(item.ArrTime));
            this.applyAirFilterGOBVal=this.onewayResultOB
        }
        else if(airchecked.length>0){
            const ifcheckedAir=this.applyAirFilterGIBVal.filter((item:any)=>
            airchecked.includes(item.AirlineCode));          
            this.onewayResultOB=ifcheckedAir.filter((item:any)=>
            arrcheckeds.includes(item.ArrTime));
            this.applyAirFilterGOBVal=this.onewayResultOB
        }   
    
      }
    }      
   }
   applyAirFilterGIB(event:any){
    //get changed Dep    
    const getDepfilters= this.depArrayFilterIB
    const changedDepfilter=getDepfilters.map((item:any)=>
    item.DepTime ===event.source.value ? {...item,checked:!item.checked}:item);
    this.depArrayFilterIB=changedDepfilter
    
    //get checked Dep    
    //const depchecked=["00:06"]
    const depchecked =this.depArrayFilterIB.filter((item:any)=>item.checked)
    .map((item:any)=>item.DepTime);
    const aa=depchecked.toString()
    const ab=aa.split(",");
    const depcheckeds=ab.map((s:any) => s.slice(1));
    
    //get changed Arr    
    const getArrfilters= this.arrArrayFilterIB
    const changedArrfilter=getArrfilters.map((item:any)=>
    item.ArrTime ===event.source.value ? {...item,checked:!item.checked}:item);
    this.arrArrayFilterIB=changedArrfilter

    //get checked Arr    
    //const arrchecked=["00:06"]
    const arrchecked =this.arrArrayFilterIB.filter((item:any)=>item.checked)
    .map((item:any)=>item.ArrTime);
    const bb=arrchecked.toString()
    const ba=bb.split(",");
    const arrcheckeds=ba.map((s:any) => s.slice(1));
    debugger
    if(this.applyAirFilterGOBVal.length==0){      
      if(depchecked.length>0){      
        //if checked single air select
        if(arrchecked.length==0){                
            this.onewayResultOB=this.onewayResultOBFilterArray.filter((item:any)=>
            depcheckeds.includes(item.DepTimeG));
            this.applyAirFilterGIBVal=this.onewayResultOB;                  
        }
        else if(arrchecked.length>0){                
            const ifcheckedArr=this.onewayResultOBFilterArray.filter((item:any)=>
            arrcheckeds.includes(item.ArrTimeG));          
            this.onewayResultOB=ifcheckedArr.filter((item:any)=>
            depcheckeds.includes(item.DepTimeG));
            this.applyAirFilterGIBVal=this.onewayResultOB;                  
        }  
    
      }
      else if(arrchecked.length>0){      
        //if checked single air select
        if(depchecked.length==0){        
            this.onewayResultOB=this.onewayResultOBFilterArray.filter((item:any)=>
            arrcheckeds.includes(item.ArrTimeG)); 
            this.applyAirFilterGIBVal=this.onewayResultOB;              
        }
        else if(depchecked.length>0){                
          const ifcheckedDep=this.onewayResultOBFilterArray.filter((item:any)=>
          depcheckeds.includes(item.DepTimeG));          
          this.onewayResultOB=ifcheckedDep.filter((item:any)=>
          arrcheckeds.includes(item.ArrTimeG));
          this.applyAirFilterGIBVal=this.onewayResultOB;                  
        }  
    
      }
    }
    if(this.applyAirFilterGOBVal.length>0){
      debugger
      if(depchecked.length>0){      
        //if checked single air select
        if(arrchecked.length==0){                
            this.onewayResultOB=this.applyAirFilterGOBVal.filter((item:any)=>
            depcheckeds.includes(item.DepTimeG));
            this.applyAirFilterGIBVal=this.onewayResultOB;                  
        }
        else if(arrchecked.length>0){                
            const ifcheckedArr=this.applyAirFilterGOBVal.filter((item:any)=>
            arrcheckeds.includes(item.ArrTimeG));          
            this.onewayResultOB=ifcheckedArr.filter((item:any)=>
            depcheckeds.includes(item.DepTimeG));
            this.applyAirFilterGIBVal=this.onewayResultOB;                  
        }  
    
      }
      else if(arrchecked.length>0){      
        //if checked single air select
        if(depchecked.length==0){        
            this.onewayResultOB=this.applyAirFilterGOBVal.filter((item:any)=>
            arrcheckeds.includes(item.ArrTimeG)); 
            this.applyAirFilterGIBVal=this.onewayResultOB;              
        }
        else if(depchecked.length>0){                
          const ifcheckedDep=this.applyAirFilterGOBVal.filter((item:any)=>
          depcheckeds.includes(item.DepTimeG));          
          this.onewayResultOB=ifcheckedDep.filter((item:any)=>
          arrcheckeds.includes(item.ArrTimeG));
          this.applyAirFilterGIBVal=this.onewayResultOB;                  
        }  
    
      }
    } 
   }

//Open oneway More Fare Box
  onewaymoreBtn(divVal:string){
    this.currDiv = divVal;
    //debugger
    const moreFare = this.onewayResultOb.filter(function(el: { DepFlightNo: string; }) {
      return el.DepFlightNo == divVal;
      });
      this.onewaymoreFares = moreFare;
      //console.log(this.moreFares)
  }
  //Open oneway More Fare Box
  returnGdsMoreBtn(divVal:string,RetFlightNo:any){
    this.currDiv = divVal;
    //debugger
    const moreFare = this.onewayResultOb.filter(function(el: { DepFlightNo: string;RetFlightNo: string; }) {
      return el.DepFlightNo == divVal && el.RetFlightNo == RetFlightNo;
      });
      this.onewaymoreFares = moreFare;
      //debugger
      //console.log(this.moreFares)
  }
//Open return OB More Fare Box
returnmoreBtnOb(divVal:string){
  debugger
  this.currDiv = divVal;
  const moreFare = this.returnResultOb.filter(function(el: { FlightNo: string; }) {
    return el.FlightNo == divVal;
    });
    this.returnmoreFaresOb = moreFare;
    //console.log(this.moreFares)
}
//Open return IB More Fare Box
returnmoreBtnIb(divVal:string){
  this.currDiv = divVal;
  const moreFare = this.returnResultIb.filter(function(el: { FlightNo: string; }) {
    return el.FlightNo == divVal;
    });
    this.returnmoreFaresIb = moreFare;
    //console.log(this.moreFares)
}
 
  onewayGetItinerary(ResultIndex:any){
   // console.log(ResultIndex)
    
    // this.json.forEach(item => obj[item.id] = item.name);
    let item = this.onewayResultob.find((result: { ResultIndex: any; }) => result.ResultIndex === ResultIndex);
     //console.log(item);
      var items={
        TripType:1,
        PNR:'null',
        GDS:'TBO',
        GDSBookingId:'null',
        //Origin:this.getResultRes.Origin,
        //Destination:this.getResultRes.Destination,
        //TokenId:this.getResultRes.TraceId,
        //TraceId:this.getResultRes.TraceId,
        Itinerary:item
      }
      //console.log(items)
      this.router.navigate(['./flight/flight-review'],{      
           
       //queryParams:{json:btoa(JSON.stringify(this.json))}
       queryParams:{index:JSON.stringify(items)}
       
      })
      
   }
   returnGetItinerary(){
    //debugger
    var items={
      TripType:2,
      PNR:'null',
      GDS:'TBO',
      GDSBookingId:'null',
      Origin:this.getResultRes.Origin,
      Destination:this.getResultRes.Destination,
      TokenId:this.getResultRes.TraceId,
      TraceId:this.getResultRes.TraceId,
      ItineraryOb:this.returnFirstOb,
      ItineraryIb:this.returnFirstIb
    }
    
    //console.log(items)
    //debugger
    this.router.navigate(['./flight/return-flight-review'],{      
           
      //queryParams:{json:btoa(JSON.stringify(this.json))}
      queryParams:{index:JSON.stringify(items)}
      
     })

   }
   returnGetItineraryMoreOb(event:any){
    //this.currDiv = indexOb;
    var indexOb=event.value;
    
    //console.log(event.target.checked)
    
    const itemOb = this.returnResultOb.filter(function(el: { ResultIndex: string; }) {
      return el.ResultIndex == indexOb;
      });
      this.returnFirstOb = itemOb[0];
      debugger
    }
    returnGetItineraryMoreIb(event:any){
    
      //this.currDiv = indexIb;
      var indexIb=event.value;
      const itemIb = this.returnResultIb.filter(function(el: { ResultIndex: string; }) {
        return el.ResultIndex == indexIb;
        });
        this.returnFirstIb = itemIb[0];
        debugger
    }
   returnGetItineraryOb(event:any){
      //this.currDiv = indexOb;
      var indexOb=event.value;
      
      //console.log(event.target.checked)
      
      const itemOb = this.returnResultOb.filter(function(el: { ResultIndex: string; }) {
        return el.ResultIndex == indexOb;
        });
        this.returnFirstOb = itemOb[0];
        //this.itemObFare =this.itemOb[0].PublishedFare        
        //console.log(this.itemOb)
        const moreFare = this.returnResultOb.filter(function(el: { FlightNo: string; }) {
          return el.FlightNo == event.source.id;
          });
          debugger
          this.returnmoreFaresOb = moreFare;
    
   }
   returnGetItineraryIb(event:any){
    
    //this.currDiv = indexIb;
    var indexIb=event.value;
    const itemIb = this.returnResultIb.filter(function(el: { ResultIndex: string; }) {
      return el.ResultIndex == indexIb;
      });
      this.returnFirstIb = itemIb[0];
      //this.itemIbFare =this.itemIb[0].PublishedFare      
      //console.log(this.itemOb)  
      const moreFare = this.returnResultIb.filter(function(el: { FlightNo: string; }) {
        return el.FlightNo == event.source.id;
        });
        this.returnmoreFaresIb = moreFare;
   debugger
       }
  increasePrice(){
    this.onewayResultOB =this.onewayResultOB.sort( function(a: { PublishedFare: number; }, b: { PublishedFare: number; }){
      return a.PublishedFare - b.PublishedFare;
  });
  this.incpriceisShow = !this.incpriceisShow;
  this.decpriceisShow = true;
  }
  decreasePrice(){
    
  this.onewayResultOB =this.onewayResultOB.sort( function(a: { PublishedFare: number; }, b: { PublishedFare: number; }){
    return b.PublishedFare - a.PublishedFare;
    
});
this.decpriceisShow = !this.decpriceisShow;
this.incpriceisShow = true;
}
increaseDepart(){    
  this.onewayResultOB =this.onewayResultOB.sort( function(a: { DpTime:any; }, b: { DpTime:any; }){
    return <any>new Date(a.DpTime) - <any>new Date(b.DpTime);    
});
this.incdepartisShow = !this.incdepartisShow;
this.decdepartisShow = true;
}
decreaseDepart(){  
this.onewayResultOB =this.onewayResultOB.sort( function(a: { DpTime:any; }, b: { DpTime:any; }){
  return <any>new Date(b.DpTime) - <any>new Date(a.DpTime);  
});
this.decdepartisShow = !this.decdepartisShow;
this.incdepartisShow = true;
}
increaseArrival(){    
  this.onewayResultOB =this.onewayResultOB.sort( function(a: { ArTime:any; }, b: { ArTime:any; }){
    return <any>new Date(a.ArTime) - <any>new Date(b.ArTime);    
});
this.incarrivalisShow = !this.incarrivalisShow;
this.decarrivalisShow = true;
}
decreaseArrival(){  
this.onewayResultOB =this.onewayResultOB.sort( function(a: { ArTime:any; }, b: { ArTime:any; }){
  return <any>new Date(b.ArTime) - <any>new Date(a.ArTime);  
});
this.decarrivalisShow = !this.decarrivalisShow;
this.incarrivalisShow = true;
}

  
}
function reloadComponent() {
  throw new Error('Function not implemented.');
}

