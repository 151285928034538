import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders} from '@angular/common/http';
import {Router,ActivatedRoute} from '@angular/router';
import {environment} from '../../../../environments/environment';
import { MatTableDataSource } from '@angular/material/table';
// export interface PeriodicElement {
//   name: string;
//   position: number;
//   weight: number;
//   symbol: string;
//   grandAmount:number;
//   ammendment:string;
// }


@Component({
  selector: 'app-flight-bookings',
  templateUrl: './flight-bookings.component.html',
  styleUrls: ['./flight-bookings.component.css']
})
export class FlightBookingsComponent implements OnInit {
  url = environment.apiUrl;
  results:any ;
  bookingId:any;  
  
  //dataSource:any;
  displayedColumns: string[] = ['bookingId', 'origin', 'destination', 'bookingAmount','bookingStatus','paymentStatus','ammendment'];
  dataSource: MatTableDataSource<any>=new MatTableDataSource<any>();
  
  constructor(private httpClient:HttpClient, private router:Router) { }

  ngOnInit(): void {

    this.httpClient.get(this.url+"/flight/flight-bookings").subscribe(resp =>{
    //   this.isShow = false;
       this.results = resp;
       this.dataSource = new MatTableDataSource<any>(this.results);
    //   this.rArray = this.results.Results[0];
    //   this.result = this.results.Results[0];
    
    //  console.log(this.dataSource)

    });

    

  }

//Open booking
  openflightbooking(bookingId:any){
    
    //console.log(bookingId)
    this.router.navigate(['./flight/open-flight-booking'],{     
           
      //queryParams:{json:btoa(JSON.stringify(this.json))}
      queryParams:{id:JSON.stringify(bookingId)}
      
     })
  }

//Open booking
openflighthtmltopdf(bookingId:any){
    
  //console.log(bookingId)
  this.router.navigate(['./flight/htmltopdf'],{     
         
    //queryParams:{json:btoa(JSON.stringify(this.json))}
    queryParams:{id:JSON.stringify(bookingId)}
    
   })
}

//Delete booking
deleteflightbooking(bookingId:any){
    
    console.log(bookingId)
    
    if (confirm("If you want a delete booking!") == true) {
      this.httpClient.get(this.url+"/flight/flight-booking-delete/"+bookingId+"").subscribe(resp =>{
        //  this.isShow = false;
        let bookingsubmit = resp;     
        });
        this.ngOnInit() 
    }
  }

}
