import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../environments/environment';
import { catchError, Observable, retry } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FlightService {

  url = environment.apiUrl;

  constructor(private httpClient:HttpClient) { }

  //Get Booking By Id
//    getBookingbyId(id:any){
//      debugger
//      return this.httpClient.get(this.url+'flight/flight-booking/'+id)
//      .pipe(retry(1));
// debugger
//    }

}
