import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders} from '@angular/common/http';
import {Router,ActivatedRoute} from '@angular/router';
import {environment} from '../../environments/environment';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  url = environment.apiUrl;
  results: any;
  respn: any;
  constructor(private httpClient:HttpClient, private router:Router) { }

  ngOnInit(): void {

    this.httpClient.get(this.url+"/flight/total-default-flight-bookings").subscribe(resp =>{
      //   this.isShow = false;
      this.respn = resp;
      let remvAry = this.respn[0];
      this.results = remvAry;
      //console.log(this.results)
      });
  }

}
