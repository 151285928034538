<!-- <mat-card>
    <mat-card-content></mat-card-content>
</mat-card> -->
<section class="container-fluid">
  <mat-accordion class="mt-4 mb-2 d-block">    
    <mat-expansion-panel (opened)="panelOpenState = true"
                         (closed)="panelOpenState = false">
      <mat-expansion-panel-header class="bg-light">
        <mat-panel-title class="text-info">
          GDS-Flight Itnerary - {{results.origin}} <mat-icon>arrow_forward</mat-icon> {{results.destination}}
        </mat-panel-title>
        <mat-panel-description>
          Currently Flight Itnerary {{panelOpenState ? 'open' : 'closed'}}
        </mat-panel-description>
      </mat-expansion-panel-header>
      <div *ngFor="let seg of gdsbisegments">
        <div class="col-3 float-start text-start"><span class="d-block">{{seg.Airline.AirlineName}}</span><span class="d-block">{{seg.Airline.AirlineCode}}-{{seg.Airline.FlightNumber}}</span></div>
        <div class="col-3 float-start text-start"><span class="d-block">{{seg.Origin.DepTime.substring(11).slice(0,-3)}}</span> <span class="d-block">{{seg.Origin.Airport.CityName}}</span><span class="d-block">{{seg.Origin.DepTime.substring(0).slice(0,-9)}}</span></div>
        <div class="col-3 float-start text-center"><mat-icon>arrow_forward</mat-icon><span class="d-block">{{seg.Duration/60| number : '1.0-0'}}h:{{seg.Duration%60}}m</span></div>
        <div class="col-3 float-start text-end"><span class="d-block">{{seg.Destination.ArrTime.substring(11).slice(0,-3)}}</span> <span class="d-block">{{seg.Destination.Airport.CityName}}</span><span class="d-block">{{seg.Destination.ArrTime.substring(0).slice(0,-9)}}</span></div>
        <div class="col-12 float-start d-block mb-2">
            Baggage {{seg.Baggage}}, {{seg.CabinBaggage}}
        </div>
      </div>
    </mat-expansion-panel>
  </mat-accordion>
  <mat-accordion class="mt-4 mb-2 d-block">    
    <mat-expansion-panel (opened)="panelOpenState = true"
                         (closed)="panelOpenState = false">
      <mat-expansion-panel-header class="bg-light">
        <mat-panel-title class="text-success">
          BI-Flight Itnerary - {{results.origin}} <mat-icon>arrow_forward</mat-icon> {{results.destination}}
        </mat-panel-title>
        <mat-panel-description>
          Currently Flight Itnerary {{panelOpenState ? 'open' : 'closed'}}
        </mat-panel-description>
      </mat-expansion-panel-header>
      <div *ngFor="let seg of bisegments">
        <div *ngIf="seg.GroundTime!==0" class="col-12 bg-light float-start">{{math.trunc(seg.GroundTime/60)}}h:{{seg.GroundTime%60}}m Layover in {{seg.Origin.Airport.CityName}}</div>
        <div class="col-3 float-start text-start"><span class="d-block">{{seg.Airline.AirlineName}}</span><span class="d-block">{{seg.Airline.AirlineCode}}-{{seg.Airline.FlightNumber}}</span></div>
        <div class="col-3 float-start text-start"><span class="d-block">{{seg.Origin.DepTime.substring(11).slice(0,-3)}}</span> <span class="d-block">{{seg.Origin.Airport.CityName}}</span><span class="d-block">{{seg.Origin.DepTime.substring(0).slice(0,-9)}}</span></div>
        <div class="col-3 float-start text-center"><mat-icon>arrow_forward</mat-icon><span class="d-block">{{seg.Duration/60| number : '1.0-0'}}h:{{seg.Duration%60}}m</span></div>
        <div class="col-3 float-start text-end"><span class="d-block">{{seg.Destination.ArrTime.substring(11).slice(0,-3)}}</span> <span class="d-block">{{seg.Destination.Airport.CityName}}</span><span class="d-block">{{seg.Destination.ArrTime.substring(0).slice(0,-9)}}</span></div>
        <div class="col-12 float-start d-block mb-2">
            Baggage {{seg.Baggage}}, {{seg.CabinBaggage}}
        </div>
      </div>
      <div *ngIf="this.results.tripType==2">
        <b>Departure</b>
        <div *ngFor="let seg of bisegmentsOb">
          <div *ngIf="seg.GroundTime!==0" class="col-12 bg-light float-start">{{math.trunc(seg.GroundTime/60)}}h:{{seg.GroundTime%60}}m Layover in {{seg.Origin.Airport.CityName}}</div>
          <div class="col-3 float-start text-start"><span class="d-block">{{seg.Airline.AirlineName}}</span><span class="d-block">{{seg.Airline.AirlineCode}}-{{seg.Airline.FlightNumber}}</span></div>
          <div class="col-3 float-start text-start"><span class="d-block">{{seg.Origin.DepTime.substring(11).slice(0,-3)}}</span> <span class="d-block">{{seg.Origin.Airport.CityName}}</span><span class="d-block">{{seg.Origin.DepTime.substring(0).slice(0,-9)}}</span></div>
          <div class="col-3 float-start text-center"><mat-icon>arrow_forward</mat-icon><span class="d-block">{{seg.Duration/60| number : '1.0-0'}}h:{{seg.Duration%60}}m</span></div>
          <div class="col-3 float-start text-end"><span class="d-block">{{seg.Destination.ArrTime.substring(11).slice(0,-3)}}</span> <span class="d-block">{{seg.Destination.Airport.CityName}}</span><span class="d-block">{{seg.Destination.ArrTime.substring(0).slice(0,-9)}}</span></div>
          <div class="col-12 float-start d-block mb-2">
              Baggage {{seg.Baggage}}, {{seg.CabinBaggage}}
          </div>
        </div>
      </div>
      <div *ngIf="this.results.tripType==2">
        <b>Return</b>
        <div *ngFor="let seg of bisegmentsIb">
          <div *ngIf="seg.GroundTime!==0" class="col-12 bg-light float-start">{{math.trunc(seg.GroundTime/60)}}h:{{seg.GroundTime%60}}m Layover in {{seg.Origin.Airport.CityName}}</div>
          <div class="col-3 float-start text-start"><span class="d-block">{{seg.Airline.AirlineName}}</span><span class="d-block">{{seg.Airline.AirlineCode}}-{{seg.Airline.FlightNumber}}</span></div>
          <div class="col-3 float-start text-start"><span class="d-block">{{seg.Origin.DepTime.substring(11).slice(0,-3)}}</span> <span class="d-block">{{seg.Origin.Airport.CityName}}</span><span class="d-block">{{seg.Origin.DepTime.substring(0).slice(0,-9)}}</span></div>
          <div class="col-3 float-start text-center"><mat-icon>arrow_forward</mat-icon><span class="d-block">{{seg.Duration/60| number : '1.0-0'}}h:{{seg.Duration%60}}m</span></div>
          <div class="col-3 float-start text-end"><span class="d-block">{{seg.Destination.ArrTime.substring(11).slice(0,-3)}}</span> <span class="d-block">{{seg.Destination.Airport.CityName}}</span><span class="d-block">{{seg.Destination.ArrTime.substring(0).slice(0,-9)}}</span></div>
          <div class="col-12 float-start d-block mb-2">
              Baggage {{seg.Baggage}}, {{seg.CabinBaggage}}
          </div>
        </div>
      </div>
    </mat-expansion-panel>
  </mat-accordion>
  
  <!-- <mat-accordion class="mt-4 mb-2 d-block">    
    <mat-expansion-panel (opened)="panelOpenState = true"
                         (closed)="panelOpenState = false">
      <mat-expansion-panel-header class="bg-light">
        <mat-panel-title class="text-warning">
          MBU-Flight Itnerary - {{results.origin}} <mat-icon>arrow_forward</mat-icon> {{results.destination}}
        </mat-panel-title>
        <mat-panel-description>
          Currently Flight Itnerary {{panelOpenState ? 'open' : 'closed'}}
        </mat-panel-description>
      </mat-expansion-panel-header>
      <div *ngFor="let seg of mbubisegments">
        <div class="col-3 float-start text-start"><span class="d-block">{{seg.Airline.AirlineName}}</span><span class="d-block">{{seg.Airline.AirlineCode}}-{{seg.Airline.FlightNumber}}</span></div>
        <div class="col-3 float-start text-start"><span class="d-block">{{seg.Origin.DepTime.substring(11).slice(0,-3)}}</span> <span class="d-block">{{seg.Origin.Airport.CityName}}</span><span class="d-block">{{seg.Origin.DepTime.substring(0).slice(0,-9)}}</span></div>
        <div class="col-3 float-start text-center"><mat-icon>arrow_forward</mat-icon><span class="d-block">{{seg.Duration/60| number : '1.0-0'}}h:{{seg.Duration%60}}m</span></div>
        <div class="col-3 float-start text-end"><span class="d-block">{{seg.Destination.ArrTime.substring(11).slice(0,-3)}}</span> <span class="d-block">{{seg.Destination.Airport.CityName}}</span><span class="d-block">{{seg.Destination.ArrTime.substring(0).slice(0,-9)}}</span></div>
        <div class="col-12 float-start d-block mb-2">
            Baggage {{seg.Baggage}}, {{seg.CabinBaggage}}
        </div>
      </div>
    </mat-expansion-panel>
  </mat-accordion> -->
  
  <mat-card class="mb-2 d-block col-12 float-start">
    <div class="col-12 float-start">BreakUp <div class="float-end"> {{ this.results.bookingStatus}} <span>{{this.results.rescheduleAmount}} </span><span>{{ this.results.refundAmount}} </span>
      <form [formGroup]="changeStatusForm" (ngSubmit)="changeStatus()">
      <select formControlName="Status">
        <option value="Process">Process</option>
        <option value="Confirmed">Confirmed</option>
        <option value="Rescheduled">Rescheduled</option>
        <option value="Cancelled">Cancelled</option>
      </select>
      <input type="text" formControlName="Amount" />
      <button type="submit">Updaate Status</button>
    </form>
    </div>
  </div>
    <mat-card-content>
      <div class="col-12">
          <div class="col-4 float-start">Pax(s)</div><div class="col-4 float-start">GDS Fare(Offer)</div><div class="col-4 float-start">Fare(Public)</div>
      </div>
      <div class="col-12 bg-light float-start p-1 mt-1" *ngIf="this.results.tripType==1">
        <div class="col-4 float-start">All Base Fares</div><div class="col-4 float-start"><mat-icon class="mat-icon-rupee">currency_rupee</mat-icon>&nbsp;</div><div class="col-4 float-start"><div *ngFor="let pubfbdown of pubFbDown" class="col-12 float-start"><mat-icon class="mat-icon-rupee">currency_rupee</mat-icon>{{pubfbdown.BaseFare}}<span *ngIf="pubfbdown.PassengerType==1"> {{pubfbdown.PassengerCount}}X Adult</span><span *ngIf="pubfbdown.PassengerType==2"> {{pubfbdown.PassengerCount}}X Child</span><span *ngIf="pubfbdown.PassengerType==3"> {{pubfbdown.PassengerCount}}X Infant</span></div></div>
      </div>
      <div class="col-12 bg-light float-start p-1 mt-1" *ngIf="this.results.tripType==1">
        <div class="col-4 float-start">All Taxes</div><div class="col-4 float-start"><mat-icon class="mat-icon-rupee">currency_rupee</mat-icon>&nbsp;</div><div class="col-4 float-start"><mat-icon class="mat-icon-rupee">currency_rupee</mat-icon>{{puballtax}}</div>
      </div>
      <div class="col-12 bg-light float-start p-1 mt-1" *ngIf="this.results.tripType==2">
        <div class="col-4 float-start">All Base Fares(Dep)</div><div class="col-4 float-start"><mat-icon class="mat-icon-rupee">currency_rupee</mat-icon>&nbsp;</div><div class="col-4 float-start"><div *ngFor="let pubfbdown of retpubFbDownOb" class="col-12 float-start"><mat-icon class="mat-icon-rupee">currency_rupee</mat-icon>{{pubfbdown.BaseFare}}<span *ngIf="pubfbdown.PassengerType==1"> {{pubfbdown.PassengerCount}}X Adult</span><span *ngIf="pubfbdown.PassengerType==2"> {{pubfbdown.PassengerCount}}X Child</span><span *ngIf="pubfbdown.PassengerType==3"> {{pubfbdown.PassengerCount}}X Infant</span></div></div>
      </div>
      <div class="col-12 bg-light float-start p-1 mt-1" *ngIf="this.results.tripType==2">
        <div class="col-4 float-start">All Taxes(Dep)</div><div class="col-4 float-start"><mat-icon class="mat-icon-rupee">currency_rupee</mat-icon>&nbsp;</div><div class="col-4 float-start"><mat-icon class="mat-icon-rupee">currency_rupee</mat-icon>{{retpuballtaxOb}}</div>
      </div>
      <div class="col-12 bg-light float-start p-1 mt-1" *ngIf="this.results.tripType==2">
        <div class="col-4 float-start">All Base Fares(Ret)</div><div class="col-4 float-start"><mat-icon class="mat-icon-rupee">currency_rupee</mat-icon>&nbsp;</div><div class="col-4 float-start"><div *ngFor="let pubfbdown of retpubFbDownIb" class="col-12 float-start"><mat-icon class="mat-icon-rupee">currency_rupee</mat-icon>{{pubfbdown.BaseFare}}<span *ngIf="pubfbdown.PassengerType==1"> {{pubfbdown.PassengerCount}}X Adult</span><span *ngIf="pubfbdown.PassengerType==2"> {{pubfbdown.PassengerCount}}X Child</span><span *ngIf="pubfbdown.PassengerType==3"> {{pubfbdown.PassengerCount}}X Infant</span></div></div>
      </div>
      <div class="col-12 bg-light float-start p-1 mt-1" *ngIf="this.results.tripType==2">
        <div class="col-4 float-start">All Taxes(Ret)</div><div class="col-4 float-start"><mat-icon class="mat-icon-rupee">currency_rupee</mat-icon>&nbsp;</div><div class="col-4 float-start"><mat-icon class="mat-icon-rupee">currency_rupee</mat-icon>{{retpuballtaxIb}}</div>
      </div>
      <div class="col-12 bg-light float-start p-1 mt-1">
        <div class="col-4 float-start">Other Charges <b>{{otherCharge.ChargeType}}</b></div><div class="col-4 float-start">&nbsp;</div><div class="col-4 float-start"><mat-icon class="mat-icon-rupee">currency_rupee</mat-icon>{{otherCharge.ChargeAmount}}<mat-icon (click)="editOtherCharge()" class="float-end">edit</mat-icon></div>
      </div>
      <div class="col-12 bg-light float-start p-1 mt-1" *ngIf="otherChargeBox">
        <form [formGroup]="otherChargeForm" (ngSubmit)="otherChargeAdd()">
        <div class="col-4 float-start">
          <select formControlName="ChargeType">
              <option value="Other" selected>Other</option>
              <option value="FareIncrease">FareIncrease</option>
          </select>
        </div>
        <div class="col-4 float-start">&nbsp;</div>
        <div class="col-4 float-start"><mat-icon class="mat-icon-rupee">currency_rupee</mat-icon><input type="text" formControlName="ChargeAmount" /><button type="submit">Add Charges</button></div>
      </form>
      </div>
      <div class="col-12 bg-light float-start p-1 mt-1">
        <div class="col-4 float-start">Insurance Fee <b>{{insurance.PaxNo}}X{{insurance.InsPaxFee}}</b></div><div class="col-4 float-start">&nbsp;</div><div class="col-4 float-start"><mat-icon class="mat-icon-rupee">currency_rupee</mat-icon>{{insurance.InsFee}}</div>
      </div>
      <div class="col-12 bg-light float-start p-1 mt-1">
        <div class="col-4 float-start">Addons <b>Seat(s){{seatSsrObAmt}}, Meal(s){{mealSsrObAmt}}, Baggage(s){{baggageSsrObAmt}}</b></div><div class="col-4 float-start">&nbsp;</div><div class="col-4 float-start"><mat-icon class="mat-icon-rupee">currency_rupee</mat-icon>{{ssrAmount}}</div>
      </div>
      <div class="col-12 bg-light float-start p-1 mt-1">
        <div class="col-4 float-start">Discount <b>{{discount.PromoCode}}</b></div><div class="col-4 float-start">&nbsp;</div><div class="col-4 float-start"><mat-icon class="mat-icon-rupee">-currency_rupee</mat-icon>{{discount.GetDiscount}}<mat-icon (click)="editDiscount()" class="float-end">edit</mat-icon></div>
      </div>
      <div class="col-12 bg-light float-start p-1 mt-1" *ngIf="discountBox">
        <form [formGroup]="discountForm" (ngSubmit)="discountAdd()">
        <div class="col-4 float-start">
          <select formControlName="PromoCode">
              <option selected>Other</option>
              <option value="HUGEDISCOUNT">HUGEDISCOUNT</option>
          </select>
        </div>
        <div class="col-4 float-start">&nbsp;</div>
        <div class="col-4 float-start"><mat-icon class="mat-icon-rupee">currency_rupee</mat-icon><input type="text" formControlName="GetDiscount" /><button type="submit">Add Discount</button></div>
      </form>
      </div>
      <div class="col-12 bg-light float-start p-1 mt-1">
        <div class="col-4 float-start">Cashback <b>{{cashBack.PromoCode}}</b></div><div class="col-4 float-start">&nbsp;</div><div class="col-4 float-start"><mat-icon class="mat-icon-rupee">currency_rupee</mat-icon>{{cashBack.GetCashback}}</div>
      </div>
      <div class="col-12 bg-light float-start p-1 mt-1">
        <div class="col-4 float-start">Convenience Fee <b>{{convenience.PaxNo}}X{{convenience.ConvPaxFee}}</b></div><div class="col-4 float-start">&nbsp;</div><div class="col-4 float-start"><mat-icon class="mat-icon-rupee">currency_rupee</mat-icon>{{convenience.ConvFee}}</div>
      </div>
      <div class="col-12">
    <div class="col-4 float-start">Grand Total</div><div class="col-4 float-start"><mat-icon class="mat-icon-rupee">currency_rupee</mat-icon>{{this.offerAmount}}</div><div class="col-4 float-start"><mat-icon class="mat-icon-rupee">currency_rupee</mat-icon>{{this.bookingAmount }}</div>
      </div>
      
      <div class="col-12">
        
        <div class="col-6 float-start">
          <form [formGroup]="offerAmountForm" (ngSubmit)="offerAmountAdd()">
          <mat-form-field appearance="fill">
            <mat-label>Offer Amounts</mat-label>
            <input matInput value="0" formControlName="Amount" >
          </mat-form-field>
          <button mat-raised-button color="primary" type="submit">Offer Amount Add</button>
        </form>
        </div>
        <div class="col-6 float-start">
          <form [formGroup]="bookingAmountForm" (ngSubmit)="bookingAmountUpdate()">
          <mat-form-field appearance="fill">
            <mat-label>Booking Amounts</mat-label>
            <input matInput value="0" formControlName="Amount" >
          </mat-form-field>
          <button mat-raised-button color="primary" type="submit">Booking Amount Update</button>
        </form>
        </div>
      
      </div>
    </mat-card-content>
</mat-card>
<mat-card class="col-12 float-start mb-1">
  <b class="d-block">PNR</b>
  <form [formGroup]="pnrForm" (ngSubmit)="pnrAdd()">
  <div  class="col-4 float-start">
    <mat-form-field appearance="fill">
      <mat-label>Departure PNR</mat-label>
      <input matInput value="" placeholder="E3R54R" formControlName="obPnr" >
    </mat-form-field>
    <span class="d-block">{{pnr.obPnr}}</span>
  </div>
  <div  class="col-4 float-start">
    <mat-form-field appearance="fill">
      <mat-label>Return PNR</mat-label>
      <input matInput value="" placeholder="E3R54R" formControlName="ibPnr" >
    </mat-form-field>
    <span class="d-block">{{pnr.ibPnr}}</span>
  </div>
  <div  class="col-4 float-start">
    <button mat-raised-button color="primary" type="submit">Add PNR</button>
  </div>
</form>
</mat-card>
<mat-card class="mb-2 d-block col-12 float-start" *ngIf="this.results.tripType==1">
  <mat-panel-title class="col-12 d-block"><b>Ssr Details</b></mat-panel-title>
  <div class="col-12 font-weight" *ngFor="let seats of seatSsrOb.ObSeatArray">
    <div class="col-1 float-start">Seat(s)</div>
    <div class="col-11 float-start" *ngFor="let seat of seats">{{seat.seat}}</div>
  </div>
  <div class="col-12 font-weight" *ngFor="let meals of mealSsrOb">
    <div class="col-1 float-start">Meal(s)</div>
    <div class="col-11 float-start" *ngFor="let meal of meals">{{meal.Origin}}-{{meal.Destination}} {{meal.Quantity}}x{{meal.Price}} {{meal.AirlineDescription}}</div>
  </div>
  <div class="col-12 font-weight" *ngFor="let baggages of baggageSsrOb">
    <div class="col-1 float-start">Baggage(s)</div>
    <div class="col-11 float-start" *ngFor="let baggage of baggages">{{baggage.Origin}}-{{baggage.Destination}} {{baggage.Weight}}KG</div>
  </div>
</mat-card>
<mat-card class="mb-2 d-block col-12 float-start" *ngIf="this.results.tripType==2">
  <mat-panel-title class="col-12 d-block"><b>Ssr Details</b></mat-panel-title>
  <div class="col-12 font-weight" *ngFor="let seats of retseatSsrOb">
    <div class="col-1 float-start">Seat(s)</div>
    <div class="col-11 float-start">{{seats.seat}}</div>
  </div>
  <div class="col-12 font-weight" *ngFor="let seats of retseatSsrIb">
    <div class="col-1 float-start">Seat(s)</div>
    <div class="col-11 float-start">{{seats.seat}}</div>
  </div>
  <div class="col-12 font-weight" *ngFor="let meals of retmealSsrOb">
    <div class="col-1 float-start">Meal(s)</div>
    <div class="col-11 float-start">{{meals.Origin}}-{{meals.Destination}} {{meals.Quantity}}x{{meals.Price}} {{meals.AirlineDescription}}</div>
  </div>
  <div class="col-12 font-weight" *ngFor="let meals of retmealSsrIb">
    <div class="col-1 float-start">Meal(s)</div>
    <div class="col-11 float-start">{{meals.Origin}}-{{meals.Destination}} {{meals.Quantity}}x{{meals.Price}} {{meals.AirlineDescription}}</div>
  </div>
  <div class="col-12 font-weight" *ngFor="let baggages of retbaggageSsrOb">
    <div class="col-1 float-start">Baggage(s)</div>
    <div class="col-11 float-start">{{baggages.Origin}}-{{baggages.Destination}} {{baggages.Weight}}KG</div>
  </div>
  <div class="col-12 font-weight" *ngFor="let baggages of retbaggageSsrIb">
    <div class="col-1 float-start">Baggage(s)</div>
    <div class="col-11 float-start">{{baggages.Origin}}-{{baggages.Destination}} {{baggages.Weight}}KG</div>
  </div>
</mat-card>
<mat-card class="mb-2 d-block col-12 float-start">
  <mat-panel-title class="col-12 d-block"><b>Traveler Details</b><mat-icon class="float-end" (click)="opentravelerform()">edit</mat-icon></mat-panel-title>
  <div class="col-12 font-weight">
    <div class="col-1 float-start">No.</div><div class="col-2 float-start">Type</div><div class="col-3 float-start">Title</div><div class="col-3 float-start">First Name</div><div class="col-3 float-start">Last Name</div>
  </div>
  <mat-card-content>   
      <div class="col-12 float-start mb-3">
      <div class="float-start bg-light col-12 m-1 p-1">
        <div *ngFor="let adults of travelerDetails.adult">
          <div class="col-3 float-start">Adults</div>
          <div class="col-3 float-start">{{adults.title}}</div>
          <div class="col-3 float-start">{{adults.firstName}}</div>
          <div class="col-3 float-start">{{adults.lastName}}</div>
      </div>
      </div>
      <div class="float-start bg-light col-12 m-1 p-1">
        <div class="float-start col-12" *ngFor="let childs of travelerDetails.child">
          <div class="col-3 float-start">Childs</div>
          <div class="col-3 float-start">{{childs.title}}</div>
          <div class="col-3 float-start">{{childs.firstName}}</div>
          <div class="col-3 float-start">{{childs.lastName}}</div>
        </div>
      </div>
      <div class="float-start bg-light col-12 m-1 p-1">
        <div class="float-start col-12" *ngFor="let infants of travelerDetails.infant">
          <div class="col-3 float-start">Infants</div>
          <div class="col-3 float-start">{{infants.title}}</div>
          <div class="col-3 float-start">{{infants.firstName}}</div>
          <div class="col-3 float-start">{{infants.lastName}}</div>
        </div>
      </div>
      </div>
    <form [formGroup]="travelerForm" (ngSubmit)="travelerupdate()" *ngIf="travelerFormBox">
      <div class="col-12 float-start mb-3">
      <div class="float-start bg-light col-12 m-1 p-1" formArrayName="adult">
        <div class="float-start col-12 mt-1"  *ngFor="let adult of travelerForm.get('adult')['controls']; let i = index;" formGroupName="{{i}}">
          <div class="col-3 float-start">Adults</div>
          <div class="col-3 float-start"><input type="text" formControlName="title"/></div>
          <div class="col-3 float-start"><input type="text" formControlName="firstName"/></div>
          <div class="col-3 float-start"><input type="text" formControlName="lastName" /></div>
        </div>
      <button (click)="addAdult()">Add Adults</button>
      </div>
      <div class="float-start bg-light col-12 m-1 p-1" formArrayName="child">
        <div class="float-start col-12 mt-1"  *ngFor="let child of travelerForm.get('child')['controls']; let i = index;" formGroupName="{{i}}">
          <div class="col-3 float-start">Child</div>
          <div class="col-3 float-start"><input type="text" formControlName="title"/></div>
          <div class="col-3 float-start"><input type="text" formControlName="firstName"/></div>
          <div class="col-3 float-start"><input type="text" formControlName="lastName" /></div>
        </div>
      <button (click)="addChild()">Add Child</button>
      </div>
      <div class="float-start bg-light col-12 m-1 p-1" formArrayName="infant">
        <div class="float-start col-12 mt-1"  *ngFor="let infant of travelerForm.get('infant')['controls']; let i = index;" formGroupName="{{i}}">
          <div class="col-3 float-start">Infant</div>
          <div class="col-3 float-start"><input type="text" formControlName="title"/></div>
          <div class="col-3 float-start"><input type="text" formControlName="firstName"/></div>
          <div class="col-3 float-start"><input type="text" formControlName="lastName" /></div>
        </div>
      <button (click)="addInfant()">Add Infant</button>
      </div>
      </div>      
      <div class="col-6 float-start text-end">
        <button mat-raised-button color="primary" type="submit">Update Traveler Details</button>
    </div>
    </form> 
    
  </mat-card-content>
  <div class="col-6 float-start">&nbsp;</div>
  
</mat-card> 
</section>
  