<mat-drawer #drawer >
    <ol class="list-group list-group-flush" style="width:240px;">
      <li class="list-group-item d-flex justify-content-between align-items-start list-group-item-light">
        <div class="ms-2 me-auto">
          <div class="fw-bold">All Booking</div>
          All Submited Booking
        </div>
        <span class="badge bg-info rounded-pill">{{totalFlight?.total}}</span>
      </li>
      <li class="list-group-item d-flex justify-content-between align-items-start list-group-item-danger">
        <div class="ms-2 me-auto">
          <div class="fw-bold">Payment Faild</div>
          All not Success Payment
        </div>
        <span class="badge bg-danger rounded-pill">{{totalPaymentFailedFlight?.total}}</span>
      </li>
      <li class="list-group-item d-flex justify-content-between align-items-start list-group-item-warning">
        <div class="ms-2 me-auto">
          <div class="fw-bold">Paid Booking</div>
          All Initial Success Paid
        </div>
        <span class="badge bg-warning rounded-pill">14</span>
      </li>
      <li class="list-group-item d-flex justify-content-between align-items-start list-group-item-info">
        <div class="ms-2 me-auto">
          <div class="fw-bold">Success Booking</div>
          All Success Booking
        </div>
        <span class="badge bg-success rounded-pill">{{totalSuccessFlight?.total}}</span>
      </li>
    </ol>   
  </mat-drawer>
  <button mat-raised-button (click)="drawer.toggle()">Toggle drawer</button>