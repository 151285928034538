import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders} from '@angular/common/http';
import {FormControl, Validators, FormBuilder, FormGroup, FormArray } from '@angular/forms';
import {Router,ActivatedRoute} from '@angular/router';
import {environment} from '../../../../environments/environment';
import { FlightService } from '../../../services/flight.service';
import { AnyARecord } from 'dns';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

@Component({
  selector: 'app-htmltopdf',
  templateUrl: './htmltopdf.component.html',
  styleUrls: ['./htmltopdf.component.css']
})
export class HtmltopdfComponent implements OnInit {
  hide = true;
  panelOpenState = false;
  math = Math;
  loginForm:any = FormGroup;
  travelerForm:any=FormGroup;
  otherChargeForm:any=FormGroup;
  offerAmountForm:any=FormGroup;
  discountForm:any=FormGroup;
  bookingAmountForm:any=FormGroup;
  url = environment.apiUrl;
  id:any;
  bisegments: any;
  bisegmentsOb:any;
  bisegmentsIb:any;
  respn:any;
  results:any;
  remvAry: any;
  pnr:any;
  travelerDetails: any;
  travelersubmit:any;
  travelerFormBox:boolean=false;
  insurance: any;
  discount: any;
  cashBack: any;
  mbubisegments: any;
  gdsbisegments: any;
  convenience: any;
  pubFbDown:any;
  retpubFbDownOb:any;
  retpubFbDownIb:any;
  puballtax:any;
  puballbase:any;
  retpuballbaseOb:any;
  retpuballtaxOb:any;
  retpuballbaseIb:any;
  retpuballtaxIb:any;
  otherCharge:any;
  otherChargeBox:boolean=false;
  discountBox:boolean=false;
  afterOtherchargeAmount:any;
  bookingAmount:any;
  offerAmount:any;
  changeStatusForm:any=FormGroup;
  seatSsrObAmt:any;
  mealSsrObAmt:any;
  baggageSsrObAmt:any;
  ssrAmount:any;
  seatSsrOb:any;
  mealSsrOb:any;
  baggageSsrOb:any;
  retseatSsrOb:any;
  retseatSsrIb:any;
  retmealSsrOb:any;
  retmealSsrIb:any
  retbaggageSsrOb:any;
  retbaggageSsrIb:any;
  constructor( private httpClient:HttpClient, private router:Router, private route:ActivatedRoute, private flightService:FlightService) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe((params)=>{
      //console.log(params.json);
      //this.data= JSON.parse(atob(params.data));
      this.id= JSON.parse(params['id']);       
      console.log(this.id)
     // console.log(this.segments) 
      });
    this.httpClient.get(this.url+"/flight/flight-booking/"+this.id).subscribe(resp =>{
      //   this.isShow = false;
           this.respn = resp;           
           let remvAry = this.respn[0];           
           this.results = remvAry;
           this.pnr = JSON.parse(remvAry.pnr)
           this.seatSsrObAmt=0;
           this.mealSsrObAmt=0;
           this.baggageSsrObAmt=0;
           this.ssrAmount=0;
           debugger           
           let tDjsonObj = remvAry.travelerDetails;           
           //let itnry = bIjsonObj;
           
           //this.mbubisegments = remvAry.makeByUserBookingItnerary.Segments[0];
           debugger
           //this.gdsbisegments = remvAry.bookingItnerary.Segments[0];
           this.travelerDetails = tDjsonObj;
           this.insurance =this.results.insurance;
           this.discount =this.results.discount;
           this.cashBack =this.results.cashBack;
           this.convenience =JSON.parse(this.results.convenienceFee);
           this.otherCharge =JSON.parse(this.results.otherCharge);
           
           if(this.results.tripType==1){
            this.pubFbDown= remvAry.bookingItnerary.FareBreakdown;
            debugger
            this.bisegments = remvAry.bookingItnerary.Segments[0];
            
            debugger
           var puballbase = 0;
           this.pubFbDown.forEach((item: { BaseFare: number; }) => {
                  puballbase += item.BaseFare;
           });
           debugger
           this.puballbase= puballbase;
           var puballtax = 0;
           this.pubFbDown.forEach((item: { Tax: number; }) => {
                  puballtax += item.Tax;
           });
           this.puballtax= puballtax;
           debugger
            this.seatSsrOb =JSON.parse(this.results.seatSsr);
           this.mealSsrOb =JSON.parse(this.results.mealSsr);
           this.baggageSsrOb =JSON.parse(this.results.baggageSsr);
           
           debugger
           
           var seatSsrObAmt = 0;
           debugger
           this.seatSsrOb.ObSeatArray[0].forEach((item: { price: number; }) => {
            seatSsrObAmt += item.price;
            });
            var mealSsrObAmt = 0;
            this.mealSsrOb[0].forEach((item: { Price: number; }) => {
              mealSsrObAmt += item.Price;
              });
            var baggageSsrObAmt = 0;
            this.baggageSsrOb[0].forEach((item: { Price: number; }) => {
              baggageSsrObAmt += item.Price;
             });
             this.seatSsrObAmt=seatSsrObAmt;
             this.mealSsrObAmt=mealSsrObAmt;
             this.baggageSsrObAmt=baggageSsrObAmt;
             this.ssrAmount=seatSsrObAmt+mealSsrObAmt+baggageSsrObAmt;
            debugger
            this.bookingAmount =JSON.parse(this.puballbase+this.puballtax) + this.insurance.InsFee + JSON.parse(this.otherCharge.ChargeAmount) - JSON.parse(this.discount.GetDiscount) + this.convenience.ConvFee+this.seatSsrObAmt+this.mealSsrObAmt+this.baggageSsrObAmt;
            this.offerAmount =this.results.offerAmount
            debugger
           }
           else if(this.results.tripType==2){
            debugger
            this.bisegmentsOb = remvAry.bookingItnerary.ItineraryOb.Segments[0];
            this.bisegmentsIb = remvAry.bookingItnerary.ItineraryIb.Segments[0];
            this.retpubFbDownOb= remvAry.bookingItnerary.ItineraryOb.FareBreakdown;
            this.retpubFbDownIb= remvAry.bookingItnerary.ItineraryIb.FareBreakdown;
            debugger
            var retpuballbaseOb = 0;
            this.retpubFbDownOb.forEach((item: { BaseFare: number; }) => {
                   retpuballbaseOb += item.BaseFare;
            });
            debugger
            this.retpuballbaseOb= retpuballbaseOb;
            var retpuballtaxOb = 0;
            this.retpubFbDownOb.forEach((item: { Tax: number; }) => {
              retpuballtaxOb += item.Tax;
            });
            this.retpuballtaxOb= retpuballtaxOb;
            debugger
            var retpuballbaseIb = 0;
            this.retpubFbDownIb.forEach((item: { BaseFare: number; }) => {
                   retpuballbaseIb += item.BaseFare;
            });
            debugger
            this.retpuballbaseIb= retpuballbaseIb;
            var retpuballtaxIb = 0;
            this.retpubFbDownIb.forEach((item: { Tax: number; }) => {
              retpuballtaxIb += item.Tax;
            });
            this.retpuballtaxIb= retpuballtaxIb;

            debugger
            this.seatSsrOb =JSON.parse(this.results.seatSsr);
           this.mealSsrOb =JSON.parse(this.results.mealSsr);
           this.baggageSsrOb =JSON.parse(this.results.baggageSsr);
           debugger
           var retseatSsrObAmt = 0;
           debugger
           this.seatSsrOb.SeatOb.ObSeatArray[0].forEach((item: { price: number; }) => {
            retseatSsrObAmt += item.price;
            });
            var retseatSsrIbAmt = 0;
           debugger
           this.seatSsrOb.SeatIb.IbSeatArray[0].forEach((item: { price: number; }) => {
            retseatSsrIbAmt += item.price;
            });
            var retmealSsrObAmt = 0;
            this.mealSsrOb.MealOb[0].forEach((item: { Price: number; }) => {
              retmealSsrObAmt += item.Price;
              });
            var retmealSsrIbAmt = 0;
            this.mealSsrOb.MealIb[0].forEach((item: { Price: number; }) => {
              retmealSsrIbAmt += item.Price;
             });
             var retbaggageSsrObAmt = 0;
            this.baggageSsrOb.BaggageOb[0].forEach((item: { Price: number; }) => {
              retbaggageSsrObAmt += item.Price;
             });
             var retbaggageSsrIbAmt = 0;
            this.baggageSsrOb.BaggageIb[0].forEach((item: { Price: number; }) => {
              retbaggageSsrIbAmt += item.Price;
             });
            
             this.retseatSsrOb =this.seatSsrOb.SeatOb.ObSeatArray[0];
             this.retseatSsrIb =this.seatSsrOb.SeatIb.IbSeatArray[0];
             this.retmealSsrOb =this.mealSsrOb.MealOb[0];
             this.retmealSsrIb =this.mealSsrOb.MealIb[0];
             this.retbaggageSsrOb =this.baggageSsrOb.BaggageOb[0]
             this.retbaggageSsrIb =this.baggageSsrOb.BaggageIb[0]

            debugger
             this.seatSsrObAmt=retseatSsrObAmt+retseatSsrIbAmt;
             this.mealSsrObAmt=retmealSsrObAmt+retmealSsrIbAmt;
             this.baggageSsrObAmt=retbaggageSsrObAmt+retbaggageSsrIbAmt;
             this.ssrAmount=retseatSsrObAmt+retseatSsrIbAmt+retmealSsrObAmt+retmealSsrIbAmt+retbaggageSsrObAmt+retbaggageSsrIbAmt;
            this.bookingAmount =JSON.parse(this.retpuballbaseOb+this.retpuballbaseIb+this.retpuballtaxOb+this.retpuballtaxIb) + this.insurance.InsFee + JSON.parse(this.otherCharge.ChargeAmount) - JSON.parse(this.discount.GetDiscount) + this.convenience.ConvFee+retseatSsrObAmt+retseatSsrIbAmt+retmealSsrObAmt+retmealSsrIbAmt+retbaggageSsrObAmt+retbaggageSsrIbAmt;
            this.offerAmount =this.results.offerAmount
            debugger

           }
           debugger
          
        //  console.log(bIjsonObj)
        //  console.log(this.segments)
        //  console.log(this.travelerDetails)
    
        });
  }
  generatePdf(){
    debugger
    const elementToPrint:any=document.getElementById('htmltopdf') as HTMLElement;
    debugger
    html2canvas(elementToPrint,{allowTaint:true}).then((canvas)=>{
      const imgData=canvas.toDataURL('image/png');
      debugger
      console.log(imgData);
    });
  }
}
