     <div layout="row" layout-align="center center">
     <mat-card class="example-card mx-auto" style="width: 280px; margin: 0 auto;">
        <mat-card-header>
          <mat-card-title>Existing Member? Login</mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <form class="example-form" [formGroup]="loginForm" (ngSubmit)="handleSubmit()">
            <table class="example-full-width" cellspacing="0">
              <tr>
                <td>
                  <mat-form-field class="example-full-width">
                  <input matInput placeholder="Username"  type="text" formControlName="email" required>
                  </mat-form-field>
                </td>
              </tr>
              <tr>
              <td><mat-form-field class="example-full-width">
                <input matInput placeholder="Password" type="password" formControlName="password" required>
              </mat-form-field></td>
            </tr></table>
            
            <mat-card-actions>
                <button mat-raised-button color="primary" type="submit" (click)="handleSubmit()">Login</button>
            </mat-card-actions>
          </form>
          <!-- <mat-spinner></mat-spinner> -->
        </mat-card-content>
      </mat-card>
    </div>