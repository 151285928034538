import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders} from '@angular/common/http';
import {FormControl, Validators, FormBuilder, FormGroup, FormArray } from '@angular/forms';
import {Router,ActivatedRoute} from '@angular/router';
import {environment} from '../../../../environments/environment';
import { FlightService } from '../../../services/flight.service';
import { AnyARecord } from 'dns';

@Component({
  selector: 'app-open-flight-booking',
  templateUrl: './open-flight-booking.component.html',
  styleUrls: ['./open-flight-booking.component.css']
})
export class OpenFlightBookingComponent implements OnInit {
  hide = true;
  panelOpenState = false;
  math = Math;
  loginForm:any = FormGroup;
  travelerForm:any=FormGroup;
  otherChargeForm:any=FormGroup;
  offerAmountForm:any=FormGroup;
  pnr:any;
  pnrForm:any=FormGroup;
  discountForm:any=FormGroup;
  bookingAmountForm:any=FormGroup;
  url = environment.apiUrl;
  id:any;
  bisegments: any;
  bisegmentsOb:any;
  bisegmentsIb:any;
  respn:any;
  results:any;
  remvAry: any;
  travelerDetails: any;
  travelersubmit:any;
  travelerFormBox:boolean=false;
  insurance: any;
  discount: any;
  cashBack: any;
  mbubisegments: any;
  gdsbisegments: any;
  convenience: any;
  pubFbDown:any;
  retpubFbDownOb:any;
  retpubFbDownIb:any;
  puballtax:any;
  puballbase:any;
  retpuballbaseOb:any;
  retpuballtaxOb:any;
  retpuballbaseIb:any;
  retpuballtaxIb:any;
  otherCharge:any;
  otherChargeBox:boolean=false;
  discountBox:boolean=false;
  afterOtherchargeAmount:any;
  bookingAmount:any;
  offerAmount:any;
  changeStatusForm:any=FormGroup;
  seatSsrObAmt:any;
  mealSsrObAmt:any;
  baggageSsrObAmt:any;
  ssrAmount:any;
  seatSsrOb:any;
  mealSsrOb:any;
  baggageSsrOb:any;
  retseatSsrOb:any;
  retseatSsrIb:any;
  retmealSsrOb:any;
  retmealSsrIb:any
  retbaggageSsrOb:any;
  retbaggageSsrIb:any;
  constructor(private formBuilder:FormBuilder, private httpClient:HttpClient, private router:Router, private route:ActivatedRoute, private flightService:FlightService) { }

  ngOnInit(): void {
    
    //Traveler formBuilder Group
    this.loginForm = this.formBuilder.group({
      email:[null,Validators.required],
      password:[null,Validators.required]
    });

    this.otherChargeForm = new FormGroup({
          ChargeType:new FormControl(''),
          ChargeAmount:new FormControl('')
    });
    this.discountForm = new FormGroup({
      PromoCode:new FormControl(''),
      GetDiscount:new FormControl('')
    });
    this.changeStatusForm = new FormGroup({
      Status:new FormControl(''),
      Amount:new FormControl('')
    });
    this.offerAmountForm = new FormGroup({
      Amount:new FormControl('')
    });
    this.pnrForm = new FormGroup({
      obPnr:new FormControl(''),
      ibPnr:new FormControl('')
    });
    this.bookingAmountForm = new FormGroup({
      Amount:new FormControl('')
    });
    this.travelerForm = new FormGroup({
      adult: new FormArray([
        new FormGroup({
          title:new FormControl(''),
          firstName:new FormControl(''),
          lastName:new FormControl('')
        })
      ]),
      child: new FormArray([
        new FormGroup({
          title:new FormControl(''),
          firstName:new FormControl(''),
          lastName:new FormControl('')
        })
      ]),
      infant: new FormArray([
        new FormGroup({
          title:new FormControl(''),
          firstName:new FormControl(''),
          lastName:new FormControl('')
        })
      ])
      // child: new FormControl(),
      // infant: new FormControl()
  });

    this.route.queryParams.subscribe((params)=>{
      //console.log(params.json);
      //this.data= JSON.parse(atob(params.data));
      this.id= JSON.parse(params['id']);       
      console.log(this.id)
     // console.log(this.segments)
     
     
      
      });
      
      //Get Booking by Id
      this.httpClient.get(this.url+"/flight/flight-booking/"+this.id).subscribe(resp =>{
      //   this.isShow = false;
           this.respn = resp;           
           let remvAry = this.respn[0];           
           this.results = remvAry;
           this.pnr = JSON.parse(remvAry.pnr)
           this.seatSsrObAmt=0;
           this.mealSsrObAmt=0;
           this.baggageSsrObAmt=0;
           this.ssrAmount=0;
           debugger           
           let tDjsonObj = remvAry.travelerDetails;           
           //let itnry = bIjsonObj;
           
           //this.mbubisegments = remvAry.makeByUserBookingItnerary.Segments[0];
           debugger
           //this.gdsbisegments = remvAry.bookingItnerary.Segments[0];
           this.travelerDetails = tDjsonObj;
           this.insurance =this.results.insurance;
           this.discount =this.results.discount;
           this.cashBack =this.results.cashBack;
           this.convenience =JSON.parse(this.results.convenienceFee);
           this.otherCharge =JSON.parse(this.results.otherCharge);
           
           if(this.results.tripType==1){
            this.pubFbDown= remvAry.bookingItnerary.FareBreakdown;
            debugger
            this.bisegments = remvAry.bookingItnerary.Segments[0];
            
            debugger
           var puballbase = 0;
           this.pubFbDown.forEach((item: { BaseFare: number; }) => {
                  puballbase += item.BaseFare;
           });
           debugger
           this.puballbase= puballbase;
           var puballtax = 0;
           this.pubFbDown.forEach((item: { Tax: number; }) => {
                  puballtax += item.Tax;
           });
           this.puballtax= puballtax;
           debugger
            this.seatSsrOb =JSON.parse(this.results.seatSsr);
           this.mealSsrOb =JSON.parse(this.results.mealSsr);
           this.baggageSsrOb =JSON.parse(this.results.baggageSsr);
           
           debugger
           
           var seatSsrObAmt = 0;
           debugger
           this.seatSsrOb.ObSeatArray[0].forEach((item: { price: number; }) => {
            seatSsrObAmt += item.price;
            });
            var mealSsrObAmt = 0;
            this.mealSsrOb[0].forEach((item: { Price: number; }) => {
              mealSsrObAmt += item.Price;
              });
            var baggageSsrObAmt = 0;
            this.baggageSsrOb[0].forEach((item: { Price: number; }) => {
              baggageSsrObAmt += item.Price;
             });
             this.seatSsrObAmt=seatSsrObAmt;
             this.mealSsrObAmt=mealSsrObAmt;
             this.baggageSsrObAmt=baggageSsrObAmt;
             this.ssrAmount=seatSsrObAmt+mealSsrObAmt+baggageSsrObAmt;
            debugger
            this.bookingAmount =JSON.parse(this.puballbase+this.puballtax) + this.insurance.InsFee + JSON.parse(this.otherCharge.ChargeAmount) - JSON.parse(this.discount.GetDiscount) + this.convenience.ConvFee+this.seatSsrObAmt+this.mealSsrObAmt+this.baggageSsrObAmt;
            this.offerAmount =this.results.offerAmount
            debugger
           }
           else if(this.results.tripType==2){
            debugger
            this.bisegmentsOb = remvAry.bookingItnerary.ItineraryOb.Segments[0];
            this.bisegmentsIb = remvAry.bookingItnerary.ItineraryIb.Segments[0];
            this.retpubFbDownOb= remvAry.bookingItnerary.ItineraryOb.FareBreakdown;
            this.retpubFbDownIb= remvAry.bookingItnerary.ItineraryIb.FareBreakdown;
            debugger
            var retpuballbaseOb = 0;
            this.retpubFbDownOb.forEach((item: { BaseFare: number; }) => {
                   retpuballbaseOb += item.BaseFare;
            });
            debugger
            this.retpuballbaseOb= retpuballbaseOb;
            var retpuballtaxOb = 0;
            this.retpubFbDownOb.forEach((item: { Tax: number; }) => {
              retpuballtaxOb += item.Tax;
            });
            this.retpuballtaxOb= retpuballtaxOb;
            debugger
            var retpuballbaseIb = 0;
            this.retpubFbDownIb.forEach((item: { BaseFare: number; }) => {
                   retpuballbaseIb += item.BaseFare;
            });
            debugger
            this.retpuballbaseIb= retpuballbaseIb;
            var retpuballtaxIb = 0;
            this.retpubFbDownIb.forEach((item: { Tax: number; }) => {
              retpuballtaxIb += item.Tax;
            });
            this.retpuballtaxIb= retpuballtaxIb;

            debugger
            this.seatSsrOb =JSON.parse(this.results.seatSsr);
           this.mealSsrOb =JSON.parse(this.results.mealSsr);
           this.baggageSsrOb =JSON.parse(this.results.baggageSsr);
           debugger
           var retseatSsrObAmt = 0;
           debugger
           this.seatSsrOb.SeatOb.ObSeatArray[0].forEach((item: { price: number; }) => {
            retseatSsrObAmt += item.price;
            });
            var retseatSsrIbAmt = 0;
           debugger
           this.seatSsrOb.SeatIb.IbSeatArray[0].forEach((item: { price: number; }) => {
            retseatSsrIbAmt += item.price;
            });
            var retmealSsrObAmt = 0;
            this.mealSsrOb.MealOb[0].forEach((item: { Price: number; }) => {
              retmealSsrObAmt += item.Price;
              });
            var retmealSsrIbAmt = 0;
            this.mealSsrOb.MealIb[0].forEach((item: { Price: number; }) => {
              retmealSsrIbAmt += item.Price;
             });
             var retbaggageSsrObAmt = 0;
            this.baggageSsrOb.BaggageOb[0].forEach((item: { Price: number; }) => {
              retbaggageSsrObAmt += item.Price;
             });
             var retbaggageSsrIbAmt = 0;
            this.baggageSsrOb.BaggageIb[0].forEach((item: { Price: number; }) => {
              retbaggageSsrIbAmt += item.Price;
             });
            
             this.retseatSsrOb =this.seatSsrOb.SeatOb.ObSeatArray[0];
             this.retseatSsrIb =this.seatSsrOb.SeatIb.IbSeatArray[0];
             this.retmealSsrOb =this.mealSsrOb.MealOb[0];
             this.retmealSsrIb =this.mealSsrOb.MealIb[0];
             this.retbaggageSsrOb =this.baggageSsrOb.BaggageOb[0]
             this.retbaggageSsrIb =this.baggageSsrOb.BaggageIb[0]

            debugger
             this.seatSsrObAmt=retseatSsrObAmt+retseatSsrIbAmt;
             this.mealSsrObAmt=retmealSsrObAmt+retmealSsrIbAmt;
             this.baggageSsrObAmt=retbaggageSsrObAmt+retbaggageSsrIbAmt;
             this.ssrAmount=retseatSsrObAmt+retseatSsrIbAmt+retmealSsrObAmt+retmealSsrIbAmt+retbaggageSsrObAmt+retbaggageSsrIbAmt;
            this.bookingAmount =JSON.parse(this.retpuballbaseOb+this.retpuballbaseIb+this.retpuballtaxOb+this.retpuballtaxIb) + this.insurance.InsFee + JSON.parse(this.otherCharge.ChargeAmount) - JSON.parse(this.discount.GetDiscount) + this.convenience.ConvFee+retseatSsrObAmt+retseatSsrIbAmt+retmealSsrObAmt+retmealSsrIbAmt+retbaggageSsrObAmt+retbaggageSsrIbAmt;
            this.offerAmount =this.results.offerAmount
            debugger

           }
           debugger
          
        //  console.log(bIjsonObj)
        //  console.log(this.segments)
        //  console.log(this.travelerDetails)
    
        });
  }
  addAdult(){
    const control = <FormArray>this.travelerForm.controls['adult'];
    if (this.travelerDetails.adult.length<=control.length){

    }
    else{
      control.push(
        new FormGroup({
          title:new FormControl(''),
          firstName:new FormControl(''),
          lastName:new FormControl('')
        })
      );
    }
  }
  addChild(){
    const control = <FormArray>this.travelerForm.controls['child'];
    if (this.travelerDetails.child.length<=control.length){

    }
    else{
      control.push(
        new FormGroup({
          title:new FormControl(''),
          firstName:new FormControl(''),
          lastName:new FormControl('')
        })
      );
    }
  }
  addInfant(){
    const control = <FormArray>this.travelerForm.controls['infant'];
    if (this.travelerDetails.infant.length<=control.length){

    }
    else{
      control.push(
        new FormGroup({
          title:new FormControl(''),
          firstName:new FormControl(''),
          lastName:new FormControl('')
        })
      );
    }
  }
  opentravelerform(){
    this.travelerFormBox=true;
  }
  travelerupdate(){
      console.log(this.travelerForm.value)
      const travelerObj ={
        id:this.id,
        trdtl:this.travelerForm.value
      }
      //debugger
      let httpheaders = new HttpHeaders({ 
        'content-type':'application/json'  
         });
      if(confirm('Are you sure you want to update traveler details?')){
        this.httpClient.post(this.url+"/flight/flight-traveler-updated",travelerObj, {headers:httpheaders}).subscribe(resp =>{
          //   this.isShow = false;
           this.travelersubmit = resp;                
       });
      }
    
  }
  editOtherCharge(){
    debugger
    this.otherChargeBox=true;
    debugger
  }
  otherChargeAdd(){
    debugger
    console.log(this.otherChargeForm.value)
      const otherChargeObj =
      {
        id:this.id,
        othC:this.otherChargeForm.value
      }
    
      debugger
      let httpheaders = new HttpHeaders({ 
        'content-type':'application/json'  
         });
      if(confirm('Are you sure you want to add other charges?')){
        this.httpClient.post(this.url+"/flight/flight-otherCharge-add",otherChargeObj, {headers:httpheaders}).subscribe(resp =>{
          //   this.isShow = false;
           this.travelersubmit = resp;                
       });
      }
      window.location.reload();
  }

  changeStatus(){
    this.changeStatusForm.value
    const statusObj ={
      id:this.id,
      stus:this.changeStatusForm.value.Status,
      amount:this.changeStatusForm.value.Amount,
    }
    let httpheaders = new HttpHeaders({ 
      'content-type':'application/json'  
    });
debugger
    if(this.changeStatusForm.value.Status=='Rescheduled'){
      debugger
        this.httpClient.post(this.url+"/flight/flight-change-status-reschedule",statusObj, {headers:httpheaders}).subscribe(resp =>{
          //   this.isShow = false;
           this.travelersubmit = resp;                
       });
      
    }
    if(this.changeStatusForm.value.Status=='Cancelled'){
     debugger
        this.httpClient.post(this.url+"/flight/flight-change-status-cancelled",statusObj, {headers:httpheaders}).subscribe(resp =>{
          //   this.isShow = false;
           this.travelersubmit = resp;                
       });
      
    }
    if(this.changeStatusForm.value.Status=='Confirmed'){
      
        this.httpClient.post(this.url+"/flight/flight-change-status-confirmed",statusObj, {headers:httpheaders}).subscribe(resp =>{
          //   this.isShow = false;
           this.travelersubmit = resp;                
       });
      
    }
    window.location.reload();
  }
  offerAmountAdd(){
    this.offerAmountForm.value
    const offerObj ={
      id:this.id,
      amount:this.offerAmountForm.value.Amount,
    }
    let httpheaders = new HttpHeaders({ 
      'content-type':'application/json'  
    });
    debugger
    this.httpClient.post(this.url+"/flight/flight-offer-amount-add",offerObj, {headers:httpheaders}).subscribe(resp =>{
      //   this.isShow = false;
       this.travelersubmit = resp;                
   });
   window.location.reload();
  }
  editDiscount(){
    this.discountBox=true;
  }
  discountAdd(){
    this.discountForm.value
    const discountObj ={
      id:this.id,
      disObj:this.discountForm.value
    }
    let httpheaders = new HttpHeaders({ 
      'content-type':'application/json'  
    });
    this.httpClient.post(this.url+"/flight/flight-discount-add",discountObj, {headers:httpheaders}).subscribe(resp =>{
      //   this.isShow = false;
       this.travelersubmit = resp;                
   });
   window.location.reload();
  }
  bookingAmountUpdate(){
    this.bookingAmountForm.value
    debugger
    const bookAmntObj ={
      id:this.id,
      amount:this.bookingAmountForm.value.Amount
    }
    debugger
    let httpheaders = new HttpHeaders({ 
      'content-type':'application/json'  
    });
    this.httpClient.post(this.url+"/flight/flight-booking-amount-update",bookAmntObj, {headers:httpheaders}).subscribe(resp =>{
      //   this.isShow = false;
       this.travelersubmit = resp;                
   });
   window.location.reload();
  }
  pnrAdd(){
    this.pnrForm.value
    const pnrObj ={
      id:this.id,
      obPnr:this.pnrForm.value.obPnr,
      ibPnr:this.pnrForm.value.ibPnr
    }
    let httpheaders = new HttpHeaders({ 
      'content-type':'application/json'  
    });
    debugger
    this.httpClient.post(this.url+"/flight/flight-pnr-update",pnrObj, {headers:httpheaders}).subscribe(resp =>{
      //   this.isShow = false;
       this.travelersubmit = resp;                
   });
   window.location.reload();
  }
}
