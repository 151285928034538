import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders} from '@angular/common/http';
import {Router,ActivatedRoute} from '@angular/router';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {environment} from '../../../../environments/environment';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
@Component({
  selector: 'app-add-packages',
  templateUrl: './add-packages.component.html',
  styleUrls: ['./add-packages.component.css']
})
export class AddPackagesComponent implements OnInit {
  url = environment.apiUrl;
  packageNameForm!: FormGroup;
  packagePoliciesForm!: FormGroup;
  packageMetaForm!: FormGroup;
  Coveritems!: FormArray;
  inclusionsitems!: FormArray;
  exclusionsitems!: FormArray;
  itineraryitems!: FormArray;
  imageitems!: FormArray;
  packageNameObj:any;
  packageDetailObj:any;
  packageInclusionsObj:any;
  packageExclusionsObj:any;
  packageItineraryObj:any;
  packageImageObj:any;
  packagePoliciesObj:any;
  packageMetaObj:any;
  constructor(private httpClient:HttpClient, private router:Router,private fbuilder:FormBuilder) { }

  ngOnInit(): void {
    this.packageNameForm = this.fbuilder.group({ 
      desName:['', [Validators.required]],
      packUrlName:['', [Validators.required]],   
    });
    // this.packageDetailForm = this.fbuilder.group({ 
    //   packName:['', [Validators.required]],
    //   packType:['', [Validators.required]],
    //   Includes:['', [Validators.required]],   
    // });
    this.packagePoliciesForm = this.fbuilder.group({ 
      Policies:['', [Validators.required]],  
    });
    this.packageMetaForm = this.fbuilder.group({ 
      title:['', [Validators.required]],
      keywords:['', [Validators.required]],
      description:['', [Validators.required]]  
    });
  }
  Includes = new FormControl('');
  includesList: string[] = ['Meal', 'Hotel', 'Flight', 'Sightseeing', 'Transport',];

 
  addNameBtn(){
    this.packageNameObj = this.packageNameForm.getRawValue();
    console.log(this.packageNameObj)   
  }
  packageDetailForm = new FormGroup({
    packName: new FormControl(),
    packType: new FormControl(),
    Includes: new FormControl(),
    priceType: new FormControl(),
    numberOfNights: new FormControl(),
    advanceRate: new FormControl(),
    startingPrice: new FormControl(),
    packageType: new FormControl(),
    availability: new FormControl(),
    verification: new FormControl(),
    hotelStarRanking: new FormControl(),
    imageTitle:new FormControl(),
    mainImage:new FormControl(),
    coverPlaces: new FormArray([])
    
  });
  addNewAdult() {    
      this.Coveritems = this.packageDetailForm.get("coverPlaces") as FormArray;
      this.Coveritems.push(this.PalcesGroup());
  }
  RemoveAdult(index:any){
    this.Coveritems = this.packageDetailForm.get("coverPlaces") as FormArray;
    this.Coveritems.removeAt(index)
  }
  get coverPlaces(){
    return this.packageDetailForm.get("coverPlaces") as FormArray;
  }
  PalcesGroup(): FormGroup {
    return new FormGroup({
     Day:new FormControl('',Validators.required),
     City:new FormControl('',Validators.required)
    });
  }
  addDetailBtn(){
    this.packageDetailObj = this.packageDetailForm.getRawValue();
    console.log(this.packageDetailObj)
  }
  packageInclusionsForm = new FormGroup({    
    inclusions: new FormArray([]),
    others: new FormControl()
  });
  addNewInclusions() {    
      this.inclusionsitems = this.packageInclusionsForm.get("inclusions") as FormArray;
      this.inclusionsitems.push(this.InclGroup());
  }
  RemoveInclusions(index:any){
    this.inclusionsitems = this.packageInclusionsForm.get("inclusions") as FormArray;
    this.inclusionsitems.removeAt(index)
  }
  get inclusions(){
    return this.packageInclusionsForm.get("inclusions") as FormArray;
  }
  InclGroup(): FormGroup {
    return new FormGroup({
     InclusionsType:new FormControl('',Validators.required),
     Inclustions:new FormControl('',Validators.required)
    });
  }
  addInclusionsBtn(){
    this.packageInclusionsObj = this.packageInclusionsForm.getRawValue();
    console.log(this.packageInclusionsObj)
  }
  packageExclusionsForm = new FormGroup({    
    exclusions: new FormArray([]),
    others: new FormControl()
  });
  addNewExclusions() {    
      this.exclusionsitems = this.packageExclusionsForm.get("exclusions") as FormArray;
      this.exclusionsitems.push(this.ExclGroup());
  }
  RemoveExclusions(index:any){
    this.exclusionsitems = this.packageExclusionsForm.get("exclusions") as FormArray;
    this.exclusionsitems.removeAt(index)
  }
  get exclusions(){
    return this.packageExclusionsForm.get("exclusions") as FormArray;
  }
  ExclGroup(): FormGroup {
    return new FormGroup({
     ExclusionsType:new FormControl('',Validators.required),
     Exclustions:new FormControl('',Validators.required)
    });
  }
  addExclusionsBtn(){
    this.packageExclusionsObj = this.packageExclusionsForm.getRawValue();
    console.log(this.packageExclusionsObj)
  }
  packageItineraryForm = new FormGroup({    
    itinerary: new FormArray([])
  });
  addNewItinerary() {    
      this.itineraryitems = this.packageItineraryForm.get("itinerary") as FormArray;
      this.itineraryitems.push(this.ItinGroup());
  }
  RemoveItinerary(index:any){
    this.itineraryitems = this.packageItineraryForm.get("itinerary") as FormArray;
    this.itineraryitems.removeAt(index)
  }
  get itinerary(){
    return this.packageItineraryForm.get("itinerary") as FormArray;
  }
  ItinGroup(): FormGroup {
    return new FormGroup({
     Day:new FormControl('',Validators.required),
     DayTitle:new FormControl('',Validators.required),
     DailyText:new FormControl('',Validators.required),
     DayIncludes:new FormControl('',Validators.required)
    });
  }
  //IncludesDay = new FormControl('');
  includesDayList: string[] = ['Meal', 'Hotel', 'Flight', 'Sightseeing', 'Transport','Houseboat'];
  addItineraryBtn(){
    this.packageItineraryObj = this.packageItineraryForm.getRawValue();
    console.log(this.packageItineraryObj)
  }
  packageImageForm = new FormGroup({    
    image: new FormArray([])
  });
  addNewImage() {    
      this.imageitems = this.packageImageForm.get("image") as FormArray;
      this.imageitems.push(this.ImageGroup());
  }
  RemoveImage(index:any){
    this.imageitems = this.packageImageForm.get("image") as FormArray;
    this.imageitems.removeAt(index)
  }
  get image(){
    return this.packageImageForm.get("image") as FormArray;
  }
  ImageGroup(): FormGroup {
    return new FormGroup({
     ImageTitle:new FormControl('',Validators.required),
     ImageUrl:new FormControl('',Validators.required)
    });
  }
  addImageBtn(){
    this.packageImageObj = this.packageImageForm.getRawValue();
    console.log(this.packageImageObj)
  }

  addPoliciesBtn(){
    this.packagePoliciesObj = this.packagePoliciesForm.getRawValue();
    console.log(this.packagePoliciesObj)   
  }
  addMetaBtn(){
    this.packageMetaObj = this.packageMetaForm.getRawValue();
    console.log(this.packageMetaObj)   
  }
  submitPackage(){
    let id =Math.floor((Math.random()*1000000)+1);
    
    let mainObj ={
      packageId:id,
      destinationName:this.packageNameObj.desName,
      packageName:this.packageNameObj.packUrlName,
      packageDetails:JSON.stringify(this.packageDetailForm.value),
      inclusions:JSON.stringify(this.packageInclusionsForm.value),
      exclusions:JSON.stringify(this.packageExclusionsForm.value),
      itinerary:JSON.stringify(this.packageItineraryForm.value),
      photos:JSON.stringify(this.packageImageForm.value),
      policies:JSON.stringify(this.packagePoliciesForm.value),
      metaTag:JSON.stringify(this.packageMetaForm.value)
    }
    console.log(mainObj)
    let httpheaders = new HttpHeaders({ 
      'content-type':'application/json'  
       });
    return this.httpClient.post(this.url+"/holiday/holiday-package-submited",mainObj, {headers:httpheaders}).subscribe(resp =>{
           //   this.isShow = false;
            let ds = resp; 
                
        });
  }
 
  
}
