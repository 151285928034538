<app-header></app-header>
<section class="container-fluid pb-5 float-start">
    <div class="col-12 col-md-2 float-start">
      &nbsp;
      
    </div>
    <div class="col-12 col-md-10 mt-4 pt-3 float-start">
        <mat-card class="col-12 mt-1 float-start">
            <b>Add Convenience Fees</b>
            <form [formGroup]="flightConFeeForm" (ngSubmit)="flightConfeeSubmit()">
                <mat-form-field appearance="fill" class="col-3 p-1 float-start">
                    <mat-label>Journey Type</mat-label>
                    <mat-select formControlName="journeyType">
                      <mat-option value="Oneway">One Way</mat-option>
                      <mat-option value="Round">Round Way</mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field appearance="fill" class="col-3 p-1 float-start">
                    <mat-label>Trip Type</mat-label>
                    <mat-select formControlName="tripType">
                      <mat-option value="Dom">Domestic</mat-option>
                      <mat-option value="Intl">International</mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field class="col-3 p-1 float-start">
                    <mat-label>Fee</mat-label>
                    <input matInput type="text" class="p-1" formControlName="fee" />
                </mat-form-field>
                <div class="col-3 float-start">
                    <button mat-flat-button color="accent" class="ps-3 pe-3 pt-1 pb-1">Submit</button>
                </div>
            </form>
        
            <div class="col-12 mt-4 pt-3 float-start">
                    <div class="col-12 float-start">
                        <div class="col-1 float-start">
                            <b>Id</b>
                        </div>
                        <div class="col-4 float-start">
                            <b>Journey Type</b>
                        </div>
                        <div class="col-3 float-start">
                            <b>Trip Type</b>
                        </div>
                        <div class="col-2 float-start">
                            <b>Fee</b>
                        </div>
                        <div class="col-2 float-start">
                            &nbsp;
                        </div>
                    </div>
                    
                    <div class="col-12 float-start" *ngFor="let feeresult of confeeResult;let i = index">
                        
                        <div class="col-1 float-start">
                            <span #id>{{feeresult.feeId}}</span>
                        </div>
                        <div class="col-3 float-start">
                            {{feeresult.journeyType}}
                        </div>
                        <div class="col-3 float-start">
                            <span *ngIf="feeresult.tripType=='Dom'">Domestic</span>
                            <span *ngIf="feeresult.tripType=='Intl'">International</span>
                        </div>
                        <div class="col-2 float-start">
                            <mat-form-field class="col-12 p-1 float-start">
                                <mat-label>Fee</mat-label>
                                <input matInput #fee [value]="feeresult.fee" type="text" class="p-1" />
                            </mat-form-field>
                        </div>
                        <div class="col-3 float-start">
                            <button mat-flat-button color="accent" (click)="updateConFee(fee.value,id)" class="ps-2 pe-2 pt-1 pb-1">Submit</button>                            
                            <!-- <mat-checkbox color="primary" (click)="checkedFee($event)" class="ms-2"></mat-checkbox> -->
                        </div>
                        
                    </div>
                
            </div>
        </mat-card>

        <mat-card class="col-12 mt-2 float-start">
            <b>Add Insurance Fees</b>
            <form [formGroup]="flightInsFeeForm" (ngSubmit)="flightInsfeeSubmit()">
                <mat-form-field appearance="fill" class="col-3 p-1 float-start">
                    <mat-label>Journey Type</mat-label>
                    <mat-select formControlName="journeyType">
                      <mat-option value="Oneway">One Way</mat-option>
                      <mat-option value="Round">Round Way</mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field appearance="fill" class="col-3 p-1 float-start">
                    <mat-label>Trip Type</mat-label>
                    <mat-select formControlName="tripType">
                      <mat-option value="Dom">Domestic</mat-option>
                      <mat-option value="Intl">International</mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field class="col-3 p-1 float-start">
                    <mat-label>Fee</mat-label>
                    <input matInput type="text" class="p-1" formControlName="fee" />
                </mat-form-field>
                <div class="col-3 float-start">
                    <button mat-flat-button color="accent" class="ps-3 pe-3 pt-1 pb-1">Submit</button>
                </div>
            </form>
        
            <div class="col-12 mt-4 pt-3 float-start">
                    <div class="col-12 float-start">
                        <div class="col-1 float-start">
                            <b>Id</b>
                        </div>
                        <div class="col-4 float-start">
                            <b>Journey Type</b>
                        </div>
                        <div class="col-3 float-start">
                            <b>Trip Type</b>
                        </div>
                        <div class="col-2 float-start">
                            <b>Fee</b>
                        </div>
                        <div class="col-2 float-start">
                            &nbsp;
                        </div>
                    </div>
                    
                    <div class="col-12 float-start" *ngFor="let feeresult of insfeeResult;let i = index">
                        
                        <div class="col-1 float-start">
                            <span #id>{{feeresult.feeId}}</span>
                        </div>
                        <div class="col-3 float-start">
                            {{feeresult.journeyType}}
                        </div>
                        <div class="col-3 float-start">
                            <span *ngIf="feeresult.tripType=='Dom'">Domestic</span>
                            <span *ngIf="feeresult.tripType=='Intl'">International</span>
                        </div>
                        <div class="col-2 float-start">
                            <mat-form-field class="col-12 p-1 float-start">
                                <mat-label>Fee</mat-label>
                                <input matInput #fee [value]="feeresult.fee" type="text" class="p-1" />
                            </mat-form-field>
                        </div>
                        <div class="col-3 float-start">
                            <button mat-flat-button color="accent" (click)="updateInsFee(fee.value,id)" class="ps-2 pe-2 pt-1 pb-1">Submit</button>                            
                            <!-- <mat-checkbox color="primary" (click)="checkedFee($event)" class="ms-2"></mat-checkbox> -->
                        </div>
                        
                    </div>
                
            </div>
        </mat-card>
        
    </div>
    
</section>
<mat-spinner *ngIf="processBar"></mat-spinner>
<app-footer></app-footer>