<section class="container-fluid pb-5 float-start">
    <div class="col-12 col-md-2 float-start">
      &nbsp;
      
    </div>
    <div class="col-12 col-md-10 mt-4 pt-3 float-start">
        <mat-card class="col-12 mt-1 float-start">
            <b>Add Discount</b>
            <form [formGroup]="flightDiscountForm" (ngSubmit)="flightDiscountSubmit()">
                <mat-form-field appearance="fill" class="col-2 p-1 float-start">
                    <mat-label>Offer Type</mat-label>
                    <mat-select formControlName="offerType">
                      <mat-option value="Discount">Discount</mat-option>
                      <mat-option value="Cashback">Cashback</mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field appearance="fill" class="col-2 p-1 float-start">
                    <mat-label>Discount Type</mat-label>
                    <mat-select formControlName="discountType">
                      <mat-option value="fixed">Fixed</mat-option>
                      <mat-option value="percentage">Percentage</mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field appearance="fill" class="col-1 p-1 float-start">
                    <mat-label>Airline</mat-label>
                    <input matInput type="text" class="p-1" formControlName="airline" />
                </mat-form-field>
                <mat-form-field appearance="fill" class="col-1 p-1 float-start">
                    <mat-label>Source</mat-label>
                    <mat-select formControlName="sources">
                      <mat-option value="Dom">Domestic</mat-option>
                      <mat-option value="Intl">International</mat-option>
                    </mat-select>
                </mat-form-field>                
                <mat-form-field class="col-1 p-1 float-start">
                    <mat-label>Discount Name</mat-label>
                    <input matInput type="text" class="p-1" formControlName="discountName" />
                </mat-form-field>
                <mat-form-field class="col-1 p-1 float-start">
                    <mat-label>Airline Class</mat-label>
                    <input matInput type="text" class="p-1" formControlName="airlineclass" />
                </mat-form-field>
                <mat-form-field class="col-1 p-1 float-start">
                    <mat-label>Discount Amount</mat-label>
                    <input matInput type="text" class="p-1" formControlName="discountAmount" />
                </mat-form-field>
                <mat-form-field class="col-1 p-1 float-start">
                    <mat-label>Next Person Discount</mat-label>
                    <input matInput type="text" class="p-1" formControlName="nextPersonDiscountAmount" />
                </mat-form-field>
                <mat-form-field class="col-1 p-1 float-start">
                    <mat-label>Min Limit</mat-label>
                    <input matInput type="text" class="p-1" formControlName="minLimit" />
                </mat-form-field>
                <mat-form-field class="col-1 p-1 float-start">
                    <mat-label>Max Limit</mat-label>
                    <input matInput type="text" class="p-1" formControlName="maxLimit" />
                </mat-form-field>
                <div class="col-12 float-start">
                    <button mat-flat-button color="accent" class="ps-3 pe-3 pt-1 pb-1">Submit</button>
                </div>
            </form>
        </mat-card>
        <mat-card class="col-12 mt-1 float-start">
            <div class="col-12 mt-4 pt-3 float-start">
                <div class="col-12 float-start">
                    <div class="col-1 float-start">
                        <b>Id</b>
                    </div>
                    <div class="col-1 float-start">
                        <b>Offer Type</b>
                    </div>
                    <div class="col-1 float-start">
                        <b>Discount Type</b>
                    </div>
                    <div class="col-1 float-start">
                        <b>Airline</b>
                    </div>
                    <div class="col-1 float-start">
                        <b>Source</b>
                    </div>
                    <div class="col-1 float-start">
                        <b>Discount Name</b>
                    </div>
                    <div class="col-1 float-start">
                        <b>Airline Class</b>
                    </div>
                    <div class="col-1 float-start">
                        <b>Amount</b>
                    </div>
                    <div class="col-1 float-start">
                        <b>Next Person</b>
                    </div>
                    <div class="col-1 float-start">
                        <b>Min Limit</b>
                    </div>
                    <div class="col-1 float-start">
                        <b>Max Limit</b>
                    </div>
                    <div class="col-1 float-start">
                        &nbsp;
                    </div>
                </div>
                <div class="col-12 float-start" *ngFor="let disresult of disResult;let i = index">                    
                    <div class="col-1 float-start">
                        <b #id>{{disresult.discountId}}</b>
                    </div>
                    <mat-form-field  class="col-1 p-1 float-start">
                        <mat-label>Offer Type</mat-label>
                        <input matInput type="text" [value]="disresult.offerType" class="p-1" #offerType />
                    </mat-form-field>
                    <mat-form-field class="col-1 p-1 float-start">
                        <mat-label>Discount Type</mat-label>
                        <input matInput type="text" [value]="disresult.discountType" class="p-1" #discountType />
                    </mat-form-field>
                    <mat-form-field class="col-1 p-1 float-start">
                        <mat-label>Airline</mat-label>
                        <input matInput type="text" [value]="disresult.airline" class="p-1" #airline />
                    </mat-form-field>
                    <mat-form-field class="col-1 p-1 float-start">
                        <mat-label>Source</mat-label>
                        <input matInput type="text" [value]="disresult.sources" class="p-1" #sources />
                    </mat-form-field>
                    <mat-form-field class="col-1 p-1 float-start">
                        <mat-label>Discount Name</mat-label>
                        <input matInput type="text" [value]="disresult.discountName" class="p-1" #discountName />
                    </mat-form-field>
                    <mat-form-field class="col-1 p-1 float-start">
                        <mat-label>Airline Class</mat-label>
                        <input matInput type="text" [value]="disresult.airlineclass" class="p-1" #airlineclass />
                    </mat-form-field>
                    <mat-form-field class="col-1 p-1 float-start">
                        <mat-label>Discount Amount</mat-label>
                        <input matInput type="text" [value]="disresult.discountAmount" class="p-1" #discountAmount />
                    </mat-form-field>
                    <mat-form-field class="col-1 p-1 float-start">
                        <mat-label>Next Person Discount</mat-label>
                        <input matInput type="text" [value]="disresult.nextPersonDiscountAmount" class="p-1" #nextPersonDiscountAmount />
                    </mat-form-field>
                    <mat-form-field class="col-1 p-1 float-start">
                        <mat-label>Min Limit</mat-label>
                        <input matInput type="text" [value]="disresult.minLimit" class="p-1" #minLimit />
                    </mat-form-field>
                    <mat-form-field class="col-1 p-1 float-start">
                        <mat-label>Max Limit</mat-label>
                        <input matInput type="text" [value]="disresult.maxLimit" class="p-1" #maxLimit />
                    </mat-form-field>
                    <div class="col-1 float-start">
                        <button mat-flat-button color="accent" (click)="updateDiscount(id,offerType,discountType,airline,sources,discountName,airlineclass,discountAmount,nextPersonDiscountAmount,minLimit,maxLimit)" class="ps-2 pe-2">Update</button>
                        <mat-icon (click)="deleteDiscount(disresult.discountId)">delete</mat-icon>
                    </div>
                </div>
            </div>
        </mat-card>
    </div>
</section>