<mat-card class="col-12 mt-2 mb-2 float-start pb-1">
    <form class="example-form" [formGroup]="wayform">
    <div class="col-12 float-start">
        <div class="col-12 col-md-5 float-start">
            <div class="col-12 col-md-6 p-1 float-start">
                <mat-form-field class="example-full-width col-12" appearance="fill">
                    <mat-label>FROM</mat-label>
                    <input type="text"
                           placeholder="Origin"
                           aria-label="Number"
                           matInput
                           [formControl]="myControl"
                           [matAutocomplete]="auto"
                           formControlName="Origin" value="">
                           
                    <mat-autocomplete #auto="matAutocomplete">
                      <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
                        {{option}}
                      </mat-option>
                    </mat-autocomplete>
                  </mat-form-field>
            </div>
            <div class="col-12 col-md-6 p-1 float-start">
                <mat-form-field class="example-full-width col-12" appearance="fill">
                    <mat-label>To</mat-label>
                    <input type="text"
                           placeholder="Destination"
                           aria-label="Number"
                           matInput
                           [formControl]="myControl"
                           [matAutocomplete]="auto"
                           formControlName="Destination">
                    <mat-autocomplete #auto="matAutocomplete">
                      <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
                        {{option}}
                      </mat-option>
                    </mat-autocomplete>
                  </mat-form-field>
            </div>
        </div>
        <div class="col-12 col-md-3 pt-1 float-start">
            <mat-form-field appearance="fill" class="example-form-field col-12">
                <mat-label>Departure - Return</mat-label>
                <mat-date-range-input [rangePicker]="rangePicker" [min]="myDate">
                  <input matStartDate placeholder="Departure date" formControlName="Departure">
                  <input matEndDate placeholder="Return date" formControlName="Return">
                </mat-date-range-input>
                <!-- <mat-hint>MM/DD/YYYY – MM/DD/YYYY</mat-hint> -->
                <mat-datepicker-toggle matIconSuffix [for]="rangePicker"></mat-datepicker-toggle>
                <mat-date-range-picker #rangePicker>
                  <mat-date-range-picker-actions>
                    <button mat-button matDateRangePickerCancel>Cancel</button>
                    <button mat-raised-button color="primary" matDateRangePickerApply>Apply</button>
                  </mat-date-range-picker-actions>
                </mat-date-range-picker>
              </mat-form-field>
        </div>
        <div class="col-12 col-md-3 float-start">
            <div class="col-6 col-md-6 p-1 float-start">
                <div class="col-12 btn-group">
                  <mat-form-field appearance="fill" class="col-12 dropdown-toggle" id="dropdownMenuClickableInside" data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-expanded="false">
                    <mat-label>{{adultValue+childValue+infantValue}} Travellers</mat-label>
                    <mat-select>                      
                    </mat-select>
                  </mat-form-field>
                  <ul class="dropdown-menu small p-2" aria-labelledby="dropdownMenuClickable">
                    <div class="col-12 pt-1 pb-1 float-start">
                      <small class="col-5 float-start">Adult(s)</small><span (click)="decAdult()" class="col-2 float-start"> - </span> <input type="text" value="{{adultValue}}" class="col-2 ms-1 me-1 float-start" readonly><span (click)="incAdult()" class="col-2 float-start"> + </span>
                    </div>
                    <div class="col-12 pt-1 pb-1 float-start">
                      <small class="col-5 float-start">Child(s)</small><span (click)="decChild()" class="col-2 float-start"> - </span> <input type="text" value="{{childValue}}" class="col-2 ms-1 me-1 float-start" readonly><span (click)="incChild()" class="col-2 float-start"> + </span>
                    </div>
                    <div class="col-12 pt-1 pb-1 float-start">
                      <small class="col-5 float-start">Infant(s)</small><span (click)="decInfant()" class="col-2 float-start"> - </span> <input type="text" value="{{infantValue}}" class="col-2 ms-1 me-1 float-start" readonly><span (click)="incInfant()" class="col-2 float-start"> + </span>
                    </div>                    
                  </ul>
                </div>
            </div>
            <div class="col-6 col-md-6 p-1 float-start">
                <mat-form-field appearance="fill" class="col-12">
                    <mat-label>Class</mat-label>
                    <mat-select formControlName="Class">
                      <mat-option value="1" Selected>Economy</mat-option>
                      <mat-option value="2">Business</mat-option>
                      <mat-option value="3">First Class</mat-option>
                    </mat-select>
                  </mat-form-field>
            </div>
        </div>
        <div class="col-12 col-md-1 p-1 float-start">
            <button mat-flat-button color="accent" (click)="searchFlightBtn()" class="pt-2 pb-2 d-block col-12">Search</button>
        </div>
    </div>
</form>
</mat-card>
<div class="clearfix"></div>