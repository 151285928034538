<section  class="container-fluid float-start pt-2 pb-3">
    <div class="col-12 col-md-9 p-1 float-start">
        <mat-card class="col-12 float-start flight-detail-box">
            <b class="mb-2 d-block">Flight Detail</b>
            <mat-card-content>
                <div class="col-12 float-start">

                    <div class="col-12 p-2 float-start flight-detail-bx">
                        <!-- <div class="d-inline" *ngFor="let aircode of Segments[0]">
                            {{aircode.Airline.AirlineName}}-{{aircode.Airline.AirlineCode}}-{{aircode.Airline.FlightNumber}}
                        </div> -->

                        <div class="col-12 float-start mb-1" *ngFor="let air of segments[0]">
                            <div class="col-12 float-start">{{air.GroundTime/60}}h:{{air.GroundTime%60}}m Layover in {{air.Origin.Airport.CityName}}</div>
                            <div class="col-3 float-start air-logo">
                                <div>
                                    <img src="assets/images/air-logo/{{air.Airline.AirlineCode}}.png" class="float-start me-2" width="40" /><span class="d-inline float-start"><span class="d-block">{{air.Airline.AirlineName}}</span>{{air.Airline.AirlineCode}}-{{air.Airline.FlightNumber}}</span>
                                </div>
                            </div>
                            <div class="col-9 float-start">
                                <div class="col-4 float-start origin">
                                    <div class="col-12 float-start">
                                        <b>{{air.Origin.DepTime.substring(11).slice(0,-3)}}</b><br>
                                    {{air.Origin.Airport.CityName}}<br>
                                    {{air.Origin.DepTime.substring(0).slice(0,-9)}}<br>
                                    <small>Terminal {{air.Origin.Airport.Terminal}}</small>
                                    </div>
                                </div>
                                <div class="col-4 float-start text-center duration">
                                    20h:30m
                                    <div *ngIf="itnry.IsRefundable==true" class="text-success">Refundable</div>
                                    <div *ngIf="itnry.IsRefundable==false" class="text-danger">Non Refundable</div>
                                </div>
                                <div class="col-4 float-start text-end destination">
                                    <div class="col-12 float-start">
                                        <b>{{air.Destination.ArrTime.substring(11).slice(0,-3)}}</b><br>
                                    {{air.Destination.Airport.CityName}}<br>
                                    {{air.Destination.ArrTime.substring(0).slice(0,-9)}}<br>
                                    <small>Terminal {{air.Destination.Airport.Terminal}}</small>
                                    </div>
                                </div>

                            </div>
                            <div class="col-12 float-start pt-2 pb-2 mt-2 mb-2 baggage-bx">
                                Baggage: <small>Checkin-{{air.Baggage}}</small><small>, Cabin-{{air.CabinBaggage}}</small>
                            </div>
                        </div>

                    </div>
                </div>
            </mat-card-content>
        </mat-card>
        <mat-card class="col-12 mt-3 float-start flight-insurance-box">
            <b class="mb-2 d-block">Add Travel Insurance and Secure your Trip for <mat-icon>currency_rupee</mat-icon>{{insurancepaxfee}}/Person</b>
            <mat-card-content>
                <mat-radio-group aria-label="Select an option">
                    <!-- <mat-radio-button value="1" class="col-12 mb-2"></mat-radio-button>
                    <mat-radio-button value="2" class="col-12"></mat-radio-button> -->
                    <label class="col-12 float-start">
                        <input value="1" name="sh" type="radio" [checked]="false" (click)="insuranceyes($event)" /> Yes, I want to secure my trip with insurance.
                    </label>
                    <label class="col-12 float-start">
                        <input value="0" name="sh" type="radio" [checked]="true"  (click)="insuranceno()" /> No, I do not want to insure my trip
                    </label>
                </mat-radio-group>

            </mat-card-content>
        </mat-card>
        <mat-card class="col-12 mt-3 float-start">
            <b class="mb-2 d-block">Contact Details</b>
            <mat-card-content>
                <div class="example-container col-12 float-start">
                    <form [formGroup]="travelerContact">
                    <mat-form-field appearance="fill" class="col-12 col-md-6 ps-2 pe-2 float-start">
                      <mat-label>Enter your email</mat-label>
                      <input matInput placeholder="pat@example.com" formControlName="emailId" required>
                      <mat-error *ngIf="email.invalid">{{getErrorMessage()}}</mat-error>
                    </mat-form-field>
                    <mat-form-field hintLabel="" appearance="fill" class="col-12 col-md-6 ps-2 pe-2 float-start">
                        <mat-label>Enter your phone no.</mat-label>
                        <input matInput #input maxlength="16" placeholder="Phone Number" formControlName="phoneNumber">
                        <!-- <mat-hint align="end">{{input.value.length}}/10</mat-hint> -->
                    </mat-form-field>
                    </form>
                  </div>
            </mat-card-content>
        </mat-card>
        <mat-card class="col-12 mt-3 float-start">
            <b class="mb-2 d-block">Travellers Details</b>
            <!-- <button (click)="saves()">saves</button>
            <form [formGroup]="travelerDetails">
            <button (click)="addNewPax()">add</button>
            <div formArrayName="addPax">
                <div *ngFor="let item of addPax.controls; let i=index">
                    <div [formGroupName]="i">
                        add -{{i}}
                        <mat-card-content>
                            <mat-form-field appearance="fill" class="col-6 col-md-2 ps-2 pe-2">
                                <mat-label>Title</mat-label>
                                <mat-select formControlName="title">
                                  <mat-option value="MR">MR</mat-option>
                                  <mat-option value="MS">MS</mat-option>
                                  <mat-option value="MRS">MRS</mat-option>
                                </mat-select>
                            </mat-form-field>
                            <mat-form-field appearance="fill" class="col-12 col-md-5 ps-2 pe-2">
                                <mat-label>First Name (& middle name if any)</mat-label>
                                <input matInput placeholder="Placeholder" formControlName="firstName">
                            </mat-form-field>
                            <mat-form-field appearance="fill" class="col-12 col-md-5 ps-2 pe-2">
                                <mat-label>Last Name</mat-label>
                                <input matInput placeholder="Placeholder" formControlName="lastName">
                            </mat-form-field>
                        </mat-card-content>
                    </div>
                </div>
                
            </div>
            
            
            </form> -->
            <form [formGroup]="reactform" (ngSubmit)="ProceedSave()" >
        <mat-card class="col-12 float-start">
            <mat-card-subtitle class="float-start col-12">Adult(s)</mat-card-subtitle>
            <div formArrayName="adult">
          
                <div *ngFor="let item of adult.controls;let i=index" [formGroupName]="i" class="col-12 float-start">
                  
                    <mat-form-field appearance="fill" class="col-6 col-md-2 float-start ps-2 pe-2">
                        <mat-label>Title</mat-label>
                        <mat-select formControlName="title">
                          <mat-option value="MR">MR</mat-option>
                          <mat-option value="MS">MS</mat-option>
                          <mat-option value="MRS">MRS</mat-option>
                        </mat-select>
                    </mat-form-field>
                  
                    <mat-form-field appearance="fill" class="col-12 col-md-5 float-start ps-2 pe-2">
                        <mat-label>First Name (& middle name if any)</mat-label>
                        <input matInput placeholder="Placeholder" formControlName="firstName">
                    </mat-form-field>
                  
                    <mat-form-field appearance="fill" class="col-12 col-md-5 float-start ps-2 pe-2">
                        <mat-label>Last Name</mat-label>
                        <input matInput placeholder="Placeholder" formControlName="lastName">
                    </mat-form-field>
                  
                    <!-- <div class="col-12 col-md-2 float-start ps-2 pe-2">
                        <button mat-stroked-button color="warn" (click)="RemoveAdult(i)">Remove</button>
                    </div> -->
                </div>
            </div>
    
            <div class="col-12 float-start ps-2 pe-2">
              <a mat-stroked-button color="primary" (click)="addNewAdult()">Add New Adult (+)</a>
            </div>
    
        </mat-card>
        <mat-card class="col-12 mt-1 float-start" *ngIf="isShowChild">
            <mat-card-subtitle class="float-start col-12">Child(s)</mat-card-subtitle>
            <div formArrayName="child">
          
                <div *ngFor="let item of child.controls;let i=index" [formGroupName]="i" class="col-12 float-start">
                  
                    <mat-form-field appearance="fill" class="col-6 col-md-2 float-start ps-2 pe-2">
                        <mat-label>Title</mat-label>
                        <mat-select formControlName="title">
                          <mat-option value="MR">MR</mat-option>
                          <mat-option value="MS">MS</mat-option>
                          <mat-option value="MRS">MRS</mat-option>
                        </mat-select>
                    </mat-form-field>
                  
                    <mat-form-field appearance="fill" class="col-12 col-md-5 float-start ps-2 pe-2">
                        <mat-label>First Name (& middle name if any)</mat-label>
                        <input matInput placeholder="Placeholder" formControlName="firstName">
                    </mat-form-field>
                  
                    <mat-form-field appearance="fill" class="col-12 col-md-5 float-start ps-2 pe-2">
                        <mat-label>Last Name</mat-label>
                        <input matInput placeholder="Placeholder" formControlName="lastName">
                    </mat-form-field>
                  
                    <!-- <div class="col-12 col-md-2 float-start ps-2 pe-2">
                        <button mat-stroked-button color="warn" (click)="RemoveChild(i)">Remove</button>
                    </div> -->
                </div>
            </div>
    
            <div class="col-12 float-start ps-2 pe-2">
              <a mat-stroked-button color="primary" (click)="addNewChild()">Add New Child (+)</a>
            </div>
    
        </mat-card>
        <mat-card class="col-12 mt-1 float-start" *ngIf="isShowInfant">
            <mat-card-subtitle class="float-start col-12">Infant(s)</mat-card-subtitle>
            <div formArrayName="infant">
          
                <div *ngFor="let item of infant.controls;let i=index" [formGroupName]="i" class="col-12 float-start">
                  
                    <mat-form-field appearance="fill" class="col-6 col-md-2 float-start ps-2 pe-2">
                        <mat-label>Title</mat-label>
                        <mat-select formControlName="title">
                          <mat-option value="MR">MR</mat-option>
                          <mat-option value="MS">MS</mat-option>
                          <mat-option value="MRS">MRS</mat-option>
                        </mat-select>
                    </mat-form-field>
                  
                    <mat-form-field appearance="fill" class="col-12 col-md-5 float-start ps-2 pe-2">
                        <mat-label>First Name (& middle name if any)</mat-label>
                        <input matInput placeholder="Placeholder" formControlName="firstName">
                    </mat-form-field>
                  
                    <mat-form-field appearance="fill" class="col-12 col-md-5 float-start ps-2 pe-2">
                        <mat-label>Last Name</mat-label>
                        <input matInput placeholder="Placeholder" formControlName="lastName">
                    </mat-form-field>
                  
                    <!-- <div class="col-12 col-md-2 float-start ps-2 pe-2">
                        <button mat-stroked-button color="warn" (click)="RemoveInfant(i)">Remove</button>
                    </div> -->
                </div>
            </div>
    
            <div class="col-12 float-start ps-2 pe-2">
              <a mat-stroked-button color="primary" (click)="addNewInfant()">Add New Infant (+)</a>
            </div>
    
        </mat-card>
        <!-- <div class="example-button-row" style="margin-top: 5px">
            <button mat-raised-button color="primary">Submit</button> 
        </div> -->
            </form>
        </mat-card>
        <div class="col-12 mt-3 mb-2 float-start">
            <button mat-raised-button color="accent" (click)="makeBooking()" class="col-12 col-md-3 pt-1 pb-1">Make Booking</button>
        </div>
    </div>
    <div class="col-12 col-md-3  p-1 float-start">
        <mat-card class="col-12 float-start flight-summary-box">
            <b>Price Summary</b>
            <div class="col-12 float-start pt-1 pb-1 bline" *ngFor="let fbDown of fbdown">
                <span class="col-6 float-start"><span *ngIf="fbDown.PassengerType==1">Adult</span><span *ngIf="fbDown.PassengerType==2">Child</span><span *ngIf="fbDown.PassengerType==3">Infant</span> x {{fbDown.PassengerCount}}</span><span class="col-6 float-start text-end"><mat-icon>currency_rupee</mat-icon>{{fbDown.BaseFare}}</span>
            </div>
            <div class="col-12 float-start pt-1 pb-1 bline">
                <span class="col-6 float-start">All Taxes</span><span class="col-6 float-start text-end"><mat-icon>currency_rupee</mat-icon>{{alltax}}</span>
            </div>
            <div class="col-12 float-start pt-1 pb-1 bline text-muted" *ngIf="insNo">
                <span class="col-6 float-start">uninsuranced</span><span class="col-6 float-start text-end"><mat-icon>currency_rupee</mat-icon>{{insuranceallfee}}</span>
            </div>
            <div class="col-12 float-start pt-1 pb-1 bline text-dark" *ngIf="insYes">
                <span class="col-6 float-start">Insuranced</span><span class="col-6 float-start text-end"><mat-icon>currency_rupee</mat-icon>{{insuranceallfee}}</span>
            </div>
            <div class="col-12 float-start pt-1 pb-1 bline text-success">
                <span class="col-6 float-start">Discount</span><span class="col-6 float-start text-end"><mat-icon>currency_rupee</mat-icon>{{discount}}</span>
            </div>
            <div class="col-12 float-start pt-1 pb-1 bline text-primary">
                <span class="col-6 float-start">Earn Cashback</span><span class="col-6 float-start text-end"><mat-icon>currency_rupee</mat-icon>{{cashbacks}}</span>
            </div>
            <div class="col-12 float-start pt-1 pb-1 bline grand text-danger fs-6">
                <span class="col-6 float-start">Grand Total</span><span class="col-6 float-start text-end"><mat-icon>currency_rupee</mat-icon>{{allbase+alltax + insuranceallfee - discount}}</span>
            </div>
        </mat-card>
        <mat-card class="col-12 float-start mt-2 flight-discount-box">
            <b>Offer Promo</b>
            <div class="col-12" *ngFor="let dislist of discountList">
                <label class="col-12 float-start" *ngIf="dislist.offerType=='Discount'">
                    <input [value]="dislist.discountAmount/100*allfare" name="offer" title="{{dislist.discountName}}" [attr.offertype]="dislist.offerType" type="radio" (change)="discounta($event)" /> Use Promo Code: {{dislist.discountName}} to get flat <mat-icon>currency_rupee</mat-icon>{{dislist.discountAmount/100*allfare | number:'1.0-0'}} OFF
                </label>
                <!-- <label class="col-12 float-start">
                    <input [value]="0" name="shss" type="radio" (click)="discountb()" /> Discountb
                </label> -->
                <label class="col-12 float-start" *ngIf="dislist.offerType=='Cashback'">
                    <input [value]="dislist.discountAmount/100*allfare" name="offer" title="{{dislist.discountName}}" [attr.offertype]="dislist.offerType" type="radio" (change)="discounta($event)" /> Use Promo Code:{{dislist.discountName}}  to get flat <mat-icon>currency_rupee</mat-icon>{{dislist.discountAmount/100*allfare | number:'1.0-0'}} OFF
                </label>
            </div>
            
        </mat-card>
    </div>


</section>

<!-- <div class="col-12 float-start pt-1 pb-1 bline">
                <span class="col-6 float-start">Child x </span><span class="col-6 float-start text-end"><mat-icon>currency_rupee</mat-icon>7999</span>
            </div>
            <div class="col-12 float-start pt-1 pb-1 bline">
                <span class="col-6 float-start">Infant x 2</span><span class="col-6 float-start text-end"><mat-icon>currency_rupee</mat-icon>7999</span>
            </div>
            <div class="col-12 float-start pt-1 pb-1 bline">
                <span class="col-6 float-start">All Taxes</span><span class="col-6 float-start text-end"><mat-icon>currency_rupee</mat-icon>{{adults.Tax}}</span>
            </div>
            <div class="col-12 float-start pt-1 pb-1 bline" *ngIf="sh == 1">
                <span class="col-6 float-start">Insuranced</span><span class="col-6 float-start text-end"><mat-icon>currency_rupee</mat-icon>500</span>
            </div>
            <div class="col-12 float-start pt-1 pb-1 bline" *ngIf="sh == 0">
                <span class="col-6 float-start">Uninsuranced</span><span class="col-6 float-start text-end"><mat-icon>currency_rupee</mat-icon>500</span>
            </div>
            
            <div class="col-12 float-start pt-1 pb-1 bline grand text-danger fs-6">
                <span class="col-6 float-start">Grand Total</span><span class="col-6 float-start text-end"><mat-icon>currency_rupee</mat-icon></span>
            </div> -->