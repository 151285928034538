import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { LoginComponent } from './login/login.component';
import { DashboardComponent} from './dashboard/dashboard.component';

//flight
import { FlightBookingsComponent } from './products/flight/flight-bookings/flight-bookings.component';
import {SearchResultsComponent} from './products/flight/search-results/search-results.component';
import { FlightReviewComponent } from './products/flight/flight-review/flight-review.component';
import { ReturnFlightReviewComponent } from './products/flight/return-flight-review/return-flight-review.component';
import { OpenFlightBookingComponent } from './products/flight/open-flight-booking/open-flight-booking.component';
import { AddFlightFeesComponent } from './products/flight/add-flight-fees/add-flight-fees.component';
import { AddFlightDiscountsComponent } from './products/flight/add-flight-discounts/add-flight-discounts.component';
import {HtmltopdfComponent} from './products/flight/htmltopdf/htmltopdf.component';

// holiday
import { HolidayBookingsComponent } from './products/holiday/holiday-bookings/holiday-bookings.component';
import { AddPackagesComponent } from './products/holiday/add-packages/add-packages.component';
const routes: Routes = [
  {path:'',redirectTo:'login',pathMatch:'full'},
  //{path:'**',component:PageNotFoundComponent}
  {path:'login',component:LoginComponent},
  {path:'dashboard',component:DashboardComponent},

  //flight routing
  {path:'flight/flight-bookings',component:FlightBookingsComponent},
  {path:'flight/search-results',component:SearchResultsComponent},
  {path:'flight/flight-review',component:FlightReviewComponent},
  {path:'flight/return-flight-review',component:ReturnFlightReviewComponent},
  {path:'flight/open-flight-booking',component:OpenFlightBookingComponent},
  {path:'flight/add-flight-fees',component:AddFlightFeesComponent},
  {path:'flight/add-flight-discounts',component:AddFlightDiscountsComponent},
  {path:'flight/htmltopdf',component:HtmltopdfComponent},

  //holiday routing
  {path:'holiday/holiday-bookings',component:HolidayBookingsComponent},
  {path:'holiday/add-packages',component:AddPackagesComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
