import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {FormControl, Validators, FormBuilder, FormGroup, FormArray } from '@angular/forms';
import { AnyARecord } from 'dns';

@Component({
  selector: 'app-add-flight-discounts',
  templateUrl: './add-flight-discounts.component.html',
  styleUrls: ['./add-flight-discounts.component.css']
})
export class AddFlightDiscountsComponent implements OnInit {
  url = environment.apiUrl;
  flightDiscountForm!: FormGroup;
  updateFlightFeeForm!:FormGroup;
  feessubmit:any;
  feeResult: any;
  processBar:boolean=false;
  discountssubmit: any;
  disResult: any;
  constructor(private httpClient:HttpClient) { }

  ngOnInit(): void {
    //Flight Discounts result
    this.httpClient.get(this.url+"/flight/flight-get-discounts").subscribe(resp =>{
      //   this.isShow = false;
       this.disResult = resp;
          
      //console.log(this.disResult)

   });

    this.flightDiscountForm = new FormGroup({
      offerType: new FormControl(),
      discountType: new FormControl(),
      airline: new FormControl(),
      sources: new FormControl(),
      discountName: new FormControl(),
      airlineclass: new FormControl(),
      discountAmount: new FormControl(),
      nextPersonDiscountAmount: new FormControl(),
      minLimit: new FormControl(),
      maxLimit: new FormControl()
  });
  }
  flightDiscountSubmit(){
    console.log(this.flightDiscountForm.value)
    const disObj =this.flightDiscountForm.value
    
    let httpheaders = new HttpHeaders({ 
      'content-type':'application/json'  
       });
    return this.httpClient.post(this.url+"/flight/flight-discounts-submited",disObj, {headers:httpheaders}).subscribe(resp =>{
           //   this.isShow = false;
            this.discountssubmit = resp; 
            this.ngOnInit();                
        });   
  }
  deleteDiscount(discountId:any){       
    return this.httpClient.get(this.url+"/flight/flight-discount-delete/"+discountId+"").subscribe(resp =>{
      let dsds = resp;
      this.ngOnInit();
   });
}
updateDiscount(id:any,offerType:any,discountType:any,airline:any,sources:any,discountName:any,airlineclass:any,discountAmount:any,nextPersonDiscountAmount:any,minLimit:any,maxLimit:any){  
  //debugger     
  var id=id.firstChild.data; 
  var offerType=offerType.value;
  var discountType=discountType.value;
  var airline=airline.value;
  var sources=sources.value;
  var discountName=discountName.value;
  var airlineclass=airlineclass.value;
  var discountAmount=discountAmount.value;
  var nextPersonDiscountAmount=nextPersonDiscountAmount.value;
  var minLimit=minLimit.value;
  var maxLimit=maxLimit.value;
  //debugger  
    return this.httpClient.get(this.url+"/flight/flight-discount-update/"+id+"/"+offerType+"/"+discountType+"/"+airline+"/"+sources+"/"+discountName+"/"+airlineclass+"/"+discountAmount+"/"+nextPersonDiscountAmount+"/"+minLimit+"/"+maxLimit+"").subscribe(resp =>{
      let discountResult = resp;       
      this.ngOnInit();
   });
}
}