<app-header></app-header>
<section class="container-fluid d-block pt-3">
    <div class="col-6 col-md-3 ps-1 pe-1 mb-1 float-start">
        <a href="/flight/flight-bookings">
            <mat-card>
                <mat-card-content>Total Flight Bookings -{{results?.total}}</mat-card-content>
            </mat-card>
        </a>
    </div>
    <div class="col-6 col-md-3 ps-1 pe-1 mb-1 float-start">
        <a href="/flight/flight-bookings">
            <mat-card>
                <mat-card-content>Total Flight Bookings -{{results?.total}}</mat-card-content>
            </mat-card>
        </a>
    </div>
    <div class="col-6 col-md-3 ps-1 pe-1 mb-1 float-start">
        <a href="/flight/flight-bookings">
            <mat-card>
                <mat-card-content>Total Flight Bookings -{{results?.total}}</mat-card-content>
            </mat-card>
        </a>
    </div>
    <div class="ccol-6 col-md-3 ps-1 pe-1 mb-1 float-start">
        <a href="/flight/flight-bookings">
            <mat-card>
                <mat-card-content>Total Flight Bookings -{{results?.total}}</mat-card-content>
            </mat-card>
        </a>
    </div>
</section>
<app-footer></app-footer>