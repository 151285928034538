import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {FormControl, Validators, FormBuilder, FormGroup, FormArray } from '@angular/forms';
import { features } from 'process';

@Component({
  selector: 'app-add-flight-fees',
  templateUrl: './add-flight-fees.component.html',
  styleUrls: ['./add-flight-fees.component.css']
})
export class AddFlightFeesComponent implements OnInit {
  url = environment.apiUrl;
  flightConFeeForm!: FormGroup;
  flightInsFeeForm!: FormGroup;
  updateFlightConFeeForm!:FormGroup;
  updateFlightInsFeeForm!:FormGroup;
  confeessubmit:any;
  insfeessubmit:any;
  confeeResult: any;
  insfeeResult: any;
  processBar:boolean=false;
  constructor(private httpClient:HttpClient) { }
  
  ngOnInit(): void {
    //Flight convenience fee result
    this.httpClient.get(this.url+"/flight/flight-get-convenience-fees").subscribe(resp =>{
      //   this.isShow = false;
       this.confeeResult = resp;   
      console.log(this.confeeResult)
   });
   //Flight insurance fee result
   this.httpClient.get(this.url+"/flight/flight-get-insurance-fees").subscribe(resp =>{
    //   this.isShow = false;
     this.insfeeResult = resp;   
    console.log(this.insfeeResult)
 });

    this.flightConFeeForm = new FormGroup({
      journeyType: new FormControl(),
      tripType: new FormControl(),
      fee: new FormControl()
  });
  this.flightInsFeeForm = new FormGroup({
    journeyType: new FormControl(),
    tripType: new FormControl(),
    fee: new FormControl()
  });
   this.updateFlightConFeeForm = new FormGroup({
     fee: new FormControl()
   });
   this.updateFlightInsFeeForm = new FormGroup({
    fee: new FormControl()
  });
  }
  flightConfeeSubmit(){
    console.log(this.flightConFeeForm.value)
    const feeObj =this.flightConFeeForm.value
    debugger
    let httpheaders = new HttpHeaders({ 
      'content-type':'application/json'  
       });
    return this.httpClient.post(this.url+"/flight/flight-convenience-fees-submited",feeObj, {headers:httpheaders}).subscribe(resp =>{
           //   this.isShow = false;
            this.confeessubmit = resp;                 
        });

  }
  flightInsfeeSubmit(){
    console.log(this.flightInsFeeForm.value)
    const feeObj =this.flightInsFeeForm.value
    debugger
    let httpheaders = new HttpHeaders({ 
      'content-type':'application/json'  
       });
    return this.httpClient.post(this.url+"/flight/flight-insurance-fees-submited",feeObj, {headers:httpheaders}).subscribe(resp =>{
            //this.isShow = false;
            this.insfeessubmit = resp;                 
        });
  }
  updateConFee(confeeAmount:any,id:any){
    //this.processBar=true;
    var id=id.firstChild.data;    
    return this.httpClient.get(this.url+"/flight/flight-update-convenience-fees/"+id+"/"+confeeAmount+"").subscribe(resp =>{       
      this.confeeResult = resp;       
      this.ngOnInit();
   });
   
  }
  updateInsFee(insfeeAmount:any,id:any){
    //this.processBar=true;
    var id=id.firstChild.data;    
    return this.httpClient.get(this.url+"/flight/flight-update-insurance-fees/"+id+"/"+insfeeAmount+"").subscribe(resp =>{
      this.insfeeResult = resp;       
      this.ngOnInit();
   });
   
  }
  // checkedFee(event:any){
  //   console.log(event.target.checked)
  //   if(event.target.checked){

  //   }
  // }
 
 
  
}
