import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../../../environments/environment';

@Component({
  selector: 'app-left-flight-menubar',
  templateUrl: './left-flight-menubar.component.html',
  styleUrls: ['./left-flight-menubar.component.css']
})
export class LeftFlightMenubarComponent implements OnInit {
  url = environment.apiUrl;
  respn: any;
  totalFlight: any;
  totalSuccessFlight: any;
  sfrespn: any;
  pfrespn: any;
  totalPaymentFailedFlight: any;
  constructor(private httpClient:HttpClient) { }

  ngOnInit(): void {
    this.httpClient.get(this.url+"/flight/total-default-flight-bookings").subscribe(resp =>{
      //   this.isShow = false;
      this.respn = resp;
      let remvAry = this.respn[0];
      this.totalFlight = remvAry;
      //console.log(this.totalFlight)
    });

    this.httpClient.get(this.url+"/flight/total-success-flight-bookings").subscribe(resp =>{
        //   this.isShow = false;
        this.sfrespn = resp;
        let remvAry = this.sfrespn[0];
        this.totalSuccessFlight = remvAry;
        //console.log(this.totalSuccessFlight)
    });

    this.httpClient.get(this.url+"/flight/total-paymentfailed-flight-bookings").subscribe(resp =>{
          //   this.isShow = false;
          this.pfrespn = resp;
          let remvAry = this.pfrespn[0];
          this.totalPaymentFailedFlight = remvAry;
          //console.log(this.totalSuccessFlight)
    });
  }

}
