import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders} from '@angular/common/http';
import {Router,ActivatedRoute} from '@angular/router';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
@Component({
  selector: 'app-flight-search-engine',
  templateUrl: './flight-search-engine.component.html',
  styleUrls: ['./flight-search-engine.component.css']
})
export class FlightSearchEngineComponent implements OnInit {
  adultValue:any;
  childValue:any;
  infantValue:any;
  myDate = new Date();
  minDate= new Date(this.myDate)
  minDateR= new Date();
  myControl = new FormControl('');
  options: string[] = ['New Delhi-DEL', 'Mumbai-BOM', 'Bangalore-BLR', 'New York-NYC', 'Dubai-DXB'];
  filteredOptions: Observable<string[]> | undefined;
  wayform!: FormGroup;
  constructor(private httpClient:HttpClient, private router:Router,private fbuilder:FormBuilder) { }

  ngOnInit(): void {
    this.filteredOptions = this.myControl.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value || '')),
    );
    this.adultValue=1;
    this.childValue=0;
    this.infantValue=0;
    this.wayform = this.fbuilder.group({ 
      //typ:1,
      Origin:['', [Validators.required]],
      Destination:['', [Validators.required]],
      Adt:['', [Validators.required]],
      Chd:['', [Validators.nullValidator]],
      Inf:['', [Validators.nullValidator]],
      Class:['1', [Validators.required]],
      Departure:['', [Validators.required]],
      Return:['', [Validators.nullValidator]]    
    });
    debugger
   // console.log(this.wayform)
  }
  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.options.filter(option => option.toLowerCase().includes(filterValue));
  }
 
  searchFlightBtn(){
    let formObj = this.wayform.getRawValue();
    //let searchObject = JSON.stringify(formObj);   
    
 // console.log(formObj.typ)
  
  if(formObj.Return===""){
    var depYear=formObj.Departure.getFullYear();
    let depM=formObj.Departure.getMonth()+1;
    let depMonth=('0' + depM).slice(-2);
    let depDay=('0' + formObj.Departure.getDate()).slice(-2)
    debugger
    var trip={
      "typ":1,
      org:formObj.Origin.slice(-3),
      des:formObj.Destination.slice(-3),
      "adt":this.adultValue,
      "chd":this.childValue,
      "inf":this.infantValue,
      "cls":formObj.Class,
      "dep":depYear+'-'+depMonth+'-'+depDay,
      "arr":depYear+'-'+depMonth+'-'+depDay
    }
   // console.log(trip)
    debugger
    //console.log(bookingId)
    this.router.navigate(['./flight/search-results'],{                 
      //queryParams:{json:btoa(JSON.stringify(this.json))}
      //queryParams:{index:typ+'-TripTo-'+org+'-'+des+'-Pax-'+adt+'-'+chd+'-'+inf+'-Date-'+dep+'-'+arr+'-Class-'+cls}
      queryParams:{index:JSON.stringify(trip)}
     })
  }
  else{
    let depYear=formObj.Departure.getFullYear();
    let depM=formObj.Departure.getMonth()+1;
    let depMonth=('0' + depM).slice(-2);
    let depDay=('0' + formObj.Departure.getDate()).slice(-2)
    let retYear=formObj.Return.getFullYear();
    let retM=formObj.Return.getMonth()+1;
    let retMonth=('0' + retM).slice(-2);
    let retDay=('0' + formObj.Return.getDate()).slice(-2)
    debugger
    var trip={
      "typ":2,
      org:formObj.Origin.slice(-3),
      des:formObj.Destination.slice(-3),
      "adt":this.adultValue,
      "chd":this.childValue,
      "inf":this.infantValue,
      "cls":formObj.Class,
      "dep":depYear+'-'+depMonth+'-'+depDay,
      "arr":retYear+'-'+retMonth+'-'+retDay
    }
    //console.log(trip)
    debugger
    //console.log(bookingId)
    this.router.navigate(['./flight/search-results'],{      
           
      //queryParams:{json:btoa(JSON.stringify(this.json))}
      //queryParams:{index:typ+'-TripTo-'+org+'-'+des+'-Pax-'+adt+'-'+chd+'-'+inf+'-Date-'+dep+'-'+arr+'-Class-'+cls}
      queryParams:{index:JSON.stringify(trip)}
     })
  }
    
     
  }
  //Plus Minus Passengers
  decAdult(){
    if(this.adultValue==1){
      this.adultValue=1
    }
    else{
      this.adultValue=this.adultValue-1
    }
  }
  incAdult(){
    if(this.adultValue+this.childValue<=8){
      debugger
      this.adultValue=this.adultValue+1
    }    
  }
  decChild(){    
    if(this.childValue==0){
      this.childValue=0
    }
    else{
      this.childValue=this.childValue-1
    }
  }
  incChild(){
    if(this.adultValue+this.childValue<=8){
      debugger
      this.childValue=this.childValue+1 
      debugger
    }     
  }
  decInfant(){    
    if(this.infantValue==0){
      this.infantValue=0
    }
    else{
      this.infantValue=this.infantValue-1
    }
  }
  incInfant(){    
    if(this.adultValue==this.infantValue){      
      this.infantValue=this.adultValue         
    }
    else{
      this.infantValue=this.infantValue+1
    }    
  }
}
