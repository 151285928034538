import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import {DashboardComponent } from './dashboard/dashboard.component';

//flight module import
import { LeftFlightMenubarComponent } from './products/flight/left-flight-menubar/left-flight-menubar.component';
import { FlightBookingsComponent } from './products/flight/flight-bookings/flight-bookings.component';
import { FlightSearchEngineComponent } from './products/flight/flight-search-engine/flight-search-engine.component';
import { SearchResultsComponent } from './products/flight/search-results/search-results.component';
import { FlightReviewComponent } from './products/flight/flight-review/flight-review.component';
import { ReturnFlightReviewComponent } from './products/flight/return-flight-review/return-flight-review.component';
import { OpenFlightBookingComponent } from './products/flight/open-flight-booking/open-flight-booking.component';
import { AddFlightFeesComponent } from './products/flight/add-flight-fees/add-flight-fees.component';
import { AddFlightDiscountsComponent } from './products/flight/add-flight-discounts/add-flight-discounts.component';

//holiday module import
import { HolidayBookingsComponent } from './products/holiday/holiday-bookings/holiday-bookings.component';
import { AddPackagesComponent } from './products/holiday/add-packages/add-packages.component';

import {MatToolbarModule} from '@angular/material/toolbar';
import {MatTabsModule} from '@angular/material/tabs';
import {MatDialogModule} from '@angular/material/dialog';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatSliderModule} from '@angular/material/slider';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatNativeDateModule} from '@angular/material/core';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatRadioModule} from '@angular/material/radio';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatSelectModule} from '@angular/material/select';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {MatMenuModule} from '@angular/material/menu';
import {BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {MatCardModule} from '@angular/material/card';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
//import {MatPaginator} from '@angular/material/paginator';
import {MatTableModule} from '@angular/material/table';
//import {MatTableDataSource} from '@angular/material/table';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatListModule} from '@angular/material/list';
import { FilterPipe } from './products/flight/search-results/filter.pipe';
import { HtmltopdfComponent } from './products/flight/htmltopdf/htmltopdf.component';





@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    HeaderComponent,
    FooterComponent,
    DashboardComponent,
    LeftFlightMenubarComponent,
    FlightBookingsComponent,    
    FlightSearchEngineComponent,
    SearchResultsComponent,
    FlightReviewComponent,
    ReturnFlightReviewComponent,
    OpenFlightBookingComponent,
    AddFlightFeesComponent,
    AddFlightDiscountsComponent,
    FilterPipe,
    HolidayBookingsComponent,
    AddPackagesComponent,
    HtmltopdfComponent
    
    
    
    
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    
    MatToolbarModule,
    MatTabsModule,
    MatDialogModule,
    MatAutocompleteModule,
    MatSliderModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatFormFieldModule,
    MatInputModule,
    MatRadioModule,
    MatCheckboxModule,
    MatSelectModule,
    MatIconModule,
    MatButtonModule,
    MatMenuModule,
    BrowserAnimationsModule,
    MatCardModule,
    MatProgressSpinnerModule,
    //MatPaginator,
    MatTableModule,
    //MatTableDataSource
    MatSidenavModule,
    MatExpansionModule,
    MatGridListModule,
    MatListModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
