import { Component, OnInit } from '@angular/core';
import {Router,ActivatedRoute} from '@angular/router';
import { HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {FormControl, Validators, FormBuilder, FormGroup, FormArray } from '@angular/forms';

@Component({
  selector: 'app-return-flight-review',
  templateUrl: './return-flight-review.component.html',
  styleUrls: ['./return-flight-review.component.css']
})
export class ReturnFlightReviewComponent implements OnInit {
  url = environment.apiUrl;
  travelerContact!: FormGroup;
  travelerDetails!: FormGroup;
  isChecked:boolean= true;
  sh:any;
  segments:any;
  itnry:any;
  fbdown:any;
  adults:any;
  childs:any;
  infants:any;
  ads:any;
  index:any;
  alltax: any;
  allbase: any;
  discount: any;
  insurancepaxfee: any;
  insuranceallfee: any;
  conveniencepaxfee: any;
  convenienceallfee: any;
  allpaxcount: any;
  paxcount: any;
  grandtotal: any;
  bookingsubmit: any;
  origin: any;
  destination: any;
  traceid: any;
  tokenid: any;
  formArray: any;
  title = 'FormArray';
  items!: FormArray;
  adultCount: any;
  childCount: any;
  infantCount: any;
  isShowChild:boolean =false;
  isShowInfant:boolean =false;
  disrlt: any;
  //discountList: any;
  allfare: any;
  disName: any;
  cashbacks: any;
  casName: any;
  feeresult: any;
  returnItineraryOb: any;
  returnItineraryIb: any;
  fbdownOb: any;
  fbdownIb: any;
  alltaxOb: any;
  alltaxIb: any;
  allbaseOb:any;
  allbaseIb:any;
  totalReturnFare: any;
  airMarkOb: any;
  airMarkIb: any;
  discountClassAmount: any;
  discountClassMaxLimit: any;
  discountClassAmountMinLimit: any;
  discountClassName: any;
  discountClassOffer: any;
  cashbackClassName: any;
  cashbackClassOffer: any;
  cashbackClassAmountMinLimit: any;
  cashbackClassMaxLimit: any;
  cashbackClassAmount: any;
  getFeeObj: any;
  constructor(private httpClient:HttpClient, private router:Router, private route:ActivatedRoute, private formBuilder: FormBuilder) { }
  
  ngOnInit(): void {
    this.route.queryParams.subscribe((params)=>{
      //console.log(params.json);
      //this.data= JSON.parse(atob(params.data));
      this.itnry= JSON.parse(params['index']);  
      this.origin=this.itnry.Origin;
      this.destination=this.itnry.Destination;
      this.tokenid=this.itnry.TokenId;
      this.traceid=this.itnry.TraceId;    
      console.log(this.itnry)
      this.returnItineraryOb= this.itnry.ItineraryOb.Segments;
      this.returnItineraryIb= this.itnry.ItineraryIb.Segments;
      this.airMarkOb=this.itnry.ItineraryOb.AirlineRemark;
      this.airMarkIb=this.itnry.ItineraryIb.AirlineRemark;
      //console.log(this.airMark)
      //console.log(this.childCount)
      //console.log(this.infantCount)
      this.fbdownOb= this.itnry.ItineraryOb.FareBreakdown;
      this.fbdownIb= this.itnry.ItineraryIb.FareBreakdown;
      console.log(this.fbdownOb)
      debugger
      // this.adults= this.itnry.FareBreakdown[0];
      // let adults= this.itnry.FareBreakdown[0].Tax;
      // this.childs= this.itnry.FareBreakdown[1];
      // let childs= this.itnry.FareBreakdown[1];
      // this.infants= this.itnry.FareBreakdown[2];
      // let infants= this.itnry.FareBreakdown[2];
      
      //console.log(this.segments)
      //console.log(this.fbdown)
      //console.log(this.adults)
      //console.log(this.childs)
      //console.log(this.infants)
      var allbaseOb = 0;
      this.fbdownOb.forEach((item: { BaseFare: number; }) => {
        allbaseOb += item.BaseFare;        
      });
      this.allbaseOb= allbaseOb;
      var allbaseIb = 0;
      this.fbdownIb.forEach((item: { BaseFare: number; }) => {
        allbaseIb += item.BaseFare;        
      });
      this.allbaseIb= allbaseIb;
      //console.log(this.allbase);
      var alltaxOb = 0;
      this.fbdownOb.forEach((item: { Tax: number; }) => {
        alltaxOb += item.Tax;
      });
      this.alltaxOb= alltaxOb;
      var alltaxIb = 0;
      this.fbdownIb.forEach((item: { Tax: number; }) => {
        alltaxIb += item.Tax;
      });
      this.alltaxIb= alltaxIb;
      this.totalReturnFare=this.allbaseOb+this.allbaseIb+this.alltaxOb+this.alltaxIb;
      debugger
      //console.log(this.alltax);
      //this.allfare=allbase+alltax;
      //console.log(this.allfare)
      var allpaxcount = 0;
      this.fbdownOb.forEach((item: { PassengerCount: number; }) => {
        allpaxcount += item.PassengerCount;
      });
      this.allpaxcount= allpaxcount;
      //console.log(this.allpaxcount);
      debugger
      
      this.grandtotal=0;
      
      
      debugger
      this.paxcount=allpaxcount;
      this.cashbacks=0;
      console.log(this.discount)
      debugger

      //this.adultCount= this.itnry.Itinerary.FareBreakdown[0].PassengerCount;
      //this.childCount= this.itnry.Itinerary.FareBreakdown[1].PassengerCount;
      //this.infantCount= this.itnry.Itinerary.FareBreakdown[2].PassengerCount;

      })
      this.discount=0;
      this.insuranceallfee=0;

      this.travelerContact = this.formBuilder.group({ 
        emailId:['', [Validators.required]],
        phoneNumber:['', [Validators.required]]   
      });
      this.httpClient.get(this.url+"/flight/flight-get-fees").subscribe(resp =>{
        //   this.isShow = false;
         this.feeresult = resp;
         console.log(this.feeresult)
        //  const discountList=this.feeresult.filter((el: { airlineclass: string; }) => {          
        //   return el.airlineclass== this.airMark;          
        //   })
        const checkedRound =["Round"]
        const getJourneyTypeObj=this.feeresult.filter((item:any)=>
        checkedRound.includes(item.journeyType));
        const checkedtripType =["Dom"]
        this.getFeeObj=getJourneyTypeObj.filter((item:any)=>
        checkedtripType.includes(item.tripType));
        this.insurancepaxfee=Number(this.getFeeObj[0].fee);
          debugger
     });
      this.httpClient.get(this.url+"/flight/flight-get-discounts").subscribe(resp =>{
        //   this.isShow = false;
         this.disrlt = resp;
         console.log(this.disrlt)
         //Discount Class
         const discountListClassOb=this.disrlt.filter((el: { airlineclass: string; }) => {          
          return el.airlineclass== this.airMarkOb;          
         })
          const discountListOb=discountListClassOb.filter((el: { offerType: string; }) => {          
            return el.offerType== 'Discount';          
          })
          const discountListClassIb=this.disrlt.filter((el: { airlineclass: string; }) => {          
            return el.airlineclass== this.airMarkIb;          
           })
            const discountListIb=discountListClassIb.filter((el: { offerType: string; }) => {          
              return el.offerType== 'Discount';          
            })
            
            if(discountListOb.length==0 && discountListIb.length==0){
              
            }
            else if(discountListOb.length==0){    
                        
              this.discountClassName=discountListIb[0].discountName;
              this.discountClassOffer=discountListIb[0].offerType;
              const discountAmountIb=Number(discountListIb[0].discountAmount);
              let discountMaxIb=Number(discountListIb[0].maxLimit);
              let discountMinIb=Number(discountListIb[0].minLimit);
              this.discountClassAmount = discountAmountIb/2
              this.discountClassMaxLimit = discountMaxIb/2
              this.discountClassAmountMinLimit = discountMinIb/2
              
            }
            else if(discountListIb.length==0){     
                       
              this.discountClassName=discountListOb[0].discountName;
              this.discountClassOffer=discountListOb[0].offerType;
              const discountAmountOb=Number(discountListOb[0].discountAmount);
              let discountMaxOb=Number(discountListOb[0].maxLimit);
              let discountMinOb=Number(discountListOb[0].minLimit);
              this.discountClassAmount = discountAmountOb/2
              this.discountClassMaxLimit = discountMaxOb/2
              this.discountClassAmountMinLimit = discountMinOb/2
              
              
            }
            
            
          else{
            
            this.discountClassName=discountListOb[0].discountName;
          this.discountClassOffer=discountListOb[0].offerType;
          debugger
          const discountAmountOb=Number(discountListOb[0].discountAmount);
          let discountMaxOb=Number(discountListOb[0].maxLimit);
          let discountMinOb=Number(discountListOb[0].minLimit);
          const discountAmountIb=Number(discountListIb[0].discountAmount);
          let discountMaxIb=Number(discountListIb[0].maxLimit);
          let discountMinIb=Number(discountListIb[0].minLimit);
          this.discountClassAmount = (discountAmountOb+discountAmountIb)/2
          this.discountClassMaxLimit = (discountMaxOb+discountMaxIb)/2
          this.discountClassAmountMinLimit = (discountMinOb+discountMinIb)/2
          
          }
          
         //Cashback Class
         const cashbackListClassOb=this.disrlt.filter((el: { airlineclass: string; }) => {          
          return el.airlineclass== this.airMarkOb;          
         })
          const cashbackListOb=cashbackListClassOb.filter((el: { offerType: string; }) => {          
            return el.offerType== 'Cashback';          
          })
          const cashbackListClassIb=this.disrlt.filter((el: { airlineclass: string; }) => {          
            return el.airlineclass== this.airMarkIb;          
           })
            const cashbackListIb=cashbackListClassIb.filter((el: { offerType: string; }) => {          
              return el.offerType== 'Cashback';          
            })
            debugger
            if(cashbackListOb.length==0 && cashbackListIb.length==0){
              debugger
            } 
            if(cashbackListOb.length==0){              
              this.cashbackClassName=cashbackListIb[0].discountName;
              this.cashbackClassOffer=cashbackListIb[0].offerType;
              const cashbackAmountIb=Number(cashbackListIb[0].discountAmount);
              let cashbackMaxIb=Number(cashbackListIb[0].maxLimit);
              let cashbackMinIb=Number(cashbackListIb[0].minLimit);
              this.cashbackClassAmount = cashbackAmountIb/2
              this.cashbackClassMaxLimit = cashbackMaxIb/2
              this.cashbackClassAmountMinLimit = cashbackMinIb/2
              debugger
            }
            if(cashbackListIb.length==0){
              debugger
              this.cashbackClassName=cashbackListOb[0].discountName;
              this.cashbackClassOffer=cashbackListOb[0].offerType;
              const cashbackAmountOb=Number(cashbackListOb[0].discountAmount);
              let cashbackMaxOb=Number(cashbackListOb[0].maxLimit);
              let cashbackMinOb=Number(cashbackListOb[0].minLimit);
              this.cashbackClassAmount = cashbackAmountOb/2
              this.cashbackClassMaxLimit = cashbackMaxOb/2
              this.cashbackClassAmountMinLimit = cashbackMinOb/2
              debugger
            }
          else{
          this.cashbackClassName=cashbackListIb[0].discountName;
          this.cashbackClassOffer=cashbackListIb[0].offerType;
          const cashbackAmountOb=Number(cashbackListOb[0].discountAmount);
          let cashbackMaxOb=Number(cashbackListOb[0].maxLimit);
          let cashbackMinOb=Number(cashbackListOb[0].minLimit);
          const cashbackAmountIb=Number(cashbackListIb[0].discountAmount);
          let cashbackMaxIb=Number(cashbackListIb[0].maxLimit);
          let cashbackMinIb=Number(cashbackListIb[0].minLimit);
          this.cashbackClassAmount = (cashbackAmountOb+cashbackAmountIb)/2
          this.cashbackClassMaxLimit = (cashbackMaxOb+cashbackMaxIb)/2
          this.cashbackClassAmountMinLimit = (cashbackMinOb+cashbackMinIb)/2 
          }
          
        //console.log(this.discountList)
        
     });
     
      // this.travelerDetails = this.formBuilder.group({ 
      //   title:['', [Validators.required]],
      //   firstName:['', [Validators.required]],
      //   lastName:['', [Validators.required]]   
      // });
      // this.formArray = new FormArray([
      //   this.travelerDetails=new FormGroup({
      //    title: new FormControl(null),
      //    firstName: new FormControl(null),
      //    lastName: new FormControl(null),
      //   })
        
      // ])
      //console.log(this.formArray)
      this.travelerDetails =new FormGroup({
        traveler:new FormArray([])
      });

    this.addNewAdult();
     // this.addNewChild();
     // this.addNewInfant();
     this.isShowchild();
     this.isShowinfant();
     
  }
  
  reactform = new FormGroup({
    adult: new FormArray([]),
    child: new FormArray([]),
    infant: new FormArray([])
  });

  ProceedSave() {
    console.log(this.reactform.value);
  }

  addNewAdult() {
    let ars=this.reactform.value
    //console.log(ars.adult.length)
    if(ars.adult.length==this.adultCount){
    }
    else{
      this.items = this.reactform.get("adult") as FormArray;
      this.items.push(this.PaxGroup());
    }
    
  }
  isShowchild(){
    if(this.childCount>=0){
      this.isShowChild = !this.isShowChild; 
    }
  }
  addNewChild() {
    let ars=this.reactform.value
    
   // console.log(ars.child.length)
    if(ars.child.length==this.childCount){
    }
    
    else{
      this.items = this.reactform.get("child") as FormArray;
      this.items.push(this.PaxGroup());
    }
    
  }
  isShowinfant(){
    if(this.infantCount>=0){
      this.isShowInfant = !this.isShowInfant; 
    }
  }
  addNewInfant() {
    let ars=this.reactform.value
    //console.log(ars.infant.length)
    if(ars.infant.length==this.infantCount){
    }
    else{
      this.items = this.reactform.get("infant") as FormArray;
      this.items.push(this.PaxGroup());
    }
    
  }
  RemoveAdult(index:any){
    this.items = this.reactform.get("adult") as FormArray;
    this.items.removeAt(index)
  }
  RemoveChild(index:any){
    this.items = this.reactform.get("child") as FormArray;
    this.items.removeAt(index)
  }
  RemoveInfant(index:any){
    this.items = this.reactform.get("infant") as FormArray;
    this.items.removeAt(index)
  }

  get adult(){
    return this.reactform.get("adult") as FormArray;
  }
  get child(){
    return this.reactform.get("child") as FormArray;
  }get infant(){
    return this.reactform.get("infant") as FormArray;
  }

  PaxGroup(): FormGroup {
    return new FormGroup({
     title:new FormControl('',Validators.required),
     firstName:new FormControl('',Validators.required),
     lastName:new FormControl('',Validators.required)
    });
  }
  
  
  insuranceyes(event:any){
    console.log(event.target.value)
    //let insurancepaxfee=300;
    this.insuranceallfee = this.insurancepaxfee * this.paxcount;

  }
  insuranceno(){
    let insurancepaxfee=0;
    this.insuranceallfee = insurancepaxfee

  }
  
  discounta(event:any){
    event.preventDefault();
    if(event.target.attributes.offertype.nodeValue=='Discount'){
      let disValue= event.target.value
      let disName= event.target.title
      debugger
      let discounta=disValue;
      this.discount = Math.round(discounta);
      this.disName=disName;
      this.cashbacks=0;
      this.casName="";
      debugger
    }
    else if(event.target.attributes.offertype.nodeValue=='Cashback'){
      let casValue= event.target.value
      let casName= event.target.title
      let cashback=casValue;
      this.casName=casName;
      debugger
      this.cashbacks = Math.round(cashback);
      this.discount=0;
      this.disName="";
      debugger
    }
  }
  discountb(){
    let discounta=200;
    this.discount = discounta

  }
  cashback(event:any){
    event.preventDefault();
    

  }

  email = new FormControl('', [Validators.required, Validators.email]);

  getErrorMessage() {
    if (this.email.hasError('required')) {
      return 'You must enter a value';
    }

    return this.email.hasError('email') ? 'Not a valid email' : '';
  }

  makeBooking(){
    console.log(this.formArray)
    debugger
    //Flight Authenticate
    let date_ob = new Date();
    // adjust 0 before single digit date
    let date = ("0" + date_ob.getDate()).slice(-2);
    // current month
    let month = ("0" + (date_ob.getMonth() + 1)).slice(-2);
    // current year
    let year = date_ob.getFullYear();
    let resDate =date + "" + month + "" + year;
    let resdate =year + "-" + month + "-" + date;
    let id=resDate+Math.floor(Math.random() * 1000000000);
    this.grandtotal = this.totalReturnFare + this.insuranceallfee - this.discount
    debugger
    console.log(this.grandtotal)
    let travelerContactObj = this.travelerContact.getRawValue();
    let travelerDetailsObj = this.travelerDetails.getRawValue();
    //console.log(this.travelerContact)
    console.log(id)
    var js= {
      "test":'test'
    }
    var disObj={
      "PromoCode":this.disName,
      "GetDiscount":this.discount
    }
    var casObj={
      "PromoCode":this.casName,
      "GetCashback":this.cashbacks
    }

    // let org=this.origin;
    // let des=this.destination;
    // let mbubi=JSON.stringify(this.itnry.Itinerary);
    // let gdsbi=JSON.stringify(js);
    // let bi=JSON.stringify(js);
    // let trcnt=JSON.stringify(travelerContactObj);
    // let trdtl=JSON.stringify(travelerDetailsObj);
    // let ins=JSON.stringify(js);
    // let dis=JSON.stringify(js);
    // let csb=JSON.stringify(js);
    // let bamt=this.grandtotal;
    // let tid=this.tokenid;
    // let trid=this.traceid;
    // let pnr='R9K39R';
    // let gds='TBO';
    // let gdsid='12341234'
    // let bip='123.123.123.123';
    // let bkst='Failed';
    // let pyst='Success';
    let postObj = {
    id:id,
    org:this.origin,
    des:this.destination,
    bkdate:resdate,
    mbubi:JSON.stringify(this.itnry),
    gdsbi:JSON.stringify(this.itnry),
    bi:JSON.stringify(this.itnry),
    trcnt:JSON.stringify(travelerContactObj),
    trdtl:JSON.stringify(this.reactform.value),
    ins:JSON.stringify(js),
    dis:JSON.stringify(disObj),
    csb:JSON.stringify(casObj),
    bamt:this.grandtotal,    
    bip:'123.123.123.123',
    bkst:'Failed',
    pyst:'Success',
    }
    //console.log(postObj)
    

  //  this.httpClient.get(this.url+"/flight/flight-booking-submit/"+id+"/"+org+"/"+des+"/"+resdate+"/"+mbubi+"/"+gdsbi+"/"+bi+"/"+trcnt+"/"+trdtl+"/"+ins+"/"+dis+"/"+csb+"/"+bamt+"/"+tid+"/"+trid+"/"+pnr+"/"+gds+"/"+gdsid+"/"+bip+"/"+bkst+"/"+pyst+"/").subscribe(resp =>{
  //     //   this.isShow = false;
  //      this.bookingsubmit = resp;     
  //  });
  let httpheaders = new HttpHeaders({ 
    'content-type':'application/json'  
     });
  return this.httpClient.post(this.url+"/flight/flight-booking-submited",postObj, {headers:httpheaders}).subscribe(resp =>{
         //   this.isShow = false;
          this.bookingsubmit = resp; 
              
      });
  }

}
