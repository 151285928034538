<section class="container-fluid pb-5 float-start">
<div class="col-12 col-md-2 float-start">
  &nbsp;
  <app-left-flight-menubar></app-left-flight-menubar>
</div>
<div class="col-12 col-md-10 mt-4 pt-3 float-start">
  <app-flight-search-engine></app-flight-search-engine>
  <mat-drawer-container class="example-container">
  
    <mat-drawer-content>    
      
      <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
  
        <!--- Note that these columns can be defined in any order.
              The actual rendered columns are set as a property on the row definition" -->    
        <!-- Position Column -->
        <ng-container matColumnDef="bookingId">
          <th mat-header-cell *matHeaderCellDef> BOOKING ID / Type</th>
          <td mat-cell *matCellDef="let element"> <b *ngIf="element.bookingStatus=='Cancelled'" class="text-danger">{{element.bookingId}}</b><b *ngIf="element.bookingStatus=='Process'" class="text-info">{{element.bookingId}}</b><b *ngIf="element.bookingStatus=='Confirmed'" class="text-success">{{element.bookingId}}</b><b *ngIf="element.bookingStatus=='Failed'" class="text-primary">{{element.bookingId}}</b><b *ngIf="element.bookingStatus=='Failed'" class="text-danger">{{element.bookingId}}</b><b *ngIf="element.bookingStatus=='Rescheduled'" class="text-secondary">{{element.bookingId}}</b> <span *ngIf="element.tripType==1"> Oneway</span> <span *ngIf="element.tripType==2"> Round</span></td>
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="origin">
          <th mat-header-cell *matHeaderCellDef> ORIGIN </th>
          <td mat-cell *matCellDef="let element"> {{element.origin}} </td>
        </ng-container>
      
        <!-- Weight Column -->
        <ng-container matColumnDef="destination">
          <th mat-header-cell *matHeaderCellDef> DESTINATION </th>
          <td mat-cell *matCellDef="let element"> {{element.destination}} </td>
        </ng-container>
      
        <!-- Symbol Column -->
        <ng-container matColumnDef="bookingAmount">
          <th mat-header-cell *matHeaderCellDef> Grand Total & P/L</th>
          <td mat-cell *matCellDef="let element"> {{element.bookingAmount}} <b>{{element.bookingAmount-element.offerAmount}}</b> </td>
        </ng-container>
        <!-- bookingStatus Column -->
        <ng-container matColumnDef="bookingStatus">
          <th mat-header-cell *matHeaderCellDef>Booking Status / PNR</th>
          <td mat-cell *matCellDef="let element"> <b>{{element.bookingStatus}}</b></td>
        </ng-container>
        <!-- PaymentStatus Column -->
        <ng-container matColumnDef="paymentStatus">
          <th mat-header-cell *matHeaderCellDef>Payment Status</th>
          <td mat-cell *matCellDef="let element"> <b *ngIf="element.paymentStatus=='Success'" class="text-success">{{element.paymentStatus}}</b> </td>
        </ng-container>
        
        <!-- Ammendment Column -->
        <ng-container matColumnDef="ammendment">
          <th mat-header-cell *matHeaderCellDef> AMMENDMENT </th>
          <td mat-cell *matCellDef="let element"><mat-icon class="cursor-pointer" (click)="openflightbooking(element.bookingId)">open_in_full</mat-icon> <mat-icon (click)="openflighthtmltopdf(element.bookingId)">picture_as_pdf</mat-icon> <mat-icon>outgoing_mail</mat-icon> <mat-icon class="ex2"  (click)="deleteflightbooking(element.bookingId)">delete</mat-icon>  </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </mat-drawer-content>
  </mat-drawer-container>
</div>

</section>
  
  