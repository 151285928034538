import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  url = environment.apiUrl;

  constructor(private httpClient:HttpClient) { }
  login(data:any){
    return this.httpClient.post(this.url+"/user/login",data,{
      headers:new HttpHeaders().set('Content-Type','application/json')
    })
  }
}
