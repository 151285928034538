<app-header></app-header>
<section class="container-fluid">
    <div class="container">
        <mat-card class="col-12 mt-2 mb-2 float-start pb-4">
            <h4>Add Package Names</h4>
            <form class="example-form" [formGroup]="packageNameForm">
            <div class="col-12 float-start">
                <div class="col-12 col-md-9 float-start">
                        <mat-form-field class="example-full-width col-12 col-md-6 p-1" appearance="fill">
                            <mat-label>Destination Name</mat-label>
                            <input type="text" placeholder="Enter Destination Name" matInput formControlName="desName" value="">                                             
                        </mat-form-field>
                        <mat-form-field class="example-full-width col-12 col-md-6 p-1" appearance="fill">
                            <mat-label>Package Url Name</mat-label>
                            <input type="text" placeholder="Package Url Name" matInput formControlName="packUrlName" value="">                                             
                        </mat-form-field>                                
                </div>
                
                <div class="col-12 col-md-3 col-md-1 p-1 float-start">
                    <button mat-flat-button color="accent" (click)="addNameBtn()" class="pt-2 pb-2 d-block col-12">Add Name</button>
                </div>
            </div>
        </form>
        </mat-card>
        <mat-card class="col-12 mt-2 mb-2 float-start pb-4">
            <h4>Add Package Details</h4>
            <form class="example-form" [formGroup]="packageDetailForm">
            <div class="col-12 float-start">
                <div class="col-12 col-md-9 float-start">
                        <mat-form-field class="example-full-width col-12 col-md-5 p-1" appearance="fill">
                            <mat-label>Package Name</mat-label>
                            <input type="text" placeholder="Enter Destination Name" matInput formControlName="packName" value="">                                             
                        </mat-form-field>
                        <mat-form-field appearance="fill" class="col-12 col-md-2 float-start ps-2 pe-2">
                            <mat-label>Package Type</mat-label>
                            <mat-select formControlName="packType">
                              <mat-option value="India">India</mat-option>
                              <mat-option value="International">International</mat-option>
                            </mat-select>
                        </mat-form-field> 
                        <mat-form-field appearance="fill" class="col-12 col-md-5 float-start ps-2 pe-2">
                            <mat-label>Includes</mat-label>
                            <mat-select formControlName="Includes" multiple>
                              <mat-option *ngFor="let includes of includesList" [value]="includes">{{includes}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                        
                        <mat-form-field appearance="fill" class="col-6 col-md-3 float-start ps-2 pe-2">
                            <mat-label>Price Type</mat-label>
                            <mat-select formControlName="priceType">
                              <mat-option value="Per Person">Per Person</mat-option>
                              <mat-option value="Per Couple">Per Couple</mat-option>
                            </mat-select>
                        </mat-form-field>
                        <mat-form-field class="example-full-width col-12 col-md-3 p-1" appearance="fill">
                            <mat-label>Numbers of Nights</mat-label>
                            <input type="text" placeholder="Enter Numbers of Nights" matInput formControlName="numberOfNights" value="">                                             
                        </mat-form-field>
                        <mat-form-field class="example-full-width col-12 col-md-3 p-1" appearance="fill">
                            <mat-label>Advance Rate</mat-label>
                            <input type="text" placeholder="Enter Advance Rate" matInput formControlName="advanceRate" value="">                                             
                        </mat-form-field>
                        <mat-form-field class="example-full-width col-12 col-md-3 p-1" appearance="fill">
                            <mat-label>Starting Price</mat-label>
                            <input type="text" placeholder="Enter Starting Price" matInput formControlName="startingPrice" value="">                                             
                        </mat-form-field>
                        <mat-form-field appearance="fill" class="col-6 col-md-3 float-start ps-2 pe-2">
                            <mat-label>Package Type</mat-label>
                            <mat-select formControlName="packageType">
                              <mat-option value="Family">Family</mat-option>
                              <mat-option value="Honeymoon">Honeymoon</mat-option>
                            </mat-select>
                        </mat-form-field>
                        <mat-form-field appearance="fill" class="col-6 col-md-3 float-start ps-2 pe-2">
                            <mat-label>Availability</mat-label>
                            <mat-select formControlName="availability">
                              <mat-option value="true">true</mat-option>
                              <mat-option value="false">false</mat-option>
                            </mat-select>
                        </mat-form-field>
                        <mat-form-field appearance="fill" class="col-6 col-md-3 float-start ps-2 pe-2">
                            <mat-label>Verification</mat-label>
                            <mat-select formControlName="verification">
                              <mat-option value="true">true</mat-option>
                              <mat-option value="false">false</mat-option>
                            </mat-select>
                        </mat-form-field>
                        <mat-form-field appearance="fill" class="col-6 col-md-3 float-start ps-2 pe-2">
                            <mat-label>Hotel Star Ranking</mat-label>
                            <mat-select formControlName="hotelStarRanking">
                              <mat-option value="1">1</mat-option>
                              <mat-option value="2">2</mat-option>
                              <mat-option value="3">3</mat-option>
                              <mat-option value="4">4</mat-option>
                              <mat-option value="5">5</mat-option>
                            </mat-select>
                        </mat-form-field>
                        <mat-form-field class="example-full-width col-12 col-md-3 p-1" appearance="fill">
                            <mat-label>Image Title</mat-label>
                            <input type="text" placeholder="Enter Image Title" matInput formControlName="imageTitle" value="">                                             
                        </mat-form-field>
                        <div class="col-6 col-md-3 float-start ps-2 pe-2 mt-2">
                            <label>Upload Main Image</label>
                            <input type="file" name="img" formControlName="mainImage" accept="image/*"> 
                        </div>
                        <div formArrayName="coverPlaces">
          
                            <div *ngFor="let item of coverPlaces.controls;let i=index" [formGroupName]="i" class="col-12 float-start">
                                                            
                                <mat-form-field appearance="fill" class="col-12 col-md-5 float-start ps-2 pe-2">
                                    <mat-label>Day (s)</mat-label>
                                    <input matInput placeholder="Placeholder" formControlName="Day">
                                </mat-form-field>
                              
                                <mat-form-field appearance="fill" class="col-12 col-md-5 float-start ps-2 pe-2">
                                    <mat-label>City</mat-label>
                                    <input matInput placeholder="Placeholder" formControlName="City">
                                </mat-form-field>
                              
                                <div class="col-12 col-md-2 float-start ps-2 pe-2">
                                    <button mat-stroked-button color="warn" (click)="RemoveAdult(i)">Remove</button>
                                </div>
                            </div>
                        </div>                         
                                     
                        <div class="col-12 float-start ps-2 pe-2">
                          <a mat-stroked-button color="primary" (click)="addNewAdult()">Add Cover Cities (+)</a>
                        </div>
                        
                        
                                                         
                </div>
                
                <div class="col-12 col-md-3 col-md-1 p-1 float-start">
                    <button mat-flat-button color="accent" (click)="addDetailBtn()" class="pt-2 pb-2 d-block col-12">Add Details</button>
                </div>
            </div>
        </form>
        </mat-card>
        <mat-card class="col-12 mt-2 mb-2 float-start pb-4">
            <h4>Add Inclusions</h4>
            <form class="example-form" [formGroup]="packageInclusionsForm">
            <div class="col-12 float-start">
                <div class="col-12 col-md-9 float-start">  
                                          
                        <div formArrayName="inclusions">          
                            <div *ngFor="let item of inclusions.controls;let i=index" [formGroupName]="i" class="col-12 float-start">
                                                            
                                <mat-form-field appearance="fill" class="col-12 col-md-5 float-start ps-2 pe-2">
                                    <mat-label>Inclustions Type</mat-label>
                                    <mat-select formControlName="InclusionsType">
                                      <mat-option value="Meal Plan for Hotel">Meal Plan for Hotel</mat-option>
                                      <mat-option value="Houseboat Stay">Houseboat Stay</mat-option>
                                      <mat-option value="Meal Plan for Houseboat">Meal Plan for Houseboat</mat-option>
                                    </mat-select>
                                </mat-form-field>
                              
                                <mat-form-field appearance="fill" class="col-12 col-md-5 float-start ps-2 pe-2">
                                    <mat-label>Inclustions</mat-label>
                                    <mat-select formControlName="Inclustions">
                                      <mat-option value="Breakfast">Breakfast</mat-option>
                                      <mat-option value="Lunch">Lunch</mat-option>
                                      <mat-option value="Dinner">Dinner</mat-option>
                                      <mat-option value="All Meals">All Meals</mat-option>
                                      <mat-option value="Private Houseboat">Private Houseboat</mat-option>
                                      <mat-option value="Sharing Houseboat">Sharing Houseboat</mat-option>
                                    </mat-select>
                                </mat-form-field>
                              
                                <div class="col-12 col-md-2 float-start ps-2 pe-2">
                                    <button mat-stroked-button color="warn" (click)="RemoveInclusions(i)">Remove</button>
                                </div>
                            </div>
                        </div>                
                        <div class="col-12 float-start ps-2 pe-2">
                          <a mat-stroked-button color="primary" (click)="addNewInclusions()">Add Inclusions Cities (+)</a>
                        </div> 
                        <mat-form-field appearance="fill" class="col-12 float-start p-2 mt-2">
                            <mat-label>Others</mat-label>
                            <textarea matInput formControlName="others" style="height:250px;"></textarea>
                        </mat-form-field>                                                                         
                </div>
                
                <div class="col-12 col-md-3 col-md-1 p-1 float-start">
                    <button mat-flat-button color="accent" (click)="addInclusionsBtn()" class="pt-2 pb-2 d-block col-12">Add Inclusions</button>
                </div>
            </div>
            
        </form>
        </mat-card>
        <mat-card class="col-12 mt-2 mb-2 float-start pb-4">
            <h4>Add Exclusions</h4>
            <form class="example-form" [formGroup]="packageExclusionsForm">
            <div class="col-12 float-start">
                <div class="col-12 col-md-9 float-start">  
                                          
                        <div formArrayName="exclusions">          
                            <div *ngFor="let item of exclusions.controls;let i=index" [formGroupName]="i" class="col-12 float-start">
                                                            
                                <mat-form-field appearance="fill" class="col-12 col-md-5 float-start ps-2 pe-2">
                                    <mat-label>Exclustions Type</mat-label>
                                    <mat-select formControlName="ExclusionsType">
                                      <mat-option value="Meal Plan for Hotel">Meal Plan for Hotel</mat-option>
                                      <mat-option value="Houseboat Stay">Houseboat Stay</mat-option>
                                      <mat-option value="Meal Plan for Houseboat">Meal Plan for Houseboat</mat-option>
                                    </mat-select>
                                </mat-form-field>
                              
                                <mat-form-field appearance="fill" class="col-12 col-md-5 float-start ps-2 pe-2">
                                    <mat-label>Exclustions</mat-label>
                                    <mat-select formControlName="Exclustions">
                                      <mat-option value="Breakfast">Breakfast</mat-option>
                                      <mat-option value="Lunch">Lunch</mat-option>
                                      <mat-option value="Dinner">Dinner</mat-option>
                                      <mat-option value="All Meals">All Meals</mat-option>
                                      <mat-option value="Private Houseboat">Private Houseboat</mat-option>
                                      <mat-option value="Sharing Houseboat">Sharing Houseboat</mat-option>
                                    </mat-select>
                                </mat-form-field>
                              
                                <div class="col-12 col-md-2 float-start ps-2 pe-2">
                                    <button mat-stroked-button color="warn" (click)="RemoveExclusions(i)">Remove</button>
                                </div>
                            </div>
                        </div>                
                        <div class="col-12 float-start ps-2 pe-2">
                          <a mat-stroked-button color="primary" (click)="addNewExclusions()">Add Exclusions Cities (+)</a>
                        </div> 
                        <mat-form-field appearance="fill" class="col-12 float-start p-2 mt-2">
                            <mat-label>Others</mat-label>
                            <textarea matInput formControlName="others" style="height:250px;"></textarea>
                        </mat-form-field>                                                                         
                </div>
                
                <div class="col-12 col-md-3 col-md-1 p-1 float-start">
                    <button mat-flat-button color="accent" (click)="addExclusionsBtn()" class="pt-2 pb-2 d-block col-12">Add Exclusions</button>
                </div>
            </div>
            
        </form>
        </mat-card>
        <mat-card class="col-12 mt-2 mb-2 float-start pb-4">
            <h4>Add Itinerary</h4>
            <form class="example-form" [formGroup]="packageItineraryForm">
            <div class="col-12 float-start">
                <div class="col-12 col-md-9 float-start">  
                                          
                        <div formArrayName="itinerary">          
                            <div *ngFor="let item of itinerary.controls;let i=index" [formGroupName]="i" class="col-12 float-start mt-2 mb-2">
                                                            
                                <mat-form-field appearance="fill" class="col-12 col-md-5 float-start ps-2 pe-2">
                                    <mat-label>Day</mat-label>
                                    <input type="text" placeholder="Enter Destination Name" matInput formControlName="Day" value="">
                                </mat-form-field>
                              
                                <mat-form-field appearance="fill" class="col-12 col-md-5 float-start ps-2 pe-2">
                                    <mat-label>Day title</mat-label>
                                    <input type="text" placeholder="Enter Destination Name" matInput formControlName="DayTitle" value="">
                                </mat-form-field>
                                <mat-form-field appearance="fill" class="col-12 col-md-5 float-start ps-2 pe-2">
                                    <mat-label>Day Includes</mat-label>
                                    <mat-select formControlName="DayIncludes" multiple>
                                      <mat-option *ngFor="let includes of includesDayList" [value]="includes">{{includes}}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <mat-form-field appearance="fill" class="col-12 float-start p-2 mt-2">
                                    <mat-label>Day Text</mat-label>
                                    <textarea matInput formControlName="DailyText" style="height:100px;"></textarea>
                                </mat-form-field>
                                <div class="col-12 col-md-2 float-start ps-2 pe-2">
                                    <button mat-stroked-button color="warn" (click)="RemoveItinerary(i)">Remove</button>
                                </div>
                            </div>
                        </div>                
                        <div class="col-12 float-start ps-2 pe-2">
                          <a mat-stroked-button color="primary" (click)="addNewItinerary()">Add Itinerary (+)</a>
                        </div>                                                                        
                </div>
                
                <div class="col-12 col-md-3 col-md-1 p-1 float-start">
                    <button mat-flat-button color="accent" (click)="addItineraryBtn()" class="pt-2 pb-2 d-block col-12">Add Itinerary</button>
                </div>
            </div>
            
        </form>
        </mat-card>
        <mat-card class="col-12 mt-2 mb-2 float-start pb-4">
            <h4>Add Images</h4>
            <form class="example-form" [formGroup]="packageImageForm">
            <div class="col-12 float-start">
                <div class="col-12 col-md-9 float-start">                                            
                        <div formArrayName="image">          
                            <div *ngFor="let item of image.controls;let i=index" [formGroupName]="i" class="col-12 float-start mt-2 mb-2">
                                                            
                                <mat-form-field appearance="fill" class="col-12 col-md-5 float-start ps-2 pe-2">
                                    <mat-label>Images Title</mat-label>
                                    <input type="text" placeholder="Enter Images Title" matInput formControlName="ImageTitle" value="">
                                </mat-form-field>
                                <div class="col-6 col-md-3 float-start ps-2 pe-2 mt-2">
                                    <label>Upload Images</label>
                                    <input type="file" name="img" formControlName="ImageUrl" accept="image/*"> 
                                </div>
                                <div class="col-12 col-md-2 float-start ps-2 pe-2">
                                    <button mat-stroked-button color="warn" (click)="RemoveImage(i)">Remove</button>
                                </div>
                            </div>
                        </div>                
                        <div class="col-12 float-start ps-2 pe-2">
                          <a mat-stroked-button color="primary" (click)="addNewImage()">Add Images (+)</a>
                        </div>                                                                        
                </div>
                
                <div class="col-12 col-md-3 col-md-1 p-1 float-start">
                    <button mat-flat-button color="accent" (click)="addImageBtn()" class="pt-2 pb-2 d-block col-12">Add Images</button>
                </div>
            </div>
            
        </form>
        </mat-card>
        <mat-card class="col-12 mt-2 mb-2 float-start pb-4">
            <h4>Add Policies</h4>
            <form class="example-form" [formGroup]="packagePoliciesForm">
            <div class="col-12 float-start">
                <div class="col-12 col-md-9 float-start">
                    <mat-form-field appearance="fill" class="col-12 float-start p-2 mt-2">
                        <mat-label>Add Policies</mat-label>
                        <textarea matInput formControlName="Policies" style="height:150px;"></textarea>
                    </mat-form-field>                               
                </div>                
                <div class="col-12 col-md-3 col-md-1 p-1 float-start">
                    <button mat-flat-button color="accent" (click)="addPoliciesBtn()" class="pt-2 pb-2 d-block col-12">Add Policies</button>
                </div>
            </div>
        </form>
        </mat-card>
        <mat-card class="col-12 mt-2 mb-2 float-start pb-4">
            <h4>Add Meta Tag</h4>
            <form class="example-form" [formGroup]="packageMetaForm">
            <div class="col-12 float-start">
                <div class="col-12 col-md-9 float-start">
                        <mat-form-field class="example-full-width col-12 col-md-6 p-1" appearance="fill">
                            <mat-label>Title</mat-label>
                            <input type="text" placeholder="Enter Destination Name" matInput formControlName="title" value="">                                             
                        </mat-form-field>
                        <mat-form-field class="example-full-width col-12 col-md-6 p-1" appearance="fill">
                            <mat-label>Keywords</mat-label>
                            <input type="text" placeholder="Package Url Name" matInput formControlName="keywords" value="">                                             
                        </mat-form-field> 
                        <mat-form-field class="example-full-width col-12 col-md-6 p-1" appearance="fill">
                            <mat-label>Description</mat-label>
                            <input type="text" placeholder="Package Url Name" matInput formControlName="description" value="">                                             
                        </mat-form-field>                                
                </div>
                
                <div class="col-12 col-md-3 col-md-1 p-1 float-start">
                    <button mat-flat-button color="accent" (click)="addMetaBtn()" class="pt-2 pb-2 d-block col-12">Add Meta</button>
                </div>
            </div>
        </form>
        </mat-card>
        <button type="submit" (click)="submitPackage()">Submit Package</button>
    </div>
</section>